import http from './http';
import { API_ME } from '../constants/apiRoutes';
import StorageService from './storageService';
import {
  isEmployeeInReporters,
  mergeAccessibleProfilesForUser,
  showErrorMessage,
} from './uiUtils';
import {
  PERMISSIONS_LIST_BY_ROLE,
  ROLES,
} from '../constants/rolesAndPermissionList';
import { USER_STATUSES } from '../constants/statuses';
import { USER_INFO } from '../constants/people';
import { PARAMS } from '../constants/pages';
import { ACTION_PLAN_STATUS_FILTERS_TYPES } from '../constants/filters';

const AUTH = 'auth';

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const removeLastItemFromArray = array =>
  array.slice(0, array.length - 1);

export const createSingleQuestion = (
  questionTitle,
  orderId,
  answerNumber,
  attributeId
) => {
  return {
    text: questionTitle,
    order_id: orderId,
    attribute: attributeId,
    ...(answerNumber !== 0
      ? {
          answer_number: answerNumber,
          answers: [...Array(answerNumber).keys()].map((_, i) => {
            return {
              text: (i + 1).toString(),
            };
          }),
        }
      : {}),
  };
};

export const duplicateQuestion = (
  question,
  orderId,
  attributeId,
  hasNeutral
) => {
  const answers = hasNeutral
    ? removeLastItemFromArray(question.answers)
    : question.answers;

  return {
    text: question.text,
    order_id: orderId,
    attribute: attributeId,
    answers,
  };
};

export const replaceObjectInList = (items, index, updatedProp) => {
  const updatedItem = { ...items[index], ...updatedProp };

  return [
    ...items.slice(0, index),
    updatedItem,
    ...items.slice(index + 1, items.length),
  ];
};

export const replaceObjectsInList = (list, listIndexes, replacementValues) => {
  let updatedList = [...list];
  listIndexes.map((listIndex, index) => {
    const updatedItem = { ...list[listIndex], ...replacementValues[index] };
    updatedList = [
      ...updatedList.slice(0, listIndex),
      updatedItem,
      ...updatedList.slice(listIndex + 1, list.length),
    ];
    return updatedList;
  });
  return updatedList;
};

export const isObject = value =>
  typeof value === 'object' && !Array.isArray(value) && value !== null;

export const isArray = value => Array.isArray(value);

export function isObjectEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export function isArrayEmpty(array) {
  return isArray(array) && array.length === 0;
}

export const objectHasProperty = (object, property) => {
  return object[property] !== undefined;
};

export const trimString = string => string?.trim();

export const replacePropsInObject = (obj, props) => {
  return {
    ...obj,
    ...props,
  };
};

export const isOfType = (item, type) => {
  return typeof item === type;
};

export const updateAuthData = callback => {
  const auth = JSON.parse(StorageService.get(AUTH));
  return http.get(API_ME).then(({ data }) => {
    const newAuth = {
      ...auth,
      ...data,
      permissions: PERMISSIONS_LIST_BY_ROLE[data.role],
      accessibleProfiles: mergeAccessibleProfilesForUser(data),
    };
    StorageService.set(AUTH, JSON.stringify(newAuth));
    callback(newAuth);
  });
};

export const checkUserRole = (userRole, role) => {
  return userRole === role;
};

export const canManagePerson = (
  currentUser,
  userId,
  shouldRemoveCurrentUser = false
) => {
  const isAdmin = checkUserRole(currentUser?.role, ROLES.ADMIN);
  const isModerator = checkUserRole(currentUser?.role, ROLES.MODERATOR);
  const isCurrentUser = currentUser?.id === userId;

  if (isCurrentUser && shouldRemoveCurrentUser) {
    return false;
  }

  if (isAdmin || (isModerator && currentUser?.id === userId)) {
    return true;
  }

  return Boolean(
    currentUser?.reporters[userId] ||
      (currentUser?.accessible[userId] &&
        !currentUser?.accessible[userId].read_only)
  );
};

export const getManageableUsers = (
  users,
  currentUser,
  shouldRemoveCurrentUser = false
) =>
  users.filter(user =>
    canManagePerson(currentUser, user.id, shouldRemoveCurrentUser)
  );

export const isUserDeactivated = user => {
  const { DEACTIVATED } = USER_STATUSES;
  return user[USER_INFO.STATUS] === DEACTIVATED;
};

export const isUserAppAccessForbidden = user => {
  const { ACTIVE_WITHOUT_ACCESS, DEACTIVATED } = USER_STATUSES;
  return user.status === ACTIVE_WITHOUT_ACCESS || user.status === DEACTIVATED;
};

export const getUnionOfTwoArrays = (firstArray, secondArray, key = 'id') => {
  const unique = new Set();

  return firstArray.concat(secondArray).filter(arrayItem => {
    return unique.has(arrayItem[key]) ? false : unique.add(arrayItem[key]);
  });
};

export const isArraySubset = (array, subsetArray) => {
  const set = new Set(array.map(item => item.id));
  if (set.size === 0) {
    return false;
  }
  return (
    subsetArray.filter(arrayItem => {
      return set.has(arrayItem.id);
    }).length === subsetArray.length
  );
};

export const debounce = (fn, delay = 150) => {
  let timeout;

  return function (...args) {
    const functionCall = () => fn.apply(this, args);

    clearTimeout(timeout);
    timeout = setTimeout(functionCall, delay);
  };
};

export const asyncDebounce = (fn, delay = 150, callFirst = false) => {
  let timeout;

  return function (...args) {
    return new Promise(resolve => {
      const debouncedFn = () => fn.apply(this, args);
      const callNow = callFirst && !timeout;

      if (!delay) {
        resolve(debouncedFn());
      }

      clearTimeout(timeout);

      timeout = setTimeout(() => {
        timeout = null;
        if (!callNow) {
          resolve(debouncedFn());
        }
      }, delay);

      if (callNow) {
        resolve(debouncedFn());
      }
    });
  };
};

export const canSeeEmployeeProfile = (
  currentUser,
  employeeId,
  canSeeHimself = true
) => {
  const isAdmin = checkUserRole(currentUser?.role, ROLES.ADMIN);
  return (
    isAdmin ||
    (currentUser?.id === employeeId && canSeeHimself) ||
    isEmployeeInReporters(employeeId, currentUser?.accessibleProfiles)
  );
};

export const isUserProfileAccessible = (currentUser, canSeeHimself) => userId =>
  canSeeEmployeeProfile(currentUser, userId, canSeeHimself);

export const handleCustomHttpError = (error, defaultError) => {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.detail
  ) {
    showErrorMessage(error.response.data.detail);
  } else {
    showErrorMessage(defaultError);
  }
};

export const areObjectsEqual = (obj1, obj2) => {
  return obj1 && typeof obj1 === 'object' && Object.keys(obj1).length > 0
    ? Object.keys(obj1).length === Object.keys(obj2).length &&
        Object.keys(obj1).every(p => areObjectsEqual(obj1[p], obj2[p]))
    : obj1 === obj2;
};

export const areArraysContentsEqual = (arr1, arr2) => {
  return (
    isArray(arr1) &&
    isArray(arr2) &&
    arr1.length === arr2.length &&
    !arr1.some(val => !arr2.includes(val))
  );
};

export const getItemById = (items, id, idKey = 'id') =>
  items.find(item => item[idKey] === id);

export const getItemIndex = (items, id, idKey = 'id') =>
  items.findIndex(item => item[idKey] === id);

export const buildQueryParams = params => {
  const queryParams = Object.keys(params)
    .filter(
      key =>
        (Array.isArray(params[key]) && params[key].length > 0) ||
        (typeof params[key] === 'boolean' && params[key] === true) ||
        typeof params[key] === 'number' ||
        (typeof params[key] === 'string' && params[key].trim().length > 0)
    )
    .map(
      key =>
        `${key}=${
          Array.isArray(params[key])
            ? params[key].join(',')
            : encodeURIComponent(params[key])
        }`
    );

  return queryParams.length > 0 ? `?${queryParams.join('&')}` : '';
};

export const sortByName = (a, b) => {
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

export const isItemInList = (list, item, key = 'id') =>
  list.some(i => i[key] === item[key]);

export const orderByPage = list => {
  const reorderedList = list.reduce((acc, curr) => {
    const exists = acc[curr.location];

    return {
      ...acc,
      [curr.location]: exists ? acc[curr.location].concat(curr) : [curr],
      active: {
        ...acc.active,
        ...(exists ? {} : { [curr.location]: curr.id }),
      },
    };
  }, {});

  return reorderedList;
};

export const isValueInList = (list, value) => list.some(item => item === value);

export const isSomeValueInList = (list1, list2) =>
  list2.some(value => isValueInList(list1, value));

export const isValidParameter = param => !Number.isNaN(Number(param)) && +param;

export const updatePersonRespondents = (respondents, id, value) => {
  const updatedRespondents = [...respondents];

  respondents.forEach(el => {
    if (!el.children) return;

    if (el.id === id) {
      el.children = value;
    } else {
      updatePersonRespondents(el.children, id, value);
    }
  });

  return updatedRespondents;
};

export const symmetricDifference = (first, second) => {
  return first
    .filter(x => !second.includes(x))
    .concat(second.filter(x => !first.includes(x)));
};

export const isEllipsisActive = (
  element,
  isVertical = false,
  isAlways = false
) => {
  if (!element) return;
  const scrollHeight = element.offsetHeight < element.scrollHeight;
  const scrollWidth = element.offsetWidth < element.scrollWidth;

  if (isVertical) {
    return scrollHeight;
  }

  if (isAlways) {
    return scrollHeight || scrollWidth;
  }

  return scrollWidth;
};

export const prepareDonutChartData = (
  translations,
  data,
  isActionPlanFilter
) => {
  if (!data) return null;

  return Object.keys(data).reduce((acc, currentStatus) => {
    if (translations[currentStatus]) {
      return [
        ...acc,
        {
          name: translations[currentStatus],
          value: data[currentStatus],
          ...(isActionPlanFilter
            ? {
                filters: {
                  [PARAMS.COMPLETION_STATUS]: [
                    ACTION_PLAN_STATUS_FILTERS_TYPES[
                      currentStatus.toUpperCase()
                    ],
                  ],
                },
              }
            : { filters: { [PARAMS.COMPLETION_STATUS]: [currentStatus] } }),
        },
      ];
    }

    return acc;
  }, []);
};

export const isPermissionGranted = (permission, permissions) =>
  permissions.includes(permission);

export const getPersonShortName = person => {
  return person
    ? `${person.first_name} ${person.last_name.charAt(0).toUpperCase()}.`
    : '';
};

export const isUserClickable =
  (currentUser, canSeeOwnProfile, organizationMentorId = 0) =>
  user =>
    user?.[USER_INFO.ROLE] !== ROLES.ASSOCIATE &&
    user?.id !== organizationMentorId &&
    canSeeEmployeeProfile(currentUser, user?.id, canSeeOwnProfile);

export const prepareAllUsers = (
  users,
  currentUser,
  shouldShowExternal = true,
  shouldShowCurrentUser = true
) => {
  const { ROLE } = USER_INFO;
  const isAdmin = checkUserRole(currentUser[ROLE], ROLES.ADMIN);

  if (isArrayEmpty(users)) return users;

  if (isAdmin) {
    let whitelistedUsers = [...users];

    if (!shouldShowExternal) {
      whitelistedUsers = whitelistedUsers.filter(
        user => user[ROLE] !== ROLES.ASSOCIATE
      );
    }

    if (!shouldShowCurrentUser) {
      whitelistedUsers = whitelistedUsers.filter(
        user => user.id !== currentUser.id
      );
    }

    return whitelistedUsers;
  }

  return users.filter(
    user =>
      (shouldShowCurrentUser && user.id === currentUser.id) ||
      (shouldShowExternal && user[ROLE] === ROLES.ASSOCIATE) ||
      isEmployeeInReporters(user.id, currentUser.reporters)
  );
};

export const getAdmins = users =>
  users.filter(user => user[USER_INFO.ROLE] === ROLES.ADMIN);

export const getEmployees = users =>
  users.filter(user => user[USER_INFO.ROLE] !== ROLES.ASSOCIATE);

export const getAssociates = users =>
  users.filter(user => user[USER_INFO.ROLE] === ROLES.ASSOCIATE);

export const getModerators = (users, excludedUsers = []) =>
  users.filter(
    user =>
      user[USER_INFO.ROLE] === ROLES.MODERATOR &&
      !excludedUsers.includes(user.id)
  );

export const getActiveUsers = users => {
  return users.filter(user => !isUserDeactivated(user));
};

export const getObjectToNumberArray = (array, key = 'id') =>
  array.reduce((acc, item) => [...acc, item[key]], []);

export const areArrayItemsReordered = (firstArray, secondArray) =>
  firstArray.some((item, index) => item.id !== secondArray[index]?.id);

export const removeObjectFromArray = (array, object, key = 'id') =>
  array.filter(item => item[key] !== object[key]);

export const removeArrayOfObjectsFromArray = (array1, array2, key = 'id') => {
  return array1.filter(item => !isItemInList(array2, item, key));
};

export const getFormattedUsers = (users, allUserInformation = false) => {
  const formattedUsers = {};
  users.forEach(user => {
    const userInfo = allUserInformation ? user : user.id;
    if (formattedUsers[user.report_to]) {
      formattedUsers[user.report_to] =
        formattedUsers[user.report_to].concat(userInfo);
    } else {
      formattedUsers[user.report_to] = [userInfo];
    }
  });

  return formattedUsers;
};

export const getAllManagersUnder = (userId, formattedModeratorsWithTeams) => {
  let list = [userId];
  let index = 0;
  while (index < list.length) {
    const users = formattedModeratorsWithTeams[list[index]];
    list = users ? list.concat(users) : list;
    index += 1;
  }
  return list;
};

export const reorderItemsInArrayByIds = (array, arrayIds) =>
  arrayIds.reduce((acc, currentId) => {
    const reorderedItem = array.find(item => item.id === currentId);

    return [...acc, reorderedItem];
  }, []);

export const reorderAndUpdateItemsInArrayByIds = (array, arrayIds) =>
  arrayIds.reduce((acc, currentId, currentIndex) => {
    const reorderedItem = array.find(item => item.id === currentId);
    reorderedItem.order = currentIndex + 1;

    return [...acc, reorderedItem];
  }, []);
