import { isObjectEmpty, getItemById } from './helpers';
import { formatDate } from './dateUtils';
import {
  TEAM_NOTIFICATION_MODELS_MAP,
  TEAM_NOTIFICATION_TYPES_MAP,
  TEAM_NOTIFICATION_MODEL_ICONS,
  TEAM_NOTIFICATION_ROUTES,
  TEAM_NOTIFICATION_MODELS,
  TEAM_NOTIFICATION_UPDATE_TYPES,
  TEAM_NOTIFICATION_CREATE_TYPES,
} from '../constants/teamNotifications';

const getTeamNotificationRoute = (model, itemId, userId, isAdmin) => {
  const { ACTION_PLAN, ONE_ON_ONE } = TEAM_NOTIFICATION_MODELS;
  const { notes, oneOnOne, actionPlan } = TEAM_NOTIFICATION_ROUTES;
  if (model === ACTION_PLAN) {
    return `/${actionPlan}/${itemId}/details`;
  }
  if (model === ONE_ON_ONE) {
    return isAdmin
      ? `/${oneOnOne}/${itemId}/details`
      : `/people/${userId}/one-on-one`;
  }
  return `/people/${userId}/${notes}`;
};

const getTeamNotificationCreatedItem = model => {
  if (model === TEAM_NOTIFICATION_MODELS.ACTION_PLAN) {
    return [TEAM_NOTIFICATION_CREATE_TYPES.ACTION_PLAN];
  }

  if (model === TEAM_NOTIFICATION_MODELS.NOTE) {
    return [TEAM_NOTIFICATION_CREATE_TYPES.NOTE];
  }

  return [TEAM_NOTIFICATION_CREATE_TYPES.ONE_ON_ONE];
};

export const prepareTeamNotifications = (teamNotifications, isAdmin) => {
  if (isObjectEmpty(teamNotifications)) return {};

  return Object.keys(teamNotifications).reduce((acc, key) => {
    return {
      ...acc,
      [key]: teamNotifications[key].map(notification => {
        const model = getItemById(
          TEAM_NOTIFICATION_MODELS_MAP,
          notification?.model,
          'value'
        );

        const type = getItemById(
          TEAM_NOTIFICATION_TYPES_MAP,
          notification?.action,
          'value'
        )?.key;

        const route = getTeamNotificationRoute(
          model?.value,
          notification?.object_id,
          notification?.subject,
          isAdmin
        );

        return {
          ...notification,
          createdAt: formatDate(notification.created_at),
          model: model?.key,
          type,
          icon: TEAM_NOTIFICATION_MODEL_ICONS[model?.key],
          route,
          updates:
            notification?.details?.updated ||
            getTeamNotificationCreatedItem(model?.value),
        };
      }),
    };
  }, {});
};

export const getTeamNotificationItem = (labels, notification) => ({
  model: labels.models[notification?.model],
  type: labels.types[notification?.type],
  icon: notification.icon,
  createdAt: notification.createdAt,
});

export const getTeamNotificationUpdates = (labels, model, updates) => {
  const updateType = TEAM_NOTIFICATION_UPDATE_TYPES[model];

  return Object.keys(updateType).reduce((acc, type) => {
    const update = updates.find(u => u === type);

    return [...acc, ...(update ? [labels[updateType[update]]] : [])];
  }, []);
};
