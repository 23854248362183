import ColorBox from 'components/shared/colorBox';
import { ReactComponent as TypeIcon } from '../../../assets/icons/attribute-type.svg';
import { ReactComponent as VerticalAnswerIcon } from '../../../assets/icons/vertical-answer-type.svg';
import { ReactComponent as HorizontalAnswerIcon } from '../../../assets/icons/horizontal-answer-type.svg';
import { ReactComponent as TagsIcon } from '../../../assets/icons/tags-icon.svg';
import {
  forbiddenCharacters,
  isEmpty,
  validateLength,
} from '../../../utility/validation';
import http from '../../../utility/http';
import {
  API_UNIQUE_ATTRIBUTE,
  api_attribute_usage,
} from '../../../constants/apiRoutes';
import {
  isArrayEmpty,
  trimString,
  getObjectToNumberArray,
} from '../../../utility/helpers';
import { formatDate } from '../../../utility/dateUtils';
import { getPersonFullName } from '../../../utility/uiUtils';
import { filterTags } from '../../../utility/tagUtils';
import { FIELD_TYPES } from '../../../constants/fieldTypes';
import { ATTRIBUTES } from '../../../constants/attributes';
import { PARAMS } from '../../../constants/pages';
import { CREATABLE_TAG_FIELD } from '../../../constants/tags';
import { ReactComponent as AddIcon } from '../../../assets/icons/plus-icon.svg';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-dark.svg';

const {
  TEXT,
  SELECT,
  COLOR_PICKER,
  CREATED_BY,
  ATTRIBUTES_QUESTIONS_FORM,
  CHECKBOX,
} = FIELD_TYPES;

export const SAVE = 'save';
export const QUESTIONS_ACTIONS = {
  PREVIEW: {
    name: 'previewQuestions',
  },
  EDIT: {
    name: 'editQuestions',
    icon: EditIcon,
  },
  CREATE: {
    name: 'createQuestions',
    icon: AddIcon,
  },
};

const ATTRIBUTE_TYPES_MAP = {
  SKILL: {
    id: 'SKILL',
    key: 'skill',
    name: 'Skill',
  },
  ENGAGEMENT: {
    id: 'ENGAGEMENT',
    key: 'engagement',
    name: 'Engagement',
  },
  PERFORMANCE: {
    id: 'PERFORMANCE',
    key: 'performance',
    name: 'Performance',
  },
  ONETIME: {
    id: 'ONETIME',
    key: 'oneTime',
    name: 'One-Time',
  },
};

const ATTRIBUTE_TYPES = [
  ATTRIBUTE_TYPES_MAP.SKILL,
  ATTRIBUTE_TYPES_MAP.ENGAGEMENT,
  ATTRIBUTE_TYPES_MAP.PERFORMANCE,
  ATTRIBUTE_TYPES_MAP.ONETIME,
];

export const INITIAL_SORT = {
  column: 'modifiedOn',
  asc: false,
  sortKey: '-modified_on',
};

export const ATTRIBUTE_NAME_FIELD = {
  name: 'name',
  required: true,
  translationKey: 'name',
  type: TEXT,
  validators: [
    { type: 'required', validator: isEmpty },
    {
      type: 'minLength',
      validator: value => validateLength(value, 3, 255, true),
    },
    { type: 'maxNameLength', validator: validateLength },
    {
      type: 'forbiddenCharacters',
      validator: forbiddenCharacters,
    },
    {
      type: 'availableName',
      async: true,
      validator: value => {
        const params = {
          name: trimString(value),
        };
        return http
          .get(API_UNIQUE_ATTRIBUTE, { params })
          .then(({ data }) => !data.exists);
      },
    },
  ],
};

export const ATTRIBUTE_SHORT_NAME_FIELD = {
  name: 'shortName',
  type: TEXT,
  required: true,
  translationKey: 'shortName',
  validators: [
    { type: 'required', validator: isEmpty },
    { type: 'exactLength', validator: value => validateLength(value, 1, 5) },
    {
      type: 'forbiddenCharacters',
      validator: forbiddenCharacters,
    },
    {
      type: 'availableShortName',
      async: true,
      validator: value => {
        const params = {
          code: trimString(value),
        };
        return http
          .get(API_UNIQUE_ATTRIBUTE, { params })
          .then(({ data }) => !data.exists);
      },
    },
  ],
};

export const isAttributeManageable = (isAdmin, currentUser, attribute) =>
  isAdmin || attribute.created_by.id === currentUser.id;

export const getAttributeTypes = labels => {
  return ATTRIBUTE_TYPES.map(type => ({
    ...type,
    name: labels[type.key],
    value: type.id,
  }));
};

export const getPageFilters = (
  labels,
  types,
  tagCategories,
  quickFilters = []
) => {
  const { TYPE, TAGS } = PARAMS;

  return [
    {
      id: TYPE,
      name: labels[TYPE],
      icon: TypeIcon,
      isSingleSelect: true,
      items: types,
    },
    {
      id: TAGS,
      name: labels[TAGS],
      icon: TagsIcon,
      categoryItemsKey: TAGS,
      hasCategoryColorBox: true,
      hasSubmenuHash: true,
      isCategorized: true,
      items: tagCategories,
      categorySearch: filterTags,
    },
    ...(!isArrayEmpty(quickFilters)
      ? quickFilters?.map(filter => {
          return {
            id: filter.name,
            name: filter.name,
            icon: () => {
              return <ColorBox bgColor={filter.color} isSmall />;
            },
            parent: {
              id: TAGS,
            },
            items: filter?.tags,
          };
        }) || {}
      : []),
  ];
};

export const getHeaderActions = labels => [
  {
    id: 'actions-column',
    title: labels.actions,
    rowKey: 'dotsMenu',
    align: 'right',
    isHeaderAction: true,
    rowCellClass: 'actions-dots-menu',
    minWidth: 60,
    maxWidth: 150,
  },
];

export const getTableHeaders = labels => [
  {
    id: 1,
    title: labels.color,
    rowKey: 'color',
    sortAs: 'color',
    isHighlighted: true,
    minWidth: 60,
    maxWidth: 80,
  },
  {
    id: 2,
    title: labels.shortName,
    rowKey: 'shortName',
    sortAs: 'code',
    minWidth: 115,
    maxWidth: 11,
  },
  {
    id: 3,
    title: labels.name,
    rowKey: 'name',
    sortAs: 'name',
    minWidth: 130,
    maxWidth: '1fr',
  },
  {
    id: 4,
    title: labels.attributeType,
    rowKey: 'attributeType',
    isSortingDisabled: true,
    minWidth: 130,
    maxWidth: 130,
  },
  {
    id: 5,
    title: labels.isPrivate,
    rowKey: 'isPrivate',
    sortAs: 'is_private',
    minWidth: 100,
    maxWidth: 100,
  },
  {
    id: 6,
    title: labels.modifiedOn,
    rowKey: 'modifiedOn',
    sortAs: 'modified_on',
    minWidth: 120,
    maxWidth: 140,
  },
  {
    id: 7,
    title: labels.timesUsed,
    rowKey: 'timesUsed',
    sortAs: 'times_used',
    align: 'right',
    isSortingDisabled: true,
    minWidth: 80,
    maxWidth: 105,
  },
];

export const prepareTableData = (
  translations,
  currentUser,
  isAdmin,
  attributes,
  types,
  renderName,
  renderActions,
  renderPersonalAttribute
) => {
  const typesMap = types.reduce((acc, current) => {
    return { ...acc, [current.id]: current.name };
  }, {});

  return attributes.map(attribute => {
    const isManageable = isAttributeManageable(isAdmin, currentUser, attribute);
    return {
      isActive: attribute.is_active
        ? translations.active
        : translations.inactive,
      name: renderName(attribute),
      id: attribute.id,
      attributeType: typesMap[attribute.type] || '-',
      modifiedOn: formatDate(attribute.modified_on),
      createdBy: getPersonFullName(attribute.created_by),
      isEditable: attribute.is_editable && isManageable,
      shortName: attribute.code,
      isPrivate: attribute.is_private
        ? renderPersonalAttribute(attribute.created_by)
        : translations.public,
      timesUsed: attribute.times_used,
      dotsMenu: renderActions(isManageable, attribute),
      color: attribute.color,
    };
  });
};

export const getNewAttributeData = currentUser => ({
  name: '',
  code: '',
  description: '',
  is_active: true,
  is_private: false,
  answer_number: 4,
  type: ATTRIBUTE_TYPES_MAP.SKILL.id,
  tags: [],
  color: '',
  created_by: { ...currentUser },
});

export const prepareAttributeData = attribute => ({
  ...attribute,
  description: attribute?.description || '',
  tags: getObjectToNumberArray(attribute?.tags || []),
});

export const getAttributeFields = (isEdit, onCreateTag, classes) => {
  return [
    {
      name: 'name',
      type: TEXT,
      fieldWrapperClass: classes.name,
      required: true,
      validators: [
        { type: 'required', validator: isEmpty },
        {
          type: 'forbiddenCharacters',
          validator: forbiddenCharacters,
        },
        {
          type: 'minLength',
          validator: value => validateLength(value, 3, 255, true),
        },
        { type: 'maxNameLength', validator: validateLength },
        {
          type: 'availableName',
          async: true,
          validator: value => {
            const params = {
              name: trimString(value),
            };
            return http
              .get(API_UNIQUE_ATTRIBUTE, { params })
              .then(({ data }) => !data.exists);
          },
        },
      ],
    },
    {
      name: 'code',
      type: TEXT,
      fieldWrapperClass: classes.code,
      required: true,
      validators: [
        { type: 'required', validator: isEmpty },
        {
          type: 'exactLength',
          validator: value => validateLength(value, 1, 5),
        },
        { type: 'forbiddenCharacters', validator: forbiddenCharacters },
        {
          type: 'availableShortName',
          async: true,
          validator: value => {
            const params = {
              code: trimString(value),
            };
            return http
              .get(API_UNIQUE_ATTRIBUTE, { params })
              .then(({ data }) => !data.exists);
          },
        },
      ],
    },
    {
      name: 'type',
      type: SELECT,
      fieldWrapperClass: classes.attributeTypes,
      optionsKey: 'attributeTypes',
      noOptionsLabel: 'no options',
      parser: {
        label: 'name',
        value: 'id',
      },
    },
    {
      name: 'tags',
      type: SELECT,
      fieldWrapperClass: classes.tags,
      optionsKey: 'categories',
      creatableOptionField: CREATABLE_TAG_FIELD,
      isCategorized: true,
      hasCategoryColorBox: true,
      isMulti: true,
      hasHash: true,
      isCreatable: true,
      isTag: true,
      parser: {
        value: 'id',
        label: 'name',
        categoryLabel: 'name',
        categoryValue: 'tags',
      },
      onCreateOption: onCreateTag,
    },
    {
      name: 'is_private',
      translationKey: 'visibility',
      type: CHECKBOX,
      fieldWrapperClass: classes.isPrivate,
      className: classes.checkbox,
      isControlled: true,
      shouldTopAlign: true,
      smallText: true,
      ...(isEdit
        ? {
            validators: [
              {
                type: 'attributeUsage',
                async: true,
                shouldPassFormValues: true,
                validator: async (value, values) => {
                  if (value) {
                    const { data } = await http.get(
                      api_attribute_usage(values.id)
                    );
                    return (
                      isArrayEmpty(data.positions) && isArrayEmpty(data.levels)
                    );
                  }

                  return true;
                },
              },
            ],
          }
        : {}),
    },
    {
      name: 'is_active',
      translationKey: 'status',
      type: CHECKBOX,
      fieldWrapperClass: classes.isActive,
      className: classes.checkbox,
      isControlled: true,
      smallText: true,
      isReverse: true,
      ...(isEdit
        ? {
            validators: [
              {
                type: 'attributeUsage',
                async: true,
                shouldPassFormValues: true,
                validator: async (value, values) => {
                  if (!value) {
                    const { data } = await http.get(
                      api_attribute_usage(values.id)
                    );
                    return (
                      isArrayEmpty(data.positions) && isArrayEmpty(data.levels)
                    );
                  }

                  return true;
                },
              },
            ],
          }
        : {}),
    },
    {
      name: 'color',
      translationKey: 'color',
      type: COLOR_PICKER,
      fieldWrapperClass: classes.color,
      labelHelp: true,
    },
    {
      name: 'created_by',
      translationKey: 'createdBy',
      type: CREATED_BY,
      fieldWrapperClass: classes.createdBy,
    },
    {
      name: 'description',
      type: FIELD_TYPES.RICH_TEXT_EDITOR,
      fieldWrapperClass: classes.description,
      validators: [
        {
          validator: fieldValue => validateLength(fieldValue, 0, 10000),
          type: 'descriptionMaxLength',
        },
      ],
    },
  ];
};

export const getAttributeQuestionsFields = translations => {
  const { fields } = ATTRIBUTES;

  return [
    {
      name: 'add_attribute_questions_form',
      type: ATTRIBUTES_QUESTIONS_FORM,
      questionsLimitKey: 'questionsLimit',
      questionsStyleComponent: {
        name: 'question_style_component',
        translationKey: 'question_style_component',
        title: {
          name: 'Question style',
          translationKey: 'questionStyle',
        },
        answerStyle: {
          name: fields.withTextReplyQuestions,
          translationKey: 'withTextReplyQuestions',
          dependant: {
            name: fields.answersCount,
            defaultValue: 4,
            resetValue: 0,
          },
          items: [
            {
              value: false,
              label: translations.withTextReplyQuestions.off,
            },
            {
              value: true,
              label: translations.withTextReplyQuestions.on,
            },
          ],
        },
        layoutType: {
          name: fields.layoutType,
          options: [
            {
              value: ATTRIBUTES.verticalLayout,
              label: translations.layout.vertical,
              icon: VerticalAnswerIcon,
            },
            {
              value: ATTRIBUTES.horizontalLayout,
              label: translations.layout.horizontal,
              icon: HorizontalAnswerIcon,
            },
          ],
        },
        additionalFeedback: {
          name: fields.withAdditionalFeedback,
          translationKey: 'withAdditionalFeedback',
        },
        neutralAnswer: {
          name: fields.withNeutralAnswer,
          translationKey: 'withNeutralAnswer',
        },
        zeroValueAnswer: {
          name: fields.withZeroValueAnswer,
          translationKey: 'withZeroValueAnswer',
        },
        answerNumber: {
          name: fields.answersCount,
          translationKey: 'answerNumber',
          options: Array.from({ length: 14 }, (_, i) => ({
            label: i + 2,
            value: i + 2,
          })),
        },
      },
      attributeQuestions: {
        name: 'questions',
        translationKey: 'questions',
      },
    },
  ];
};

export const getAttributeDeleteTranslations = (
  translations,
  isOpened,
  timesUsed
) => {
  if (isOpened) {
    if (timesUsed !== 0) {
      return {
        ...translations.deleteUsedAttributeDialog,
        contentMessage:
          translations.deleteUsedAttributeDialog.contentMessage.replace(
            '[X]',
            timesUsed
          ),
      };
    }
    return translations.deleteAttributeDialog;
  }

  return {};
};

export const getNoResultsLabels = (
  labels,
  search,
  filterIsActive,
  isActiveView
) => {
  if (isActiveView) {
    return {
      title: search || filterIsActive ? '' : labels.titleActive,
      content: search || filterIsActive ? labels.search : labels.message,
    };
  }

  return {
    title: search || filterIsActive ? '' : labels.titleInactive,
    content: search || filterIsActive ? labels.search : '',
  };
};
