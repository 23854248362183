import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import CustomModal from '../customModal';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  content: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    columnGap: spacing(4),
    width: '100%',
  },
  type: {
    cursor: 'pointer',
    boxSizing: 'border-box',
    border: `2px solid ${primary.bluish4}`,
    borderRadius: 16,
    padding: spacing(4),
    userSelect: 'none',
    transition: 'border-color .25s ease',
  },
  selected: {
    border: `2px solid ${primary.blue1}`,
  },
  spacingBottom: {
    marginBottom: spacing(2),
  },
  actionButtons: {
    marginTop: spacing(8),
  },
  icon: {
    width: 32,
    height: 32,
  },
}));

const ChooseTypeDialog = ({
  translations,
  isOpened,
  types,
  selectedType,
  onCancel,
  onSelect,
  onConfirm,
}) => {
  const classes = useStyles();

  const handleSelect = type => () => onSelect(type.id);

  return (
    <CustomModal
      customButtonsClass={classes.actionButtons}
      title={translations.title}
      isOpened={isOpened}
      confirmButtonLabel={translations.continue}
      closeButtonLabel={translations.cancel}
      onClose={onCancel}
      onConfirm={onConfirm}
    >
      <div className={classes.content}>
        {types.map(type => {
          const { name, description, icon: Icon } = type;
          const isSelected = type.id === selectedType;

          return (
            <div
              key={`type_item__${type.id}`}
              className={classNames(classes.type, {
                [classes.selected]: isSelected,
              })}
              onClick={handleSelect(type)}
            >
              {Icon && (
                <div className={classes.spacingBottom}>
                  <Icon className={classes.icon} />
                </div>
              )}
              <div className={classes.spacingBottom}>
                <Typography variant="h5" component="p">
                  {name}
                </Typography>
              </div>
              <Typography variant="body2">{description}</Typography>
            </div>
          );
        })}
      </div>
    </CustomModal>
  );
};

ChooseTypeDialog.defaultProps = {
  selectedType: null,
};

ChooseTypeDialog.propTypes = {
  translations: PropTypes.object.isRequired,
  isOpened: PropTypes.bool.isRequired,
  types: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedType: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ChooseTypeDialog;
