import { ReactComponent as CompletionSubmittedIcon } from '../assets/icons/check-circle-outline-green.svg';
import { ReactComponent as CompletionNotStartedIcon } from '../assets/icons/highlight_off.svg';
import { ReactComponent as CompletionStartedIcon } from '../assets/icons/access_time-green.svg';
import { primaryPalette } from '../styles/theme';

export const SURVEY_STATUS_TYPES = {
  ACTIVE: 'ACTIVE',
  COMPLETED: 'COMPLETED',
  SCHEDULED: 'SCHEDULED',
  DRAFT: 'DRAFT',
};

export const SURVEY_COMPLETION_STATUS_TYPES = {
  IN_PROGRESS: 'IN_PROGRESS',
  COMPLETED: 'COMPLETED',
  NOT_STARTED: 'NOT_STARTED',
};

const ONE_ONE_ONE_COMPLETION_STATUS_TYPES = {
  REQUESTED: 'requested',
  UPCOMING: 'upcoming',
  PENDING: 'pending',
  PAST: 'past',
};

export const SURVEY_STATUSES = [
  {
    id: SURVEY_STATUS_TYPES.ACTIVE,
    name: 'In Progress',
    key: 'inProgress',
  },
  {
    id: SURVEY_STATUS_TYPES.COMPLETED,
    name: 'Done',
    key: 'done',
  },
  {
    id: SURVEY_STATUS_TYPES.SCHEDULED,
    name: 'Scheduled',
    key: 'scheduled',
  },
  {
    id: SURVEY_STATUS_TYPES.DRAFT,
    name: 'Draft',
    key: 'draft',
  },
];

export const SURVEY_COMPLETION_STATUSES = [
  {
    id: SURVEY_COMPLETION_STATUS_TYPES.NOT_STARTED,
    name: 'Not started',
    key: 'notStarted',
    icon: CompletionNotStartedIcon,
  },
  {
    id: SURVEY_COMPLETION_STATUS_TYPES.IN_PROGRESS,
    name: 'Started',
    key: 'started',
    icon: CompletionStartedIcon,
  },
  {
    id: SURVEY_COMPLETION_STATUS_TYPES.COMPLETED,
    name: 'Submitted',
    key: 'submitted',
    icon: CompletionSubmittedIcon,
  },
];

export const USER_STATUSES = {
  ACTIVE_WITHOUT_ACCESS: 'IDLE',
  ACTIVE_INVITED: 'INVITED',
  ACTIVE_WITH_ACCESS: 'WITH_ACCESS',
  DEACTIVATED: 'SUSPENDED',
};

export const USER_STATUSES_MAP = [
  {
    id: USER_STATUSES.ACTIVE_WITHOUT_ACCESS,
    name: 'Active without access',
    key: 'activeWithoutAccess',
  },
  {
    id: USER_STATUSES.ACTIVE_INVITED,
    name: 'Active Invited',
    key: 'activeInvited',
  },
  {
    id: USER_STATUSES.ACTIVE_WITH_ACCESS,
    name: 'Active with access',
    key: 'activeWithAccess',
  },
  {
    id: USER_STATUSES.DEACTIVATED,
    name: 'Deactivated',
    key: 'deactivated',
  },
];

export const ACTION_PLAN_STATUSES = {
  IN_PROGRESS: false,
  DONE: true,
};

export const ACTION_PLAN_STATUSES_LIST = [
  {
    id: ACTION_PLAN_STATUSES.IN_PROGRESS,
    label: 'In progress',
    key: 'inProgress',
    value: ACTION_PLAN_STATUSES.IN_PROGRESS,
    color: primaryPalette.green1,
  },
  {
    id: ACTION_PLAN_STATUSES.DONE,
    label: 'Done',
    key: 'done',
    value: ACTION_PLAN_STATUSES.DONE,
    color: primaryPalette.white,
    borderColor: primaryPalette.green1,
    backgroundColor: primaryPalette.green1,
  },
];

export const COURSE_ENROLLMENT_STATUSES = [
  {
    name: 'To do',
    key: 'toDo',
    value: 1,
    id: 1,
  },
  {
    name: 'In progress',
    key: 'inProgress',
    value: 2,
    id: 2,
  },
  {
    name: 'Completed',
    key: 'completed',
    value: 3,
    id: 3,
  },
];

export const ONE_ON_ONE_COMPLETION_STATUSES = [
  {
    id: ONE_ONE_ONE_COMPLETION_STATUS_TYPES.REQUESTED,
    name: 'Requested',
  },
  {
    id: ONE_ONE_ONE_COMPLETION_STATUS_TYPES.UPCOMING,
    name: 'Upcoming',
  },
  {
    id: ONE_ONE_ONE_COMPLETION_STATUS_TYPES.PENDING,
    name: 'Pending',
  },
  {
    id: ONE_ONE_ONE_COMPLETION_STATUS_TYPES.PAST,
    name: 'Past',
  },
];
