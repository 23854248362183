import { useState } from 'react';
import PropTypes from 'prop-types';
import { Fade, makeStyles } from '@material-ui/core';
import AsyncListTooltip from '../asyncListTooltip';
import NotificationItem from './notificationItem';
import NotificationButton from '../notificationButton';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  markAsReadButton: {
    border: `1px solid ${primary.bluish1}`,
    borderRadius: 44,
    color: primary.bluish1,
    fontFamily: 'ProximaNova-Bold',
    lineHeight: '24px',
    justifySelf: 'center',
    padding: spacing(1, 2),
    maxHeight: 32,
  },
}));

const NotificationInfo = ({
  translations,
  notificationItems,
  asyncListProps,
  hasNotification,
  onClearAll,
  onViewNotification,
}) => {
  const classes = useStyles();
  const [isOpenedView, setIsOpenedView] = useState(true);

  const notificationCount = notificationItems?.length || 0;

  const onToggleView = value => {
    setIsOpenedView(value);
  };

  return (
    <Fade in={hasNotification} unmountOnExit>
      <div>
        <AsyncListTooltip
          tooltipButtonLabel={translations.markAsRead}
          labelData={notificationItems}
          labelComponent={NotificationItem}
          labelComponentProps={{
            translations,
          }}
          tooltipButtonProps={{
            className: classes.markAsReadButton,
            type: 'addWithTextRounded',
          }}
          shouldDisablePortal={false}
          onItemClickHandler={onViewNotification}
          onTooltipButtonClickHandler={onClearAll}
          onToggleView={onToggleView}
          hasTooltipButton
          {...asyncListProps}
        >
          <NotificationButton
            tooltipText={
              isOpenedView ? translations.notificationButtonTooltip : ''
            }
            notificationCount={notificationCount}
          />
        </AsyncListTooltip>
      </div>
    </Fade>
  );
};

NotificationInfo.defaultProps = {
  notificationItems: [],
  asyncListProps: {},
};

NotificationInfo.propTypes = {
  translations: PropTypes.object.isRequired,
  hasNotification: PropTypes.bool.isRequired,
  asyncListProps: PropTypes.shape({}),
  notificationItems: PropTypes.arrayOf(PropTypes.object),
  onClearAll: PropTypes.func.isRequired,
  onViewNotification: PropTypes.func.isRequired,
};

export default NotificationInfo;
