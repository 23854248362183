import { useState, useCallback, useEffect, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { FormHelperText, makeStyles } from '@material-ui/core';
import CustomButton from '../customButton';
import CustomScrollBar from '../customScrollBar';
import UsersList from '../usersList';
import Picker from './picker';
import {
  isArrayEmpty,
  trimString,
  getUnionOfTwoArrays,
  isArraySubset,
  removeArrayOfObjectsFromArray,
} from '../../../utility/helpers';
import { useCustomEffect } from '../../../utility/hooks';
import { PARAMS } from '../../../constants/pages';
import { PEOPLE_DEFAULT_ORDERING } from '../../../constants/people';

const useStyles = makeStyles(({ spacing }) => ({
  addButton: {
    marginBottom: spacing(6),
  },
  selectedUsersWrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 'calc(100% - 16px)',
    maxHeight: 128,
    width: '100%',
  },
  scrollY: {
    top: 0,
    right: -16,
    height: '100%',
  },
}));

const MultipartPeoplePicker = ({
  className,
  selectedUserWrapperClass,
  translations,
  allUsers,
  filters,
  selectedUsers,
  onGetUsers,
  onSave,
}) => {
  const { ROLE, STATUS, SCOPE, JOB_TITLE, TRACK, LEVEL, TAGS } = PARAMS;
  const classes = useStyles();

  const [isPickerOpened, setIsPickerOpened] = useState(false);
  const [tempSelectedUsers, setTempSelectedUsers] = useState(selectedUsers);
  const [users, setUsers] = useState(allUsers);
  const [search, setSearch] = useState('');
  const [selectedFilters, setSeletedFilters] = useState({
    [ROLE]: [],
    [STATUS]: [],
    [SCOPE]: [],
    [JOB_TITLE]: [],
    [TRACK]: [],
    [LEVEL]: [],
    [TAGS]: [],
  });

  const areAllUsersSelected = useMemo(
    () =>
      Boolean(
        tempSelectedUsers.length &&
          users.length &&
          isArraySubset(tempSelectedUsers, users)
      ),
    [users, tempSelectedUsers]
  );
  const isDirty = useMemo(() => {
    const union = getUnionOfTwoArrays(selectedUsers, tempSelectedUsers);

    return (
      union.length !== selectedUsers.length ||
      union.length !== tempSelectedUsers.length
    );
  }, [selectedUsers, tempSelectedUsers]);

  const handleGetUsers = useCallback(async () => {
    const params = {
      ...PEOPLE_DEFAULT_ORDERING,
      page_size: 30,
      ...selectedFilters,
      ...(search ? { search } : {}),
    };

    const { data } = await onGetUsers(params);
    setUsers(data || []);
  }, [selectedFilters, search, onGetUsers]);

  useCustomEffect(
    () => {
      handleGetUsers();
    },
    [handleGetUsers],
    false
  );

  useEffect(() => {
    if (isPickerOpened) {
      setTempSelectedUsers(selectedUsers);
    }
  }, [isPickerOpened, selectedUsers]);

  const togglePicker = () => setIsPickerOpened(prevOpened => !prevOpened);

  const handleSave = () => {
    togglePicker();

    if (isDirty) {
      onSave(tempSelectedUsers);
    }
  };

  const handleCancel = () => {
    togglePicker();
  };

  const handleRemoveUser = user => {
    const updatedUsers = selectedUsers.filter(
      selectedUser => selectedUser.id !== user.id
    );

    onSave(updatedUsers);
  };

  const handleApplyFilters = updatedFilters =>
    setSeletedFilters(updatedFilters);

  const handleSearch = value => setSearch(trimString(value));

  const handleSelectUser = selectedUser => {
    const user = users.find(u => u.id === selectedUser.id);
    const isSelected = tempSelectedUsers.some(u => u.id === selectedUser.id);

    return setTempSelectedUsers(prevSelected =>
      isSelected
        ? prevSelected.filter(u => u.id !== selectedUser.id)
        : [...prevSelected, user]
    );
  };

  const handleSelectAll = e => {
    const { checked } = e.target;

    if (checked) {
      setTempSelectedUsers(getUnionOfTwoArrays(tempSelectedUsers, users));
    } else {
      setTempSelectedUsers(
        removeArrayOfObjectsFromArray(tempSelectedUsers, users)
      );
    }
  };

  return (
    <div className={className}>
      {translations.label && (
        <FormHelperText>{translations.label}</FormHelperText>
      )}
      <CustomButton
        className={classes.addButton}
        type="peopleDarkRoundedOutlined"
        onClick={togglePicker}
      >
        {translations.addPeopleLabel}
      </CustomButton>
      {!isArrayEmpty(selectedUsers) && (
        <div className={classes.selectedUsersWrapper}>
          <CustomScrollBar
            customScrollBarYClass={classes.scrollY}
            verticalScroll
            removeScrollX
            passContentHeight
            passContentWidth
          >
            <UsersList
              selectedUserWrapperClass={selectedUserWrapperClass}
              users={selectedUsers}
              onRemoveUser={handleRemoveUser}
              isUserRemovable
            />
          </CustomScrollBar>
        </div>
      )}
      <Picker
        translations={translations.picker}
        isOpened={isPickerOpened}
        selectedFilters={selectedFilters}
        filters={filters}
        searchTerm={search}
        users={users}
        selectedUsers={tempSelectedUsers}
        areAllSelected={areAllUsersSelected}
        onSelectAll={handleSelectAll}
        onSelectUser={handleSelectUser}
        onSearch={handleSearch}
        onApplyFilters={handleApplyFilters}
        onCancel={handleCancel}
        onSave={handleSave}
        onClearAll={() => setTempSelectedUsers([])}
      />
    </div>
  );
};

MultipartPeoplePicker.defaultProps = {
  className: undefined,
  selectedUserWrapperClass: undefined,
  selectedUsers: [],
};

MultipartPeoplePicker.propTypes = {
  className: PropTypes.string,
  selectedUserWrapperClass: PropTypes.string,
  translations: PropTypes.object.isRequired,
  filters: PropTypes.arrayOf(PropTypes.object).isRequired,
  allUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectedUsers: PropTypes.arrayOf(PropTypes.object.isRequired),
  onGetUsers: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default memo(MultipartPeoplePicker);
