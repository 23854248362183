import { memo, cloneElement, Children, useState } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup } from 'react-transition-group';
import { Fade } from '@material-ui/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const ReorderItems = ({
  className,
  itemClass,
  children,
  isDragDisabled,
  onSave,
}) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleDragStart = () => {
    setIsVisible(false);
  };

  const handleDragEnd = result => {
    setIsVisible(true);
    onSave(result);
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
      <Droppable droppableId="list">
        {provided => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            <TransitionGroup className={className}>
              {Children.map(children, (child, index) => (
                <Fade className={itemClass} in appear>
                  <div>
                    <Draggable
                      key={`dragable_item_${child.key}`}
                      draggableId={child.props.id.toString()}
                      index={index}
                      isDragDisabled={isDragDisabled}
                    >
                      {providedItem =>
                        cloneElement(child, {
                          dragProps: providedItem,
                          dragRef: providedItem.innerRef,
                          isVisible,
                        })
                      }
                    </Draggable>
                  </div>
                </Fade>
              ))}
            </TransitionGroup>
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

ReorderItems.defaultProps = {
  className: undefined,
  itemClass: undefined,
  isDragDisabled: false,
};

ReorderItems.propTypes = {
  className: PropTypes.string,
  itemClass: PropTypes.string,
  children: PropTypes.node.isRequired,
  isDragDisabled: PropTypes.bool,
  onSave: PropTypes.func.isRequired,
};

export default memo(ReorderItems);
