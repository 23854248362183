import { createAction, handleActions } from 'redux-actions';
import http from '../../utility/http';
import { isObjectEmpty, replaceObjectInList } from '../../utility/helpers';
import {
  API_MY_COURSES,
  API_ONE_ON_ONE_MY_HISTORY,
  API_ONE_ON_ONE_USER_REQUEST,
  API_TO_DO,
  API_TEAM_STATS,
  api_user_action_plans,
  api_user_tasks,
  api_task_completion,
} from '../../constants/apiRoutes';
import {
  DASHBOARD_DEFAULT_PARAMS,
  MY_COURSES_ORDERING,
} from '../../constants/dashboard';

// ------------------------------------
// Constants
// ------------------------------------

const SET_MY_TO_DO = 'SET_MY_TO_DO';
const SET_MY_TEAM_STATS = 'SET_MY_TEAM_STATS';
const SET_MY_PLANS = 'SET_MY_PLANS';
const LOAD_MORE_PLANS = 'LOAD_MORE_PLANS';
const SET_MY_COURSES = 'SET_MY_COURSES';
const LOAD_MORE_COURSES = 'LOAD_MORE_COURSES';
const SET_MY_ONE_ON_ONE = 'SET_MY_ONE_ON_ONE';
const LOAD_MORE_ONE_ON_ONES = 'LOAD_MORE_ONE_ON_ONES';
const SET_MY_ONE_ON_ONE_REQUEST = 'SET_MY_ONE_ON_ONE_REQUEST';
const SET_MY_TASKS = 'SET_MY_TASKS';
const LOAD_MORE_TASKS = 'LOAD_MORE_TASKS';
const SET_MY_TASK_STATUS = 'SET_MY_TASK_STATUS';
const SET_LOADERS = 'SET_LOADERS';

const initialState = {
  loadings: {
    myPlansLoader: true,
    myCoursesLoader: true,
    myOneOnOneLoader: true,
    myTasksLoader: true,
    myToDoLoader: true,
    myTeamStatsLoader: true,
  },
  myPlans: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  myCourses: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  myOneOnOne: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  myOnOneRequest: {},
  myTasks: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  myToDo: [],
  myTeamStats: [],
};

// ------------------------------------
// Actions
// ------------------------------------

const setMyToDo = createAction(SET_MY_TO_DO);
const setMyTeamStats = createAction(SET_MY_TEAM_STATS);
const setMyPlans = createAction(SET_MY_PLANS);
const loadMorePlans = createAction(LOAD_MORE_PLANS);
const setMyCourses = createAction(SET_MY_COURSES);
const loadMoreCourses = createAction(LOAD_MORE_COURSES);
const setMyOneOnOne = createAction(SET_MY_ONE_ON_ONE);
const loadMoreOneOnOne = createAction(LOAD_MORE_ONE_ON_ONES);
const setMyOneOnOneRequest = createAction(SET_MY_ONE_ON_ONE_REQUEST);
const setMyTasks = createAction(SET_MY_TASKS);
const loadMoreTasks = createAction(LOAD_MORE_TASKS);
const setMyTaskStatus = createAction(SET_MY_TASK_STATUS);
export const setLoaders = createAction(SET_LOADERS);

// ------------------------------------
// Reducers
// ------------------------------------

const reducers = {
  [SET_MY_TO_DO]: (state, { payload }) => ({ ...state, myToDo: payload }),
  [SET_MY_PLANS]: (state, { payload }) => ({ ...state, myPlans: payload }),
  [LOAD_MORE_PLANS]: (state, { payload }) =>
    isObjectEmpty(payload)
      ? { ...state, myPlans: payload }
      : {
          ...state,
          myPlans: {
            ...payload,
            results: state.myPlans.results
              ? [...state.myPlans.results, ...payload.results]
              : [...payload.results],
          },
        },
  [SET_MY_COURSES]: (state, { payload }) => ({ ...state, myCourses: payload }),
  [LOAD_MORE_COURSES]: (state, { payload }) =>
    isObjectEmpty(payload)
      ? { ...state, myCourses: payload }
      : {
          ...state,
          myCourses: {
            ...payload,
            results: state.myCourses.results
              ? [...state.myCourses.results, ...payload.results]
              : [...payload.results],
          },
        },
  [SET_MY_ONE_ON_ONE]: (state, { payload }) => ({
    ...state,
    myOneOnOne: payload,
  }),
  [LOAD_MORE_ONE_ON_ONES]: (state, { payload }) =>
    isObjectEmpty(payload)
      ? { ...state, myOneOnOne: payload }
      : {
          ...state,
          myOneOnOne: {
            ...payload,
            results: state.myOneOnOne.results
              ? [...state.myOneOnOne.results, ...payload.results]
              : [...payload.results],
          },
        },
  [SET_MY_ONE_ON_ONE_REQUEST]: (state, { payload }) => ({
    ...state,
    myOnOneRequest: payload,
  }),
  [SET_MY_TASKS]: (state, { payload }) => ({ ...state, myTasks: payload }),
  [LOAD_MORE_TASKS]: (state, { payload }) =>
    isObjectEmpty(payload)
      ? { ...state, myTasks: payload }
      : {
          ...state,
          myTasks: {
            ...payload,
            results: state.myTasks.results
              ? [...state.myTasks.results, ...payload.results]
              : [...payload.results],
          },
        },
  [SET_MY_TASK_STATUS]: (state, { payload }) => {
    const taskIndex = state.myTasks.results.findIndex(
      currentTask => currentTask.id === payload.id
    );

    return {
      ...state,
      myTasks: {
        ...state.myTasks,
        results: replaceObjectInList(state.myTasks.results, taskIndex, {
          ...state.myTasks.results[taskIndex],
          is_finished: payload.is_finished,
        }),
      },
    };
  },
  [SET_MY_TEAM_STATS]: (state, { payload }) => ({
    ...state,
    myTeamStats: payload,
  }),
  [SET_LOADERS]: (state, { payload }) => ({
    ...state,
    loadings: { ...state.loadings, ...payload },
  }),
};

// ------------------------------------
// API calls
// ------------------------------------

export const getMyToDo = dispatch =>
  http
    .get(API_TO_DO)
    .then(({ data }) => dispatch(setMyToDo(data)))
    .finally(() => {
      dispatch(setLoaders({ myToDoLoader: false }));
    });

export const getMyActionPlans = (
  dispatch,
  userId,
  params = {},
  isLoadMore = false
) =>
  http
    .get(api_user_action_plans(userId), {
      params: {
        ...DASHBOARD_DEFAULT_PARAMS,
        ...params,
      },
    })
    .then(({ data }) =>
      isLoadMore ? dispatch(loadMorePlans(data)) : dispatch(setMyPlans(data))
    )
    .finally(() => {
      dispatch(setLoaders({ myPlansLoader: false }));
    });

export const getMyCourses = (dispatch, params = {}, isLoadMore = false) =>
  http
    .get(API_MY_COURSES, {
      params: {
        ...DASHBOARD_DEFAULT_PARAMS,
        ...MY_COURSES_ORDERING,
        ...params,
      },
    })
    .then(({ data }) =>
      isLoadMore
        ? dispatch(loadMoreCourses(data))
        : dispatch(setMyCourses(data))
    )
    .finally(() => {
      if (!isLoadMore) {
        dispatch(setLoaders({ myCoursesLoader: false }));
      }
    });

export const getMyOneOnOne = (dispatch, params = {}, isLoadMore = false) =>
  http
    .get(API_ONE_ON_ONE_MY_HISTORY, {
      params: {
        ...DASHBOARD_DEFAULT_PARAMS,
        ...params,
      },
    })
    .then(({ data }) =>
      isLoadMore
        ? dispatch(loadMoreOneOnOne(data))
        : dispatch(setMyOneOnOne(data))
    );

export const getMyOneOnOneRequest = dispatch =>
  http.get(API_ONE_ON_ONE_USER_REQUEST).then(({ data }) => {
    dispatch(setMyOneOnOneRequest(data));
  });

export const getMyTasks = (dispatch, userId, params = {}, isLoadMore = false) =>
  http
    .get(api_user_tasks(userId), {
      params: {
        ...DASHBOARD_DEFAULT_PARAMS,
        ...params,
      },
    })
    .then(({ data }) => {
      if (isLoadMore) {
        dispatch(loadMoreTasks(data));
      } else {
        dispatch(setMyTasks(data));
      }

      return data;
    })
    .finally(() => {
      dispatch(setLoaders({ myTasksLoader: false }));
    });

export const changeTaskStatus = (dispatch, taskId) =>
  http
    .patch(api_task_completion(taskId))
    .then(({ data }) => dispatch(setMyTaskStatus(data)));

export const getMyTeamStats = dispatch =>
  http
    .get(API_TEAM_STATS)
    .then(({ data }) => {
      dispatch(setMyTeamStats(data));

      return data;
    })
    .finally(() => {
      dispatch(setLoaders({ myTeamStatsLoader: false }));
    });

export default handleActions(reducers, initialState);

// ------------------------------------
// "plain" action functions
// ------------------------------------

export const clearMyActionPlans = () => setMyPlans(initialState.myPlans);

export const clearMyCourses = () => setMyCourses(initialState.myCourses);

export const clearMyOneOnOne = () => setMyOneOnOne(initialState.myOneOnOne);

export const clearMyOneOnOneRequest = () =>
  setMyOneOnOneRequest(initialState.myOnOneRequest);

export const clearMyTasks = () => setMyTasks(initialState.myTasks);

export const clearMyToDo = () => setMyToDo(initialState.myToDo);

export const clearMyTeamStats = () => setMyTeamStats(initialState.myTeam);

export const clearAllLoaders = () => setLoaders(initialState.loadings);
