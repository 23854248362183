import { useMemo, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import Select, { createFilter } from 'react-select';
import classNames from 'classnames';
import {
  FormControl,
  FormHelperText,
  Typography,
  makeStyles,
} from '@material-ui/core';
import Tooltip from '../tooltip';
import Control from '../selectField/control';
import NoOptions from '../selectField/noOptions';
import Input from '../selectField/input';
import Menu from '../selectField/menu';
import MenuList from '../selectField/menuList';
import SingleValue from '../selectField/singleValue';
import Option from '../selectField/option';
import Placeholder from '../selectField/placeholder';
import ValueContainer from '../selectField/valueContainer';
import ClearIndicator from '../selectField/clearIndicator';
import DropdownIndicator from '../selectField/dropdownIndicator';
import GroupHeading from '../selectField/groupHeading';
import SelectedItems from './selectedItems';
import {
  isObjectEmpty,
  isArrayEmpty,
  isItemInList,
} from '../../../utility/helpers';
import { useTranslations } from '../../../utility/useTranslations';
import { APP_COMPONENTS } from '../../../constants/pages';
import { getOptions, getParsedValueWithRange } from './config';

const useStyles = makeStyles(({ spacing }) => ({
  main: { minWidth: 205 },
  root: { flexGrow: 1 },
  labelIcon: {
    marginLeft: spacing(1),
    width: 14,
    height: 14,
    cursor: 'pointer',
  },
  labelBlock: {
    display: 'flex',
    alignItems: 'end',
  },
  attributesLabel: {
    width: 201,
  },
  select: {
    marginBottom: spacing(1),
  },
  range: {
    width: 77,
    marginLeft: spacing(2),
  },
}));

const SelectWithRangeField = ({
  className,
  selectFieldClass,
  labelHelpClass,
  errorClass,
  labelClass,
  labelHelp,
  value,
  errorMessage,
  shouldRemoveLabel,
  parser,
  options,
  creatableOptionLabel,
  renderRolesTooltip,
  creatableOptionField,
  shouldReturnOption,
  shouldHideMultipleResults,
  shouldDisableSort,
  isAttributeDraggable,
  isScrollableResult,
  isCategorized,
  isSearchDisabled,
  isFullWidth,
  isCreatable,
  onCreateOption,
  onChange,
  onChangeRange,
  ...rest
}) => {
  const classes = useStyles();
  const translations = useTranslations(APP_COMPONENTS.SELECT_FIELD);
  const { id, label, hasError } = rest;

  const parsedOptions = getOptions(options, parser);

  const parsedValue = useMemo(
    () => getParsedValueWithRange(parsedOptions, value),
    [parsedOptions, value]
  );

  const components = {
    Control,
    Menu,
    NoOptionsMessage: NoOptions,
    MenuList,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer,
    DropdownIndicator,
    ClearIndicator,
    IndicatorSeparator: null,
    Input,
    GroupHeading,
  };

  const getDisabledOption = useCallback(
    option =>
      option.isDisabled || isItemInList(parsedValue, option, parser.value),
    [parsedValue, parser]
  );

  const handleChange = useCallback(
    selected => {
      return onChange(selected || []);
    },
    [onChange]
  );

  const handleDeselectItem = useCallback(
    selectedItem => {
      const updatedItems = parsedValue.filter(
        sItem => sItem[parser.value] !== selectedItem[parser.value]
      );

      return onChange(updatedItems);
    },
    [onChange, parsedValue, parser]
  );

  return (
    <FormControl
      className={classNames(classes.main, className)}
      fullWidth={isFullWidth}
    >
      {!shouldRemoveLabel && (
        <FormHelperText classes={{ root: labelClass }}>
          <span>{label.label}</span>
          {!isObjectEmpty(labelHelp) && (
            <Tooltip
              customIconClass={classNames(classes.labelIcon, labelHelpClass)}
              text={labelHelp.tooltipText}
            />
          )}
        </FormHelperText>
      )}
      <Select
        className={classNames(classes.select, selectFieldClass)}
        translations={translations}
        instanceId={id}
        styles={{ option: () => ({}) }}
        value={parsedValue}
        options={parsedOptions}
        components={components}
        isSearchable={!isSearchDisabled}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        isOptionDisabled={getDisabledOption}
        filterOption={createFilter({ ignoreAccents: false })}
        onChange={handleChange}
        menuShouldScrollIntoView
        captureMenuScroll
        {...rest}
      />
      {!isArrayEmpty(parsedValue) && (
        <>
          <div className={classes.labelBlock}>
            <Typography variant="body2" className={classes.attributesLabel}>
              {label.attributesLabel}
            </Typography>
            <Typography variant="body2" className={classes.range}>
              {label.rangeFromLabel}
            </Typography>
            <Typography variant="body2" className={classes.range}>
              {label.rangeToLabel}
            </Typography>
          </div>
          <SelectedItems
            items={parsedValue}
            isScrollableResult={isScrollableResult}
            onChangeRange={onChangeRange}
            onDeselectItem={handleDeselectItem}
          />
        </>
      )}
      {hasError && errorMessage && (
        <FormHelperText classes={{ error: errorClass }} error={hasError}>
          {errorMessage}
        </FormHelperText>
      )}
    </FormControl>
  );
};

SelectWithRangeField.defaultProps = {
  className: '',
  controlDisabledClass: '',
  errorClass: '',
  labelClass: '',
  labelHelpClass: '',
  selectFieldClass: '',
  controlClass: '',
  controlFocusClass: '',
  menuClass: '',
  menuListClass: '',
  id: undefined,
  label: {},
  placeholder: '',
  options: [],
  parser: {
    label: 'label',
    value: 'value',
    categoryLabel: 'categoryLabel',
    categoryValue: 'categoryValue',
  },
  renderRolesTooltip: [],
  value: '',
  creatableOptionLabel: '',
  isDisabled: false,
  hasError: false,
  errorMessage: '',
  isAttributeDraggable: false,
  isCreatable: false,
  isAttribute: false,
  isTag: false,
  isUser: false,
  isFullWidth: false,
  shouldHideMultipleResults: false,
  shouldDisableSort: false,
  isMulti: true,
  isSearchDisabled: false,
  isClearable: false,
  isOptionValueCentered: false,
  shouldReturnOption: false,
  labelHelp: {},
  creatableOptionField: {},
  isValueHighlighted: false,
  isCategorized: false,
  hasHash: false,
  shouldRemoveLabel: false,
  hasColorBox: false,
  hasCategoryColorBox: false,
  onCreateOption: () => {},
};

SelectWithRangeField.propTypes = {
  className: PropTypes.string,
  menuClass: PropTypes.string,
  menuListClass: PropTypes.string,
  selectFieldClass: PropTypes.string,
  controlDisabledClass: PropTypes.string,
  controlClass: PropTypes.string,
  controlFocusClass: PropTypes.string,
  errorClass: PropTypes.string,
  labelClass: PropTypes.string,
  labelHelpClass: PropTypes.string,
  id: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  parser: PropTypes.object,
  label: PropTypes.object,
  placeholder: PropTypes.string,
  hasHash: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
    PropTypes.number,
    PropTypes.bool,
  ]),
  creatableOptionLabel: PropTypes.string,
  creatableOptionField: PropTypes.object,
  errorMessage: PropTypes.string,
  isDisabled: PropTypes.bool,
  hasError: PropTypes.bool,
  isAttribute: PropTypes.bool,
  isTag: PropTypes.bool,
  isUser: PropTypes.bool,
  isFullWidth: PropTypes.bool,
  isAttributeDraggable: PropTypes.bool,
  shouldHideMultipleResults: PropTypes.bool,
  shouldDisableSort: PropTypes.bool,
  isMulti: PropTypes.bool,
  isSearchDisabled: PropTypes.bool,
  isCreatable: PropTypes.bool,
  isClearable: PropTypes.bool,
  isValueHighlighted: PropTypes.bool,
  isOptionValueCentered: PropTypes.bool,
  isCategorized: PropTypes.bool,
  labelHelp: PropTypes.object,
  shouldRemoveLabel: PropTypes.bool,
  shouldReturnOption: PropTypes.bool,
  renderRolesTooltip: PropTypes.array,
  hasColorBox: PropTypes.bool,
  hasCategoryColorBox: PropTypes.bool,
  onCreateOption: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onChangeRange: PropTypes.func.isRequired,
};

export default memo(SelectWithRangeField);
