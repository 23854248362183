import { isUserDeactivated } from '../../../utility/helpers';
import { getPersonFullName } from '../../../utility/uiUtils';
import { usersValidator } from '../peoplePage/config';
import { USER_INFO, USER_FIELDS } from '../../../constants/people';
import { USER_STATUSES } from '../../../constants/statuses';
import { FIELD_TYPES } from '../../../constants/fieldTypes';
import {
  ACCOUNT_TYPES,
  ROLES,
} from '../../../constants/rolesAndPermissionList';
import { RESET_PASSWORD_URL } from '../../../constants/appConfig';

const { FIRST_NAME, LAST_NAME, EMAIL, ROLE, REPORT_TO, STATUS } = USER_INFO;

export const getUserRoles = labels =>
  ACCOUNT_TYPES.slice(0, -1).map(role => ({
    value: role.value,
    label: labels[role.label],
  }));

export const getSlackUsersInitialValues = (
  organizationMentor,
  slackUsers = []
) => {
  const { ACTIVE_WITHOUT_ACCESS } = USER_STATUSES;

  return {
    users: [
      ...slackUsers.map(user => ({
        [FIRST_NAME]: user[FIRST_NAME] || '',
        [LAST_NAME]: user[LAST_NAME] || '',
        [EMAIL]: user[EMAIL],
        [ROLE]: ROLES.USER,
        [REPORT_TO]: [organizationMentor],
        [STATUS]: ACTIVE_WITHOUT_ACCESS,
        slack_id: user.slack_id,
        slack_image: user.slack_image,
        activation_url: RESET_PASSWORD_URL,
        client_domain: window.document.location.origin,
      })),
    ],
  };
};

export const getImportUsersFields = (
  description,
  organizationMentor,
  roles,
  reportToOptions,
  slackUsers,
  organizationSettings,
  getOrganizationSettings,
  onMentorChange
) => {
  const reportToInitialValue = getPersonFullName(organizationMentor);
  const columns = [
    { ...USER_FIELDS.FIRST_NAME },
    { ...USER_FIELDS.LAST_NAME },
    { ...USER_FIELDS.EMAIL },
    {
      ...USER_FIELDS.REPORT_TO,
      initialValue: reportToInitialValue,
    },
    { ...USER_FIELDS.ROLE },
    { ...USER_FIELDS.INVITED, key: 'invite' },
  ];

  return [
    {
      name: 'users',
      type: FIELD_TYPES.ADD_USERS_TABLE,
      description,
      columns,
      roles,
      reportToOptions,
      hasInvite: true,
      hasAddButton: false,
      hasDelete: true,
      onMentorChange,
      usersValidator,
      validators: [
        {
          validator: users =>
            usersValidator(
              users,
              columns,
              slackUsers,
              -1,
              organizationSettings,
              getOrganizationSettings
            ),
          isMultiple: true,
        },
      ],
    },
  ];
};

export const prepareUsersForSave = users =>
  users.map(user => ({ ...user, [REPORT_TO]: user[REPORT_TO][0].id }));

export const prepareSlackUsers = (users, currentUserId) =>
  users.reduce(
    (acc, user) => {
      if (
        user.id &&
        user.slack_delete &&
        !isUserDeactivated(user) &&
        user.id !== currentUserId
      ) {
        return {
          ...acc,
          deactivated: [...acc.deactivated, user],
        };
      }

      if (!user.id && !user.slack_delete) {
        return {
          ...acc,
          available: [
            ...acc.available,
            {
              ...user,
              id: user.slack_id,
              role: 'USER',
              first_name: user.slack_first_name,
              last_name: user.slack_last_name,
            },
          ],
        };
      }

      return acc;
    },
    { deactivated: [], available: [] }
  );

export const prepareDeactivatedUsers = (
  deactivatedUserIds,
  subordinates,
  organizationMentorId
) => {
  const { DEACTIVATED } = USER_STATUSES;
  const deactivatedUsers = deactivatedUserIds.reduce(
    (acc, userId) => [
      ...acc,
      {
        id: userId,
        [REPORT_TO]: organizationMentorId,
        status: DEACTIVATED,
      },
    ],
    []
  );
  const usersToTransfer = subordinates.reduce(
    (acc, user) => [
      ...acc,
      {
        id: user.id,
        [REPORT_TO]: organizationMentorId,
      },
    ],
    []
  );

  return [...deactivatedUsers, ...usersToTransfer];
};
