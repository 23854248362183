import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup } from 'react-transition-group';
import { Fade, makeStyles } from '@material-ui/core';
import OneOnOneRecord from './oneOnOneRecord';
import { getTeamNotificationUpdates } from '../../../utility/teamNotifications';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'block',
    width: '100%',
  },
  recordWrapper: {
    marginBottom: spacing(4),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}));

const OneOnOneRecords = ({
  translations,
  records,
  notifications,
  isDisabled,
  canManageUser,
  onDeleteRecord,
  onEditRecord,
  onManageTasks,
  onRescheduleRecord,
  onOpenCustomTopic,
  onAddImpression,
  isMiniCard,
  onCardClickHandler,
  canSeeUserProfileCheck,
  goToUserProfilePage,
  onClearNotifications,
}) => {
  const classes = useStyles();
  const notificationsData = useMemo(() => {
    if (notifications) {
      return getTeamNotificationUpdates(
        translations.notifications.updates,
        notifications.model,
        notifications.updates
      );
    }

    return null;
  }, [translations, notifications]);

  return (
    <TransitionGroup className={classes.root}>
      {records.map(record => (
        <Fade
          key={`record_${record.id}`}
          className={classes.recordWrapper}
          in
          unmountOnExit
        >
          <div>
            <OneOnOneRecord
              key={record.id}
              className={classes.item}
              record={record}
              notifications={notificationsData}
              translations={translations}
              goToUserProfilePage={goToUserProfilePage}
              canSeeUserProfileCheck={canSeeUserProfileCheck}
              isMiniCard={isMiniCard}
              isDisabled={isDisabled}
              canManageUser={canManageUser}
              hasNotifications={notifications?.objectId === record.id}
              isNotificationUpdate={!!notifications?.isUpdate}
              onDelete={onDeleteRecord(record)}
              onEdit={onEditRecord(record)}
              onManageTasks={onManageTasks(record)}
              onReschedule={onRescheduleRecord(record)}
              onOpenCustomTopic={onOpenCustomTopic(record)}
              onAddImpression={onAddImpression(record)}
              onCardClickHandler={onCardClickHandler}
              onClearNotifications={onClearNotifications}
            />
          </div>
        </Fade>
      ))}
    </TransitionGroup>
  );
};

OneOnOneRecords.defaultProps = {
  notifications: null,
  isMiniCard: false,
  isDisabled: false,
  canManageUser: false,
  onOpenCustomTopic: () => {},
  onRescheduleRecord: () => {},
  onEditRecord: () => {},
  onDeleteRecord: () => {},
  onAddImpression: () => {},
  onCardClickHandler: () => {},
  onManageTasks: () => {},
};

OneOnOneRecords.propTypes = {
  translations: PropTypes.shape({}).isRequired,
  records: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  notifications: PropTypes.object,
  isMiniCard: PropTypes.bool,
  isDisabled: PropTypes.bool,
  canManageUser: PropTypes.bool,
  onDeleteRecord: PropTypes.func,
  onEditRecord: PropTypes.func,
  onOpenCustomTopic: PropTypes.func,
  onRescheduleRecord: PropTypes.func,
  onAddImpression: PropTypes.func,
  onCardClickHandler: PropTypes.func,
  onManageTasks: PropTypes.func,
  goToUserProfilePage: PropTypes.func.isRequired,
  canSeeUserProfileCheck: PropTypes.func.isRequired,
  onClearNotifications: PropTypes.func.isRequired,
};

export default OneOnOneRecords;
