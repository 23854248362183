import { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/core';
import { FIELD_TYPES } from '../../../constants/fieldTypes';
import TextBoxWithTooltip from '../textBoxWithTooltip';
import ActionButton from '../actionButton';
import Tooltip from '../tooltip';
import { ReactComponent as TextReplyIcon } from '../../../assets/icons/text_snippet.svg';
import { useTranslations } from '../../../utility/useTranslations';
import { APP_COMPONENTS } from '../../../constants/pages';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';
import SelectField from '../selectField';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    padding: spacing(0, 2),
    height: 28,
    transition: 'background-color .25s ease',
    maxWidth: '100%',
    minWidth: 0,
    justifyContent: 'space-between',
    width: 400,
  },
  selectableAttribute: {
    cursor: 'pointer',
  },
  hasRemove: {
    padding: spacing(0, 0, 0, 0),
  },
  attribute: {
    width: 185,
    alignItems: 'center',
    padding: spacing(1, 2, 1, 2),
    backgroundColor: ({ color, isTextReply }) =>
      isTextReply || !color ? primary.bluish8 : color,

    lineHeight: '20px',
    justifySelf: 'start',
    maxWidth: '100%',
  },
  textReplyAttribute: {
    border: ({ color }) => `4px solid ${color}`,
  },
  button: {
    flexShrink: 0,
    width: 24,
    height: 24,
    marginLeft: spacing(1),
  },
  icon: {
    width: 16,
    height: 16,
  },
  attributeBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  attributeIcon: {
    cursor: 'default',
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    marginRight: spacing(1),
    '& svg': {
      '& g, & path': {
        fill: primary.bluish5,
      },
    },
    '& g, & path': {
      fill: primary.bluish5,
    },
  },
  attributeIconSelected: {
    '& svg': {
      '& g, & path': {
        fill: primary.bluish1,
      },
    },
    '& g, & path': {
      fill: primary.bluish1,
    },
  },
  dragButton: {
    width: 22,
    height: 22,
    flexShrink: 0,
    marginRight: spacing(1),
    '& button': {
      width: 22,
      height: 22,
    },
  },
  dragIcon: {
    width: 8,
    height: 14,
  },
  dragTooltipLabel: {
    fontFamily: 'ProximaNova-Regular',
  },
  customClassName: {
    minWidth: 77,
  },
  customMenuClass: {
    '& > div': {
      maxWidth: 77,
      maxHeight: 28,
      minHeight: 28,
    },
    maxWidth: 77,
    minHeight: 28,
    marginLeft: spacing(2),
  },
  inputRange: {
    width: 77,
    height: 28,
    minHeight: 28,
    marginLeft: spacing(2),
  },
  block: {
    justifyContent: 'space-between',
  },
}));

const RANGE_VALUES = Array.from({ length: 11 }, (x, i) => {
  return { id: i };
});

const AttributeChipWithRange = ({
  customClass,
  style,
  color,
  name,
  isTextReply,
  attributeNumber,
  attribute,
  isSelectable,
  isSelected,
  hasRemove,
  onSelect,
  onRemove,
  onChangeRange,
}) => {
  const classes = useStyles({ color, isTextReply });
  const translations = useTranslations(APP_COMPONENTS.ATTRIBUTE_CHIP);

  const handleRemove = useCallback(
    () => onRemove(attribute),
    [attribute, onRemove]
  );

  const handleSelect = useCallback(() => {
    if (isSelectable) {
      onSelect(attribute);
    }
  }, [attribute, isSelectable, onSelect]);

  const handleChangeRange = useCallback(
    (val, fieldName) => {
      onChangeRange(val, attribute, fieldName);
    },
    [attribute, onChangeRange]
  );

  return (
    <div
      className={classNames(
        classes.root,
        {
          [classes.block]: !attribute.text_reply_questions,
          [classes.textReplyAttribute]: isTextReply,
          [classes.hasRemove]: hasRemove,
          [classes.selectableAttribute]: isSelectable,
        },
        customClass
      )}
      {...(style ? { style } : {})}
      onClick={handleSelect}
    >
      <TextBoxWithTooltip
        variant="subtitle2"
        className={classes.attribute}
        text={name}
        itemNumber={attributeNumber}
      />
      {!attribute.text_reply_questions && (
        <>
          <SelectField
            className={classes.customClassName}
            menuClass={classes.customMenuClass}
            controlClass={classes.inputRange}
            value={attribute[FIELD_TYPES.RANGE_FROM]}
            options={RANGE_VALUES}
            parser={{ value: 'id', label: 'id' }}
            onChange={val => handleChangeRange(val, FIELD_TYPES.RANGE_FROM)}
            isSearchDisabled
            shouldRemoveLabel
          />
          <SelectField
            className={classes.customClassName}
            menuClass={classes.customMenuClass}
            controlClass={classes.inputRange}
            value={attribute[FIELD_TYPES.RANGE_TO]}
            options={RANGE_VALUES}
            parser={{ value: 'id', label: 'id' }}
            onChange={val => handleChangeRange(val, FIELD_TYPES.RANGE_TO)}
            isSearchDisabled
            shouldRemoveLabel
          />
        </>
      )}
      <div className={classes.attributeBlock}>
        {attribute.text_reply_questions && (
          <Tooltip
            customIconClass={classNames(classes.attributeIcon, {
              [classes.attributeIconSelected]: isSelected,
            })}
            text={translations.openEndedAttribute}
            icon={TextReplyIcon}
          />
        )}
        {hasRemove && (
          <ActionButton
            className={classes.button}
            iconClass={classes.icon}
            type={ACTION_BUTTON_TYPES.CLOSE}
            size="small"
            onClickHandler={handleRemove}
          />
        )}
      </div>
    </div>
  );
};

AttributeChipWithRange.defaultProps = {
  customClass: null,
  isTextReply: false,
  hasRemove: false,
  isAttributeDraggable: false,
  isSelected: false,
  isSelectable: false,
  attributeNumber: 0,
  attribute: {},
  style: null,
  color: undefined,
  onRemove: () => {},
};

AttributeChipWithRange.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  style: PropTypes.object,
  customClass: PropTypes.string,
  attribute: PropTypes.object,
  isTextReply: PropTypes.bool,
  hasRemove: PropTypes.bool,
  isSelectable: PropTypes.bool,
  isSelected: PropTypes.bool,
  isAttributeDraggable: PropTypes.bool,
  attributeNumber: PropTypes.number,
  onRemove: PropTypes.func,
  onChangeRange: PropTypes.func.isRequired,
};

export default memo(AttributeChipWithRange);
