import { useState, useEffect, useCallback, useMemo, memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import { Typography, Fade, alpha, makeStyles } from '@material-ui/core';
import PageContainer from '../../shared/pageContainer';
import CustomButton from '../../shared/customButton';
import CustomFormDrawer from '../../shared/customFormDrawer';
import NotificationCard from '../../shared/notificationCard';
import SlackUsersSync from '../../shared/slackUsersSync';
import GroupUserActionDialog from '../../shared/groupUserActionDialog';
import { ReactComponent as SlackIcon } from '../../../assets/icons/slack-logo.svg';
import { ReactComponent as LogoIcon } from '../../../assets/icons/logo-dark.svg';
import { ReactComponent as NotConnectedIcon } from '../../../assets/icons/not-connected.svg';
import { ReactComponent as ConnectedIcon } from '../../../assets/icons/link.svg';
import { ReactComponent as RetryIcon } from '../../../assets/icons/refresh.svg';
import { ReactComponent as ArrowsIcon } from '../../../assets/icons/arrows-left-right.svg';
import http, { API_DOMAIN } from '../../../utility/http';
import {
  API_SLACK_DISCONNECT,
  API_SLACK_STATUS,
  API_SLACK_SYNC_USERS,
  API_USERS_MULTIPLE,
  API_USERS,
  API_ORG_CHART,
} from '../../../constants/apiRoutes';
import { EMPLOYEE_ROLES_LIST } from '../../../constants/rolesAndPermissionList';
import { USER_STATUSES } from '../../../constants/statuses';
import {
  trimString,
  isArray,
  isArrayEmpty,
  updateAuthData,
} from '../../../utility/helpers';
import { useTranslations } from '../../../utility/useTranslations';
import { updateCurrentUserInfo } from '../../../store/modules/auth';
import {
  parseDuplicateParameters,
  showSuccessMessage,
  getTimeDifference,
} from '../../../utility/uiUtils';
import { getDifferenceInDays } from '../../../utility/dateUtils';
import { SLACK_CLIENT_ID } from '../../../constants/appConfig';
import { APP_PAGES } from '../../../constants/pages';
import {
  getSlackUsersInitialValues,
  getImportUsersFields,
  getUserRoles,
  prepareUsersForSave,
  prepareSlackUsers,
  prepareDeactivatedUsers,
} from './config';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  main: {
    marginTop: spacing(8),
  },
  loginWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: spacing(4),
  },
  connectionStatus: {
    color: primary.bluish4,
  },
  connectButton: {
    fontFamily: 'ProximaNova-Bold',
    fontSize: 16,
    lineHeight: '24px',
    padding: spacing(2, 4),
  },
  retryButton: {
    maxHeight: 40,
  },
  descriptionWrapper: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
  },
  description: {
    maxWidth: 600,
  },
  placeholderWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  placeholderLarge: {
    justifyContent: 'center',
    marginTop: spacing(17.5),
  },
  contentWrapper: {
    boxSizing: 'border-box',
    borderRadius: 46,
    boxShadow: ` 0px 6px 24px 0px ${alpha(primary.black, 0.12)}`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: spacing(6, 11.5),
  },
  contentWrapperSmall: {
    borderRadius: 16,
    boxShadow: `0px 2px 8px 0px ${alpha(primary.black, 0.12)}`,
    padding: spacing(2, 4),
  },
  contentWrapperConnection: {
    padding: spacing(2),
    margin: spacing(0, 2),
    width: 32,
    height: 32,
  },
  contentWrapperTransfer: {
    margin: spacing(0, 11.5),
  },
  logoIcon: {
    marginRight: spacing(3),
    width: 45,
    height: 45,
  },
  logoIconSmall: {
    marginRight: spacing(1),
    width: 16,
    height: 16,
  },
  slackIcon: {
    width: 188,
    height: 46,
  },
  company: {
    fontSize: 28,
    lineHeight: '34px',
    userSelect: 'none',
  },
  companySmall: {
    fontSize: 10,
    lineHeight: '12px',
  },
  connectedIcon: {
    '& path': {
      fill: primary.bluish1,
    },
  },
  previewAction: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    gridColumnGap: 32,
    marginTop: spacing(11.5),
  },
  tableContent: {
    padding: spacing(6, 3, 6, 8),
  },
  limitNotification: {
    marginTop: spacing(6),
  },
}));

const SlackIntegrationPage = ({
  navigate,
  auth,
  dialogs,
  organizationSettings,
  setDialogVisibility,
  getOrganizationSettings,
  ...rest
}) => {
  const classes = useStyles();
  const translations = useTranslations(APP_PAGES.SLACK_INTEGRATION);

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isImportOpened, setIsImportOpened] = useState(false);
  const [slack, setSlack] = useState({
    connection: false,
    activated_at: null,
    team_id: null,
    team_name: null,
  });
  const [users, setUsers] = useState({
    available: [],
    deactivated: [],
  });
  const [reportTo, setReportTo] = useState([]);
  const [organizationMentor, setOrganizationMentor] = useState(null);
  const [hasLimitError, setHasLimitError] = useState(false);
  const [isSyncDeactivatedOpened, setIsSyncDeactivatedOpened] = useState(false);

  const isSlackConnected = Boolean(slack?.connection);
  const showLimitNotification = isSlackConnected && hasLimitError;
  const connectionInfo = useMemo(
    () =>
      getTimeDifference(
        getDifferenceInDays(slack?.activated_at),
        translations.timeLabels
      ).toLowerCase(),
    [slack, translations]
  );
  const redirectUrl = `https://api.${API_DOMAIN}/api/registration/slack/`;
  const state = `${window.location.origin}/slack-integration`;
  const connectUrl = `https://slack.com/oauth/v2/authorize?client_id=${SLACK_CLIENT_ID}&scope=users.profile:read,users:read,users:read.email,chat:write&redirect_uri=${redirectUrl}&state=${state}`;
  const userRoles = getUserRoles(translations.role);

  const getOrganizationMentor = useCallback(async () => {
    const { data } = await http.get(API_ORG_CHART);

    setOrganizationMentor({
      ...data,
      last_name: translations.organization,
      isCeo: true,
    });
  }, [translations]);

  const fetchSlackUsers = useCallback(async () => {
    const { data } = await http.get(API_SLACK_SYNC_USERS);

    if (data.success && isArray(data?.users)) {
      if (!isArrayEmpty(data?.users)) {
        setUsers(prepareSlackUsers(data.users, auth.id));
        setHasLimitError(false);
      } else {
        setHasLimitError(false);
      }
    } else {
      setHasLimitError(true);
      setUsers({ deactivated: [], available: [] });
    }
  }, [auth.id]);

  const getSlackStatus = useCallback(async () => {
    const { data } = await http.get(API_SLACK_STATUS);

    setSlack(data);

    if (data?.connection) {
      await Promise.all([fetchSlackUsers(), getOrganizationMentor()]);
    }
    setIsLoading(false);
  }, [fetchSlackUsers, getOrganizationMentor]);

  useEffect(() => getSlackStatus(), [getSlackStatus]);

  const getDirectReports = deactivatedUsers =>
    http.get(API_USERS, {
      params: { page_size: 1000, report_to: deactivatedUsers },
      paramsSerializer: data => parseDuplicateParameters(data),
    });

  const onSlackDisconnect = async () => {
    await http.delete(API_SLACK_DISCONNECT);
    getSlackStatus();
  };

  const toggleDeactivatedSyncDialog = () =>
    setIsSyncDeactivatedOpened(prevState => !prevState);

  const onSyncDeactivated = async selectedUsers => {
    const { data } = await getDirectReports(selectedUsers);

    const deactivatedUsers = prepareDeactivatedUsers(
      selectedUsers,
      data.results,
      organizationMentor.id
    );

    try {
      await http.patch(API_USERS_MULTIPLE, deactivatedUsers);
      showSuccessMessage(translations.success.sync);
      return fetchSlackUsers();
    } catch {
      toggleDeactivatedSyncDialog();
    }
  };

  const handleUserMentorChange =
    () =>
    (value = '') => {
      const { ACTIVE_WITHOUT_ACCESS, ACTIVE_INVITED, ACTIVE_WITH_ACCESS } =
        USER_STATUSES;
      const trimmedValue = trimString(value);

      const params = {
        page: 1,
        page_size: 12,
        ordering: 'first_name',
        search: trimmedValue,
        status: [ACTIVE_WITHOUT_ACCESS, ACTIVE_INVITED, ACTIVE_WITH_ACCESS],
        role: EMPLOYEE_ROLES_LIST,
      };

      if (trimmedValue) {
        return http
          .get(API_USERS, {
            params,
            paramsSerializer: data => parseDuplicateParameters(data),
          })
          .then(({ data }) => {
            setReportTo([organizationMentor].concat(data.results));
          });
      }

      return Promise.resolve(setReportTo([]));
    };

  const toggleSlackImport = () =>
    setIsImportOpened(prevImportOpened => !prevImportOpened);

  const onImport = async values => {
    await http.post(API_USERS_MULTIPLE, prepareUsersForSave(values.users));
    await updateAuthData(data => dispatch(updateCurrentUserInfo(data)));
    getOrganizationSettings();
    await fetchSlackUsers();
    showSuccessMessage(translations.success.import);

    return Promise.resolve();
  };

  const renderPlaceholder = (isSmall = false) => (
    <div
      className={classNames(
        classNames(classes.placeholderWrapper, {
          [classes.placeholderLarge]: !isSmall,
        })
      )}
    >
      <div
        className={classNames(classes.contentWrapper, {
          [classes.contentWrapperSmall]: isSmall,
        })}
      >
        <LogoIcon
          className={classNames(classes.logoIcon, {
            [classes.logoIconSmall]: isSmall,
          })}
        />
        <Typography
          className={classNames(classes.company, {
            [classes.companySmall]: isSmall,
          })}
          variant="overline"
        >
          {translations.app}
        </Typography>
      </div>
      {isSmall ? (
        <div
          className={classNames(
            classes.contentWrapper,
            classes.contentWrapperConnection
          )}
        >
          {isSlackConnected ? (
            <ConnectedIcon className={classes.connectedIcon} />
          ) : (
            <NotConnectedIcon />
          )}
        </div>
      ) : (
        <div className={classes.contentWrapperTransfer}>
          <ArrowsIcon />
        </div>
      )}
      <div
        className={classNames(classes.contentWrapper, {
          [classes.contentWrapperSmall]: isSmall,
        })}
      >
        <SlackIcon className={classNames({ [classes.slackIcon]: !isSmall })} />
      </div>
    </div>
  );

  return (
    <PageContainer
      {...rest}
      translations={translations}
      auth={auth}
      navigate={navigate}
      organizationSettings={organizationSettings}
      shouldPassProps={false}
    >
      <div className={classes.main}>
        {!isLoading && (
          <div>
            <div className={classes.loginWrapper}>
              <div className={classes.connectionWrapper}>
                <Typography variant="h3">
                  {isSlackConnected
                    ? translations.connected
                    : translations.notConnected}
                </Typography>
                <Typography
                  className={classes.connectionStatus}
                  variant="caption"
                >
                  {isSlackConnected
                    ? `${translations.connected} ${connectionInfo}`
                    : translations.connect}
                </Typography>
              </div>
              {isSlackConnected ? (
                <CustomButton
                  className={classes.connectButton}
                  type="withTextLightRounded"
                  onClick={onSlackDisconnect}
                >
                  {translations.disconnect}
                </CustomButton>
              ) : (
                <a href={connectUrl}>
                  <CustomButton
                    className={classes.connectButton}
                    type="withTextDarkRounded"
                    onClick={() => {}}
                  >
                    {translations.connect}
                  </CustomButton>
                </a>
              )}
            </div>
            <div className={classes.descriptionWrapper}>
              <Typography className={classes.description} variant="body2">
                {translations.description}
              </Typography>
              {renderPlaceholder(true)}
            </div>
            {!isSlackConnected && (
              <Fade in={!isSlackConnected}>{renderPlaceholder()}</Fade>
            )}
            {isSlackConnected && !hasLimitError && (
              <div className={classes.previewAction}>
                <SlackUsersSync
                  translations={translations.import}
                  users={users.available}
                  onSync={toggleSlackImport}
                  isUserImport
                />
                <SlackUsersSync
                  translations={translations.deactivate}
                  users={users.deactivated}
                  onSync={toggleDeactivatedSyncDialog}
                  isUserImport
                />
              </div>
            )}
            {showLimitNotification && (
              <NotificationCard
                className={classes.limitNotification}
                title={translations.limitError.title}
                content={translations.limitError.content}
                actionButtons={[
                  {
                    className: classNames(
                      classes.connectButton,
                      classes.retryButton
                    ),
                    label: translations.retryButtonLabel,
                    type: 'rounded',
                    startIcon: <RetryIcon />,
                    onButtonClick: fetchSlackUsers,
                  },
                ]}
              />
            )}
          </div>
        )}
        <CustomFormDrawer
          customContentClass={classes.tableContent}
          translations={translations.importSlackUsersTable}
          isOpened={isImportOpened}
          initialData={getSlackUsersInitialValues(
            organizationMentor,
            users.available
          )}
          fields={getImportUsersFields(
            translations.importSlackUsersTable.description,
            organizationMentor,
            userRoles,
            reportTo,
            users.available,
            organizationSettings,
            getOrganizationSettings,
            handleUserMentorChange
          )}
          onClose={toggleSlackImport}
          onSave={onImport}
          isFullWidth
          hasCancelButton
          hideDelete
        />
        <GroupUserActionDialog
          isOpened={isSyncDeactivatedOpened}
          translations={translations.deactivateUsersDialog}
          users={users.deactivated}
          onClose={toggleDeactivatedSyncDialog}
          onConfirm={onSyncDeactivated}
        />
      </div>
    </PageContainer>
  );
};

SlackIntegrationPage.propTypes = {
  auth: PropTypes.object.isRequired,
  dialogs: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  organizationSettings: PropTypes.object.isRequired,
  setDialogVisibility: PropTypes.func.isRequired,
  getOrganizationSettings: PropTypes.func.isRequired,
};

export default memo(SlackIntegrationPage);
