import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, withStyles } from '@material-ui/core';
import ActionButton from 'components/shared/actionButton';
import { ACTION_BUTTON_TYPES } from 'components/shared/actionButton/config';
import Search from '../../shared/search';
import GridTable from '../../shared/gridTable';
import ManageActionPlan from '../../shared/manageActionPlan';
import CustomButton from '../../shared/customButton';
import AlertDialog from '../../shared/alertDialog';
import ReadLessMore from '../../shared/readLessMore';
import UserAvatar from '../../shared/userAvatar';
import Filters from '../../shared/filters';
import StatusIndicator from '../../shared/statusIndicator';
import NotificationCard from '../../shared/notificationCard';
import DotsMenu from '../../shared/dotsMenu';
import ManageTasks from '../../shared/manageTasks';
import TasksIconTooltip from '../../shared/tasksIconTooltip';
import CoursesIconTooltip from '../../shared/coursesIconTooltip';
import ConditionalTooltip from '../../shared/conditionalTooltip';
import RequestedIconTooltip from '../../shared/requestedIconTooltip';
import { ReactComponent as ProposeIconStatus } from '../../../assets/icons/person-search.svg';
import {
  showSuccessMessage,
  parseQueryParams,
  parseDuplicateParameters,
} from '../../../utility/uiUtils';
import { onSaveCreatableTag } from '../../../utility/tagUtils';
import {
  isArrayEmpty,
  trimString,
  checkUserRole,
  isArray,
  getItemById,
  isObjectEmpty,
  isUserDeactivated,
  getManageableUsers,
} from '../../../utility/helpers';
import { validateFreemiumAction } from '../../../utility/subscriptionHelper';
import { prepareTasksForSave } from '../../../utility/tasks';
import { hasSelectedFilters } from '../../shared/filters/config';
import http from '../../../utility/http';
import {
  getActionPlanStatuses,
  getActionPlansPageFilters,
  getActionPlanFilterStatuses,
  getDotsMenuItems,
} from '../../../utility/actionPlans';
import { api_action_plan } from '../../../constants/apiRoutes';
import { GENERAL_CATEGORY_ID } from '../../../constants/tags';
import { sticky } from '../../../constants/helperCssRules';
import {
  PARAMS,
  PAGE_WHITELISTED_PARAMS,
  ACTION_PLANS_DEFAULT_PARAMS,
} from '../../../constants/pages';
import { ROLES } from '../../../constants/rolesAndPermissionList';
import { FREEMIUM_LIMIT_TYPES } from '../../../constants/appConfig';
import {
  INITIAL_SORT,
  getHeaderActions,
  getTableHeaders,
  prepareTableData,
} from './config';

const styles = ({ breakpoints, palette: { primary }, spacing }) => ({
  stickyHeader: {
    ...sticky(primary.white, 105),
    padding: spacing(8, 0, 6, 0),
  },
  table: {
    '& .grid-table-header': {
      ...sticky(primary.bluish8, 243),
      '&.grid-table-header$readMoreActive': {
        top: 297,
      },
    },
    '& .title-cell, & .due-date-cell, & .modified-on-cell': {
      '& .row-cell-value': {
        color: primary.bluish1,
      },
    },
    '& .created-for-cell': {
      '& .row-cell-value': {
        overflow: 'visible',
      },
    },
    '& .actions-cell': {
      overflow: 'visible',
      paddingLeft: 0,
      paddingTop: spacing(2),
      paddingBottom: spacing(2),
      paddingRight: spacing(1),

      [breakpoints.up('xLg')]: {
        paddingRight: spacing(3),
      },
    },
  },
  readMoreActive: {},
  rightSide: {
    display: 'flex',
    alignSelf: 'center',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  addAttribute: {
    width: 'max-content',
  },
  search: {
    width: 234,
    marginRight: spacing(2),
  },
  pageDescription: {
    marginBottom: spacing(6),
  },
  filtersSection: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  filters: {
    display: 'flex',
    alignItems: 'center',
    marginRight: spacing(2),
  },
  userLabel: {
    fontFamily: 'ProximaNova-Bold',
    fontSize: 16,
    lineHeight: '20px',
  },
  statusLabel: {
    padding: spacing(1, 2),
    fontSize: 10,
    lineHeight: '12px',
    textTransform: 'uppercase',
    textAlign: 'center',
  },
  todo: {
    width: 31,
    background: primary.bluish8,
    color: primary.blue1,
  },
  done: {
    width: 31,
    background: primary.bluish1,
    color: primary.white,
  },
  inProgress: {
    width: 65,
    background: primary.orange,
    color: primary.white,
  },
  statusWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  iconTooltip: {
    marginLeft: spacing(1.5),
  },
  proposedWrapper: {
    border: `1px solid ${primary.bluish1}`,
    display: 'flex',
    alignItems: 'center',
    padding: spacing(1, 2),
    width: 'fit-content',
  },
  proposeIcon: {
    marginRight: spacing(1),
  },
  proposeText: {
    fontSize: 10,
    lineHeight: '12px',
    textTransform: 'uppercase',
    pointerEvents: 'none',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  acceptButton: {
    marginRight: spacing(2),
  },
});

const { PAGE, TAGS, SEARCH, ORDERING, COMPLETION_STATUS, USER, DUE_DATE } =
  PARAMS;

class ActionPlansPage extends PureComponent {
  state = {
    isInitialLoad: true,
    isLoading: false,
    [PAGE]: 1,
    [SEARCH]: '',
    filters: {
      [COMPLETION_STATUS]: [],
      [TAGS]: [],
      [USER]: [],
      [DUE_DATE]: [],
      ...(this.props.location?.state?.filters
        ? this.props.location.state.filters
        : {}),
    },
    [ORDERING]: INITIAL_SORT,
    isReadMore: true,
    isManagePlanOpened: false,
    isDeleteOpened: false,
    isDismissRequestOpened: false,
    isManageTasksOpened: false,
    actionPlan: {},
  };

  componentDidMount() {
    this.getInitialData();
  }

  componentWillUnmount() {
    const {
      clearAllActionPlans,
      clearTagsCategories,
      clearAllUsers,
      clearPageQuickFilters,
      clearCourses,
      clearCourseEnrolledUsers,
    } = this.props;

    clearAllActionPlans();
    clearTagsCategories();
    clearAllUsers();
    clearPageQuickFilters();
    clearCourses();
    clearCourseEnrolledUsers();
  }

  getInitialData = () => {
    const {
      location,
      translations,
      getPageQuickFilters,
      getTagsCategories,
      getAllUsers,
    } = this.props;
    const columns = getTableHeaders(translations.columns);
    const params = parseQueryParams(
      location.search,
      PAGE_WHITELISTED_PARAMS.ACTION_PLANS
    );

    if (!isObjectEmpty(params)) {
      const [ascending, descending] = params[ORDERING].split('-');
      const isAscending = !!ascending;

      return this.setState(
        {
          [ORDERING]: {
            column: getItemById(
              columns,
              isAscending ? ascending : descending,
              'sortAs'
            )?.rowKey,
            asc: isAscending,
            sortKey: params[ORDERING],
          },
          [SEARCH]: params[SEARCH]?.toString() || '',
          filters: {
            [COMPLETION_STATUS]: params[COMPLETION_STATUS]
              ? [params[COMPLETION_STATUS]]
              : [],
            [TAGS]: params[TAGS]
              ? [...(isArray(params[TAGS]) ? params[TAGS] : [params[TAGS]])]
              : [],
            [USER]: params[USER]
              ? [...(isArray(params[USER]) ? params[USER] : [params[USER]])]
              : [],
            [DUE_DATE]: params[DUE_DATE] ? [params[DUE_DATE]] : [],
          },
        },
        () => {
          return Promise.all([
            this.handlePageChange(false),
            getTagsCategories(),
            getPageQuickFilters(),
            getAllUsers(),
          ]).then(() => {
            this.setState({ isInitialLoad: false });
          });
        }
      );
    }

    return Promise.all([
      this.handlePageChange(),
      getTagsCategories(),
      getPageQuickFilters(),
      getAllUsers(),
    ]).then(() => {
      this.setState({ isInitialLoad: false });
    });
  };

  handlePageChange = (isLoadMore = false) => {
    const { history, location, getAllActionPlans } = this.props;
    const { page, filters, ordering, search } = this.state;

    const params = {
      page,
      [ORDERING]: ordering.sortKey,
      ...filters,
      ...(search ? { search } : {}),
    };
    const query = parseDuplicateParameters(params);

    if (location.search !== `?${query}`) {
      history.replace(`/action-plans/?${query}`);
    }

    return getAllActionPlans(isLoadMore, params);
  };

  onSort = newOrdering => {
    this.setState(
      { [PAGE]: ACTION_PLANS_DEFAULT_PARAMS[PAGE], [ORDERING]: newOrdering },
      () => this.handlePageChange()
    );
  };

  onSearch = text => {
    const searchTerm = trimString(text);

    this.setState(
      {
        [PAGE]: ACTION_PLANS_DEFAULT_PARAMS[PAGE],
        [SEARCH]: searchTerm,
        isLoading: true,
      },
      () =>
        this.handlePageChange().then(() => this.setState({ isLoading: false }))
    );
  };

  handleApplyFilters = filters => {
    this.setState(
      { [PAGE]: ACTION_PLANS_DEFAULT_PARAMS[PAGE], filters, isLoading: true },
      () =>
        this.handlePageChange().then(() => this.setState({ isLoading: false }))
    );
  };

  onLoadMore = () => {
    return this.setState(
      prevState => ({ ...prevState, [PAGE]: prevState[PAGE] + 1 }),
      () => this.handlePageChange(true)
    );
  };

  handleOpenManagePlan = () =>
    this.setState({
      isManagePlanOpened: true,
    });

  handleEditPlan = actionPlan =>
    this.setState({
      isManagePlanOpened: true,
      actionPlan,
    });

  handleCloseManagePlan = () => {
    this.setState({
      isManagePlanOpened: false,
      isDeleteOpened: false,
      actionPlan: {},
    });
  };

  handleConfirmPlan = () =>
    this.setState({ [PAGE]: ACTION_PLANS_DEFAULT_PARAMS[PAGE] }, () =>
      this.handlePageChange()
    );

  handleDelete = actionPlan =>
    this.setState({ actionPlan, isDeleteOpened: true });

  handleOpenDelete = () => this.setState({ isDeleteOpened: true });

  handleCloseDeleteDialog = () => this.setState({ isDeleteOpened: false });

  onDelete = () => {
    const { translations } = this.props;
    const { actionPlan, isDismissRequestOpened } = this.state;
    const successMessage = isDismissRequestOpened
      ? translations.dismissActionPlanProposalDialog.deleteSuccess
      : translations.deleteActionPlanDialog.deleteSuccess;

    return http.delete(api_action_plan(actionPlan.id)).then(() => {
      this.setState(
        {
          [PAGE]: ACTION_PLANS_DEFAULT_PARAMS[PAGE],
          isManagePlanOpened: false,
          isDeleteOpened: false,
          isDismissRequestOpened: false,
          actionPlan: {},
        },
        () => {
          this.handlePageChange();
        }
      );
      showSuccessMessage(successMessage);
    }, this.handleCloseManagePlan);
  };

  handleDismissProposal = actionPlan => {
    this.setState({ actionPlan, isDismissRequestOpened: true });
  };

  handleCloseDismissDialog = () => {
    this.setState({ actionPlan: {}, isDismissRequestOpened: false });
  };

  handleOpenManageTasks = actionPlan =>
    this.setState({ isManageTasksOpened: true, actionPlan });

  handleCloseManageTasks = () =>
    this.setState({ isManageTasksOpened: false, actionPlan: {} });

  onSaveTasks = async values => {
    const { translations } = this.props;
    const { actionPlan } = this.state;

    await http.patch(api_action_plan(actionPlan.id), {
      tasks: prepareTasksForSave(values.tasks),
      user: actionPlan.user.id,
    });
    showSuccessMessage(translations.manageTasksDialog.successMessage);

    return this.setState(
      { [PAGE]: ACTION_PLANS_DEFAULT_PARAMS[PAGE] },
      this.handlePageChange
    );
  };

  handleToggleReadMore = () => {
    this.setState(prevState => ({
      isReadMore: !prevState.isReadMore,
    }));
  };

  onCreateTag = async tagName => {
    const { addMultipleTags } = this.props;
    const tags = await onSaveCreatableTag(tagName);
    const [tag] = tags.create;

    addMultipleTags({ categoryId: GENERAL_CATEGORY_ID, tags });

    return tag;
  };

  checkCanManageFormTask = isAdmin => task => {
    const { auth } = this.props;
    const { actionPlan } = this.state;
    const isCurrentUserCreator = auth.id === actionPlan.creator?.id;
    const isNewTask = !task.id;

    return (
      isAdmin || isCurrentUserCreator || task.creator === auth.id || isNewTask
    );
  };

  handleCoursesSearch = value => {
    const { getCourses } = this.props;
    const text = trimString(value);
    const params = {
      ...(text ? { search: text } : {}),
    };

    return getCourses(params);
  };

  renderUser = (user, canAccessProfile) => {
    const { classes } = this.props;

    return (
      <UserAvatar
        labelClass={classes.userLabel}
        user={user}
        clickableCaption={canAccessProfile}
        caption
        small
      />
    );
  };

  renderStatus = (
    status,
    progress,
    hasTasks,
    courses,
    isPendingProposal,
    isProposed,
    isCreatedForCurrentUser
  ) => {
    const { classes, translations } = this.props;

    return (
      <div className={classes.statusWrapper}>
        {isPendingProposal ? (
          <>
            <div className={classes.proposedWrapper}>
              <ProposeIconStatus className={classes.proposeIcon} />
              <Typography className={classes.proposeText} variant="overline">
                {translations.proposedStatus}
              </Typography>
            </div>
            <RequestedIconTooltip
              labels={translations.proposedTooltip}
              isRequestedByCurrentUser={isCreatedForCurrentUser}
            />
            <CoursesIconTooltip
              className={classes.iconTooltip}
              text={translations.coursesIconTooltip}
              isVisible={!isArrayEmpty(courses)}
            />
          </>
        ) : (
          <>
            <StatusIndicator status={status} />
            <ConditionalTooltip
              className={classes.iconTooltip}
              message={translations.progressTooltip}
              addTooltip
            >
              <Typography variant="subtitle2">{`${progress}%`}</Typography>
            </ConditionalTooltip>
            {isProposed && (
              <RequestedIconTooltip
                labels={translations.proposedTooltip}
                isRequestedByCurrentUser={isCreatedForCurrentUser}
              />
            )}
            <TasksIconTooltip
              className={classes.iconTooltip}
              text={translations.tasksIconTooltip}
              isVisible={hasTasks}
            />
            <CoursesIconTooltip
              className={classes.iconTooltip}
              text={translations.coursesIconTooltip}
              isVisible={!isArrayEmpty(courses)}
            />
          </>
        )}
      </div>
    );
  };

  renderActions = (
    actionPlan,
    isAdmin,
    canManage,
    canManageProposal,
    isPendingProposal,
    isCreatedForCurrentUser
  ) => {
    const { classes, translations, auth } = this.props;
    const isDisabled = isUserDeactivated(actionPlan.user);
    const { ACCEPT } = ACTION_BUTTON_TYPES;
    const canManageTaks =
      isAdmin ||
      auth.id === actionPlan?.creator?.id ||
      actionPlan.user.id !== auth.id;

    return (
      <div className={classes.actions}>
        {!isDisabled && isPendingProposal && canManageProposal && (
          <ActionButton
            className={classes.acceptButton}
            type={ACCEPT}
            tooltipText={translations.actionButtons[ACCEPT.toLowerCase()]}
            onClickHandler={() => this.handleEditPlan(actionPlan)}
            isSquared
          />
        )}
        <DotsMenu
          menuItems={getDotsMenuItems(
            translations.menuItemLabels,
            actionPlan,
            canManage,
            canManageProposal,
            isDisabled,
            canManageTaks,
            isPendingProposal,
            isCreatedForCurrentUser,
            this.handleEditPlan,
            this.handleDelete,
            this.handleOpenManageTasks,
            this.handleDismissProposal
          )}
        />
      </div>
    );
  };

  render() {
    const {
      classes,
      translations,
      actionPlans,
      organizationSettings,
      auth,
      courses,
      categories,
      pageQuickFilters,
      allUsers,
      getCourses,
      getCourseEnrolledUsers,
      setDialogVisibility,
      getOrganizationSettings,
    } = this.props;
    const {
      isInitialLoad,
      isLoading,
      ordering,
      isReadMore,
      filters,
      isManagePlanOpened,
      isDeleteOpened,
      isManageTasksOpened,
      actionPlan,
      search,
      isDismissRequestOpened,
    } = this.state;
    const isAdmin = checkUserRole(auth.role, ROLES.ADMIN);
    const statuses = getActionPlanStatuses(translations.statuses);
    const statusFilters = getActionPlanFilterStatuses(translations.statuses);
    const createdForUsers = getManageableUsers(allUsers, auth, !isAdmin);

    const showLoadMore = !!actionPlans.next;

    const filterIsActive = hasSelectedFilters(filters);

    return (
      !isInitialLoad && (
        <div>
          <div className={classes.stickyHeader}>
            <div className={classes.pageDescription}>
              <ReadLessMore
                translations={translations}
                isReadMore={isReadMore}
                toggleReadMore={this.handleToggleReadMore}
              >
                <Typography variant="body2">
                  {translations.descriptionPartOne}
                </Typography>
                <Typography variant="body2">
                  {translations.descriptionPartTwo}
                </Typography>
                <Typography variant="body2">
                  {translations.descriptionPartThree}
                </Typography>
              </ReadLessMore>
            </div>
            <div className={classes.filtersSection}>
              <div className={classes.filters}>
                <Filters
                  translations={translations.filters}
                  selectedFilters={filters}
                  filters={getActionPlansPageFilters(
                    translations,
                    statusFilters,
                    categories,
                    pageQuickFilters,
                    createdForUsers,
                    true
                  )}
                  onApplyFilters={this.handleApplyFilters}
                />
              </div>
              <div className={classes.rightSide}>
                <Search
                  className={classes.search}
                  placeholder={translations.search}
                  value={search}
                  onChange={this.onSearch}
                />
                <CustomButton
                  className={classes.addAttribute}
                  type="addRoundedNew"
                  onClick={validateFreemiumAction(
                    this.handleOpenManagePlan,
                    FREEMIUM_LIMIT_TYPES.PLANS,
                    organizationSettings,
                    setDialogVisibility,
                    getOrganizationSettings
                  )}
                >
                  {translations.addActionPlan}
                </CustomButton>
              </div>
            </div>
          </div>
          <div>
            {isArray(actionPlans.results) &&
              !isArrayEmpty(actionPlans.results) && (
                <GridTable
                  className={classes.table}
                  translations={translations}
                  customHeaderClass={classNames({
                    [classes.readMoreActive]: !isReadMore,
                  })}
                  headerActions={getHeaderActions(translations.columns)}
                  headers={getTableHeaders(translations.columns)}
                  initialSort={ordering}
                  hasLoadMore={showLoadMore}
                  rows={prepareTableData(
                    actionPlans.results,
                    statuses,
                    this.renderUser,
                    this.renderStatus,
                    this.renderActions,
                    isAdmin,
                    auth,
                    organizationSettings.global_see_himself
                  )}
                  onLoadMore={this.onLoadMore}
                  onSort={this.onSort}
                />
              )}
            {!isLoading &&
              isArray(actionPlans.results) &&
              isArrayEmpty(actionPlans.results) && (
                <NotificationCard
                  title={
                    search || filterIsActive ? '' : translations.noResultsTitle
                  }
                  content={
                    search || filterIsActive
                      ? translations.noSearchResultsMessage
                      : translations.noResultsMessage
                  }
                />
              )}
          </div>
          <ManageActionPlan
            translations={translations.manageActionPlan}
            isOpen={isManagePlanOpened}
            isInitialValid={!isObjectEmpty(actionPlan)}
            currentUser={auth}
            actionPlan={actionPlan}
            allUsers={createdForUsers}
            statuses={statuses}
            categories={categories}
            courses={courses.results}
            getCourses={getCourses}
            getCourseEnrolledUsers={getCourseEnrolledUsers}
            onCoursesSearch={this.handleCoursesSearch}
            onCreateTag={this.onCreateTag}
            onDelete={this.handleOpenDelete}
            onClose={this.handleCloseManagePlan}
            onConfirm={this.handleConfirmPlan}
            hasCreatedForField
          />
          {actionPlan && (
            <ManageTasks
              translations={translations.manageTasksDialog}
              isOpened={isManageTasksOpened}
              record={actionPlan}
              availableUsers={allUsers}
              checkCanManageTask={this.checkCanManageFormTask(isAdmin)}
              onClose={this.handleCloseManageTasks}
              onSave={this.onSaveTasks}
            />
          )}
          <AlertDialog
            isOpened={isDeleteOpened}
            translations={translations.deleteActionPlanDialog}
            onClose={this.handleCloseDeleteDialog}
            onConfirm={this.onDelete}
            isWarning
          />
          <AlertDialog
            translations={translations.dismissActionPlanProposalDialog}
            isOpened={isDismissRequestOpened}
            onClose={this.handleCloseDismissDialog}
            onConfirm={this.onDelete}
            isWarning
          />
        </div>
      )
    );
  }
}

ActionPlansPage.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  auth: PropTypes.shape({}).isRequired,
  actionPlans: PropTypes.object.isRequired,
  courses: PropTypes.shape({}).isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  pageQuickFilters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  allUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  organizationSettings: PropTypes.object.isRequired,
  addMultipleTags: PropTypes.func.isRequired,
  getCourses: PropTypes.func.isRequired,
  getCourseEnrolledUsers: PropTypes.func.isRequired,
  getAllActionPlans: PropTypes.func.isRequired,
  clearAllActionPlans: PropTypes.func.isRequired,
  getTagsCategories: PropTypes.func.isRequired,
  getPageQuickFilters: PropTypes.func.isRequired,
  clearPageQuickFilters: PropTypes.func.isRequired,
  clearTagsCategories: PropTypes.func.isRequired,
  clearCourses: PropTypes.func.isRequired,
  clearCourseEnrolledUsers: PropTypes.func.isRequired,
  getAllUsers: PropTypes.func.isRequired,
  setDialogVisibility: PropTypes.func.isRequired,
  clearAllUsers: PropTypes.func.isRequired,
  getOrganizationSettings: PropTypes.func.isRequired,
};

export default withStyles(styles)(ActionPlansPage);
