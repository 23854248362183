import { createAction, handleActions } from 'redux-actions';
import http from '../../utility/http';
import { prepareTeamNotifications } from '../../utility/teamNotifications';
import {
  API_TEAM_NOTIFICATIONS,
  api_clear_user_notifications,
} from '../../constants/apiRoutes';

// ------------------------------------
// Constants
// ------------------------------------

const SET_TEAM_NOTIFICATIONS = 'SET_TEAM_NOTIFICATIONS';
const DELETE_USER_NOTIFICATIONS = 'DELETE_USER_NOTIFICATIONS';

const initialState = {};

// ------------------------------------
// Actions
// ------------------------------------

const setTeamNotifications = createAction(SET_TEAM_NOTIFICATIONS);
const deleteUserNotifications = createAction(DELETE_USER_NOTIFICATIONS);

// ------------------------------------
// Reducers
// ------------------------------------

const reducers = {
  [SET_TEAM_NOTIFICATIONS]: (state, { payload }) => {
    return { ...payload };
  },
  [DELETE_USER_NOTIFICATIONS]: (state, { payload }) => {
    const { [payload.userId]: deletedUserNotification, ...rest } = state;
    return { ...rest };
  },
};

// ------------------------------------
// API calls
// ------------------------------------

export const getTeamNotifications = (dispatch, isAdmin) => {
  return http
    .get(API_TEAM_NOTIFICATIONS)
    .then(({ data }) =>
      dispatch(setTeamNotifications(prepareTeamNotifications(data, isAdmin)))
    );
};

export const onDeleteUserNotifications = async (dispatch, userId) => {
  await http.post(api_clear_user_notifications(userId));
  dispatch(deleteUserNotifications({ userId }));
};

export default handleActions(reducers, initialState);

// ------------------------------------
// "plain" action functions
// ------------------------------------

export const clearTeamNotifications = () => setTeamNotifications(initialState);
