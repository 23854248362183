import { createAction, handleActions } from 'redux-actions';
import {
  isArrayEmpty,
  reorderAndUpdateItemsInArrayByIds,
  replaceObjectInList,
} from '../../utility/helpers';
import http from '../../utility/http';
import { API_FRAMEWORKS } from '../../constants/apiRoutes';
// ------------------------------------
// Constants
// ------------------------------------

const SET_LEVELS_AND_TRACKS = 'SET_LEVELS_AND_TRACKS';
const SET_LEVELING_FRAMEWORKS = 'SET_LEVELING_FRAMEWORKS';
const SET_FRAMEWORK_REORDERING = 'SET_FRAMEWORK_REORDERING';
const SET_TRACK_REORDERING = 'SET_TRACK_REORDERING';
const SET_LEVEL_REORDERING = 'SET_LEVEL_REORDERING';

const initialState = {
  tracks: [],
  frameworks: [],
};

// ------------------------------------
// Actions
// ------------------------------------
const setLevelsAndTracks = createAction(SET_LEVELS_AND_TRACKS);
export const setLevelingFrameworks = createAction(SET_LEVELING_FRAMEWORKS);
export const setFrameworkReordering = createAction(SET_FRAMEWORK_REORDERING);
export const setTrackReordering = createAction(SET_TRACK_REORDERING);
export const setLevelReordering = createAction(SET_LEVEL_REORDERING);

// ------------------------------------
// Reducers
// ------------------------------------

const reducers = {
  [SET_LEVELS_AND_TRACKS]: (state, { payload }) => {
    const tracks = payload?.reduce((acc, curr) => {
      const tracksTemp = curr.tracks;
      if (acc && !isArrayEmpty(acc)) {
        return acc.concat(tracksTemp);
      }
      return tracksTemp;
    }, []);

    return {
      ...state,
      tracks,
      frameworks: payload,
    };
  },
  [SET_LEVELING_FRAMEWORKS]: (state, { payload }) => {
    return {
      ...state,
      frameworks: payload,
    };
  },
  [SET_FRAMEWORK_REORDERING]: (state, { payload }) => {
    const { frameworks } = state;
    return {
      ...state,
      frameworks: reorderAndUpdateItemsInArrayByIds(
        frameworks,
        payload.frameworkIds
      ),
    };
  },
  [SET_TRACK_REORDERING]: (state, { payload }) => {
    const { frameworks } = state;
    const { frameworkIndex, trackIds } = payload;

    return {
      ...state,
      frameworks: replaceObjectInList(frameworks, frameworkIndex, {
        ...frameworks[frameworkIndex],
        tracks: reorderAndUpdateItemsInArrayByIds(
          frameworks[frameworkIndex].tracks,
          trackIds
        ),
      }),
    };
  },
  [SET_LEVEL_REORDERING]: (state, { payload }) => {
    const { frameworks } = state;
    const { frameworkIndex, trackIndex, levelIds } = payload;

    return {
      ...state,
      frameworks: replaceObjectInList(frameworks, frameworkIndex, {
        ...frameworks[frameworkIndex],
        tracks: replaceObjectInList(
          frameworks[frameworkIndex].tracks,
          trackIndex,
          {
            ...frameworks[frameworkIndex].tracks[trackIndex],
            levels: reorderAndUpdateItemsInArrayByIds(
              frameworks[frameworkIndex].tracks[trackIndex].levels,
              levelIds
            ),
          }
        ),
      }),
    };
  },
};

// ------------------------------------
// API calls
// ------------------------------------

export const getLevelsAndTracks = dispatch => {
  return http.get(API_FRAMEWORKS).then(({ data }) => {
    dispatch(setLevelsAndTracks(data));
  });
};

export const getLevelingFrameworks = dispatch => {
  return http.get(API_FRAMEWORKS).then(({ data }) => {
    dispatch(setLevelingFrameworks(data));
  });
};

export default handleActions(reducers, initialState);

// ------------------------------------
// "plain" action functions
// ------------------------------------

export const clearLevelsAndTracks = () =>
  setLevelsAndTracks(initialState.tracks);

export const clearLevelingFrameworks = () =>
  setLevelingFrameworks(initialState.frameworks);
