import { NOTE_TYPES_MAP } from '../constants/people';
import { USER_STATUSES_MAP } from '../constants/statuses';

export const getUserStatuses = labels =>
  USER_STATUSES_MAP.map(userStatus => ({
    ...userStatus,
    name: labels[userStatus.key],
  }));

export const getPeopleNoteTypes = labels =>
  NOTE_TYPES_MAP.map(type => ({
    ...type,
    name: labels[type.key].name,
    description: labels[type.key].description,
  }));
