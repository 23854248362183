import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CustomButton from '../customButton';
import AlertDialog from '../alertDialog';
import NotificationCard from '../notificationCard';
import UsersList from '../usersList';
import ConditionalTooltip from '../conditionalTooltip';
import {
  showSuccessMessage,
  getShareTypeUserLabel,
} from '../../../utility/uiUtils';

const styles = ({ palette: { primary }, spacing }) => ({
  main: {
    borderBottom: `1px solid ${primary.bluish6}`,
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: spacing(8),
    width: '100%',
  },
  title: {
    marginBottom: spacing(2),
  },
  subtitleBlock: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  descriptionContainer: {
    width: 500,
    marginBottom: spacing(6),
  },
  manageButton: {
    flexShrink: 0,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
  subtitle: {
    marginBottom: spacing(3),
  },
  disabledUserAction: {
    cursor: 'not-allowed',
  },
});

class EmployeeShare extends PureComponent {
  state = {
    selectedUserId: null,
  };

  render() {
    const {
      translations,
      onClickShareButton,
      classes,
      sharedWith,
      onDeleteItem,
      isOpened,
      canShareUser,
      setDialogVisibility,
    } = this.props;

    const { unshareUserDialog } = translations;

    const { selectedUserId } = this.state;

    return (
      <div className={classes.main}>
        <div>
          <Typography variant="h5" className={classes.title}>
            {translations.title}
          </Typography>
          <div className={classes.subtitleBlock}>
            <div className={classes.descriptionContainer}>
              <Typography variant="body2">
                {translations.description}
              </Typography>
            </div>
            <ConditionalTooltip
              className={classes.disabledUserAction}
              message={translations.disabledShareActionTooltip}
              addTooltip={!canShareUser}
            >
              <CustomButton
                className={classes.manageButton}
                type="shareDarkRoundedOutlined"
                disabled={!canShareUser}
                onClick={onClickShareButton}
              >
                {translations.shareButton}
              </CustomButton>
            </ConditionalTooltip>
          </div>
        </div>
        <div className={classes.content}>
          <Typography variant="subtitle2" className={classes.subtitle}>
            {translations.peopleWithAccess}
          </Typography>
          {sharedWith && sharedWith.length > 0 ? (
            <div>
              <UsersList
                users={sharedWith}
                getUserLabel={user =>
                  getShareTypeUserLabel(translations.sharedItem, user)
                }
                onRemoveUser={user => {
                  this.setState({ selectedUserId: user.id }, () => {
                    setDialogVisibility({
                      dialogName: 'employeeUnshareDialog',
                      opened: true,
                    });
                  });
                }}
                hasUserLabel
                isVerticalList
                isUserRemovable
                isSmallUserLabel
              />
            </div>
          ) : (
            <NotificationCard content={translations.notSharedYet} />
          )}
        </div>
        <AlertDialog
          isOpened={isOpened}
          translations={unshareUserDialog}
          onClose={() => {
            this.setState({ selectedUserId: null }, () => {
              setDialogVisibility({
                dialogName: 'employeeUnshareDialog',
                opened: false,
              });
            });
          }}
          onConfirm={async () => {
            const payload = sharedWith
              .filter(u => u.id !== selectedUserId)
              .map(record => {
                return {
                  user: record.id,
                  read_only: record.read_only,
                };
              });
            await onDeleteItem(payload);

            this.setState({ selectedUserId: null }, () => {
              showSuccessMessage(unshareUserDialog.unshareSuccess);
              setDialogVisibility({
                dialogName: 'employeeUnshareDialog',
                opened: false,
              });
            });
          }}
        />
      </div>
    );
  }
}

EmployeeShare.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  onClickShareButton: PropTypes.func.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
  sharedWith: PropTypes.arrayOf(PropTypes.object).isRequired,
  isOpened: PropTypes.bool.isRequired,
  canShareUser: PropTypes.bool.isRequired,
  setDialogVisibility: PropTypes.func.isRequired,
};

export default withStyles(styles)(EmployeeShare);
