import LevelingFrameworks from '../../shared/levelingFrameworks';
import { prepareLevelingFrameworksData } from '../../../utility/leveling';
import { isEmpty, validateUniqueName } from '../../../utility/validation';
import { FIELD_TYPES } from '../../../constants/fieldTypes';
import { LEVELING_TEMPLATES } from '../../../constants/levelingTemplates';

export const TEMPLATE_OPTIONS = [
  {
    id: 1,
    value: LEVELING_TEMPLATES.CARTA,
  },
  {
    id: 2,
    value: LEVELING_TEMPLATES.FACEBOOK,
  },
  {
    id: 3,
    value: LEVELING_TEMPLATES.AMAZON,
  },
];

export const getLevelingTemplateFormColumns = (
  frameworks,
  templateSubtitle,
  renderDescription,
  renderAttributes,
  renderRange,
  renderPeople
) => [
  {
    id: 1,
    fields: [
      {
        name: 'template',
        type: FIELD_TYPES.SELECTABLE_LIST,
        options: TEMPLATE_OPTIONS,
        label: templateSubtitle,
        required: true,
        validators: [
          { type: 'required', validator: isEmpty },
          {
            type: 'isAlreadyChosen',
            validator: ({ value }) =>
              validateUniqueName(value?.frameworks[0]?.name, frameworks),
          },
        ],
      },
    ],
    className: 'selection',
  },
  {
    id: 2,
    fields: [
      {
        name: 'preview',
        type: FIELD_TYPES.PREVIEW,
        previewKey: 'template',
        isReadOnly: true,
        component: ({
          translations,
          data,
          isExpandedOnFirstOpen,
          isExpandedOnFirstOpenFramework,
          toggleExpansionFramework,
          toggleExpansion,
        }) => (
          <LevelingFrameworks
            translations={translations}
            isReadOnly
            isAdmin
            isExpandedOnFirstOpen={isExpandedOnFirstOpen}
            toggleExpansion={toggleExpansion}
            isExpandedOnFirstOpenFramework={isExpandedOnFirstOpenFramework}
            toggleExpansionFramework={toggleExpansionFramework}
            levelingFrameworks={prepareLevelingFrameworksData(
              data,
              renderDescription,
              renderAttributes,
              renderRange,
              renderPeople
            )}
          />
        ),
      },
    ],
  },
];

export const prepareAttributes = attributesWithQuestions => {
  return attributesWithQuestions.map(attribute => {
    return {
      ...attribute,
      [FIELD_TYPES.RANGE_FROM]: 0,
      [FIELD_TYPES.RANGE_TO]: 10,
    };
  });
};

export const prepareDataForSave = levelAttributes => {
  const { RANGE_FROM, RANGE_TO } = FIELD_TYPES;
  const attributesForSave = levelAttributes.reduce((acc, curr) => {
    return [
      ...acc,
      {
        attribute: curr.id,
        [RANGE_FROM]: curr[RANGE_FROM],
        [RANGE_TO]: curr[RANGE_TO],
      },
    ];
  }, []);

  return attributesForSave;
};

export const prepareTemplateForSave = framework => {
  return {
    name: framework.name,
    tracks: framework?.tracks?.map(track => {
      return {
        name: track.name,
        levels: track.levels?.map(level => {
          return {
            name: level.name,
            description: level.description,
          };
        }),
      };
    }),
  };
};
