import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import { getTeamNotificationItem } from '../../../../utility/teamNotifications';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  labelWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    color: primary.blue1,
  },
  icon: {
    width: 16,
    height: 16,
    marginBottom: spacing(0.5),
    marginRight: spacing(2),
    '& path': {
      fill: primary.bluish1,
    },
  },
}));

const NotificationItem = ({ translations, data }) => {
  const classes = useStyles();
  const label = getTeamNotificationItem(translations, data);
  const { model, type, createdAt, icon: Icon } = label;

  return (
    <div className={classes.root}>
      <div className={classes.labelWrapper}>
        <Icon className={classes.icon} />
        <Typography
          className={classes.label}
          variant="subtitle2"
        >{`${model} - ${type}`}</Typography>
      </div>
      <Typography variant="body2">{createdAt}</Typography>
    </div>
  );
};

NotificationItem.propTypes = {
  translations: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default NotificationItem;
