import { connect } from 'react-redux';
import { useParams } from 'react-router';
import PeoplePageContainer from '../../components/shared/peoplePageContainer';
import PeopleCareerPage from '../../components/pages/peopleCareerPage';
import { jobTitlesSelector } from '../../store/selectors/jobTitlesSelector';
import { levelsAndTracksSelector } from '../../store/selectors/levelsAndTracksSelector';
import { personCareerSelector } from '../../store/selectors/personCareerSelector';
import { getJobTitles, clearJobTitles } from '../../store/modules/jobTitles';
import {
  getLevelsAndTracks,
  clearLevelsAndTracks,
} from '../../store/modules/levelsAndTracks';
import {
  getPersonJobTitle,
  clearPersonCareer,
  getMeasuredAttributes,
} from '../../store/modules/personCareer';
import { updateEmploymentInfo } from '../../store/modules/person';
import { useTranslations } from '../../utility/useTranslations';
import { APP_PAGES } from '../../constants/pages';

const mapStateToProps = state => ({
  ...jobTitlesSelector(state),
  ...personCareerSelector(state),
  tracks: levelsAndTracksSelector(state).tracks,
  frameworks: levelsAndTracksSelector(state).frameworks,
});

const mapDispatchToProps = dispatch => ({
  getJobTitles: params => getJobTitles(dispatch, params),
  getLevelsAndTracks: () => getLevelsAndTracks(dispatch),
  getPersonJobTitle: jobTitleId => getPersonJobTitle(dispatch, jobTitleId),
  getMeasuredAttributes: userId => getMeasuredAttributes(dispatch, userId),
  updateEmploymentInfo: (id, data) => updateEmploymentInfo(dispatch, id, data),
  clearJobTitles: () => dispatch(clearJobTitles()),
  clearLevelsAndTracks: () => dispatch(clearLevelsAndTracks()),
  clearPersonCareer: () => dispatch(clearPersonCareer()),
});

const PeopleCareerPageView = props => {
  const { id } = useParams();
  const userId = (!Number.isNaN(Number(id)) && +id) || -1;
  const translations = useTranslations(APP_PAGES.PEOPLE_CAREER);

  return (
    <PeoplePageContainer
      {...props}
      userId={userId}
      translations={translations}
      isMediumWidthContent
    >
      <PeopleCareerPage />
    </PeoplePageContainer>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PeopleCareerPageView);
