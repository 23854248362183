import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Fade, makeStyles } from '@material-ui/core';
import StatusIndicator from '../statusIndicator';
import ActionButton from '../actionButton';
import { getUserStatatusActions } from './config';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
  },
  action: {
    flexShrink: 0,
    marginLeft: spacing(2),
  },
}));

const UserStatus = ({
  translations,
  status,
  hasActions,
  onInvite,
  onReinvite,
  onRevokeAccess,
  onDeactivate,
  onReactivate,
  onDelete,
}) => {
  const classes = useStyles();
  const actions = useMemo(
    () =>
      hasActions
        ? getUserStatatusActions(
            translations,
            status?.id,
            onInvite,
            onReinvite,
            onRevokeAccess,
            onDeactivate,
            onReactivate,
            onDelete
          )
        : [],
    [
      translations,
      hasActions,
      status,
      onInvite,
      onReinvite,
      onRevokeAccess,
      onDeactivate,
      onReactivate,
      onDelete,
    ]
  );

  return (
    <div className={classes.root}>
      <StatusIndicator status={status} />
      <div className={classes.root}>
        {actions.map(action => {
          const { isActive, type, ...rest } = action;

          return (
            <Fade
              key={`status_action_${type}`}
              className={classes.action}
              in={isActive}
              timeout={{
                enter: 300,
                exit: 0,
              }}
              unmountOnExit
            >
              <div>
                <ActionButton {...rest} type={type} />
              </div>
            </Fade>
          );
        })}
      </div>
    </div>
  );
};

UserStatus.defaultProps = {
  hasActions: false,
  translations: null,
  onInvite: () => {},
  onReinvite: () => {},
  onRevokeAccess: () => {},
  onDeactivate: () => {},
  onReactivate: () => {},
  onDelete: () => {},
};

UserStatus.propTypes = {
  translations: PropTypes.object,
  status: PropTypes.object.isRequired,
  hasActions: PropTypes.bool,
  onInvite: PropTypes.func,
  onReinvite: PropTypes.func,
  onRevokeAccess: PropTypes.func,
  onDeactivate: PropTypes.func,
  onReactivate: PropTypes.func,
  onDelete: PropTypes.func,
};

export default UserStatus;
