import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { TransitionGroup } from 'react-transition-group';
import { Fade, makeStyles } from '@material-ui/core';
import Note from './note';
import { getTeamNotificationUpdates } from '../../../utility/teamNotifications';

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: 'block',
    width: '100%',
  },
  noteWrapper: {
    marginBottom: spacing(4),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
}));

const NotesList = ({
  translations,
  currentUser,
  canSeeUserProfileCheck,
  goToUserProfilePage,
  notes,
  notifications,
  isPrivateOnly,
  checkCanManageNote,
  checkIsDisabledEdit,
  canAccessUserProfile,
  checkCanManageTask,
  goToUserProfile,
  onTaskStatusChange,
  onEdit,
  onDelete,
  onClearNotifications,
}) => {
  const classes = useStyles();
  const notificationsData = useMemo(() => {
    if (notifications) {
      return getTeamNotificationUpdates(
        translations.notifications.updates,
        notifications.model,
        notifications.updates
      );
    }

    return null;
  }, [translations, notifications]);

  return (
    <TransitionGroup className={classes.root}>
      {notes.map(note => {
        const isCreatedByMe = note?.creator?.id === currentUser?.id;

        return (
          <Fade
            key={`note_${note.id}`}
            className={classes.noteWrapper}
            in
            unmountOnExit
          >
            <div>
              <Note
                translations={translations}
                note={note}
                notifications={notificationsData}
                canSeeUserProfileCheck={canSeeUserProfileCheck}
                goToUserProfilePage={goToUserProfilePage}
                hasNotifications={notifications?.objectId === note.id}
                isNotificationUpdate={!!notifications?.isUpdate}
                isCreatedByMe={isCreatedByMe}
                isPrivateOnly={isPrivateOnly}
                checkCanManageNote={checkCanManageNote}
                checkIsDisabledEdit={checkIsDisabledEdit}
                canAccessUserProfile={canAccessUserProfile}
                goToUserProfile={goToUserProfile}
                checkCanManageTask={checkCanManageTask}
                onTaskStatusChange={onTaskStatusChange}
                onEdit={onEdit}
                onDelete={onDelete}
                onClearNotifications={onClearNotifications}
              />
            </div>
          </Fade>
        );
      })}
    </TransitionGroup>
  );
};

NotesList.defaultProps = {
  isPrivateOnly: false,
  notifications: null,
  currentUser: null,
  checkCanManageTask: () => {},
  checkIsDisabledEdit: () => false,
  checkCanManageNote: () => true,
  goToUserProfile: () => {},
  canAccessUserProfile: () => false,
  onTaskStatusChange: () => {},
  canSeeUserProfileCheck: () => {},
  goToUserProfilePage: () => {},
  onClearNotifications: () => {},
};

NotesList.propTypes = {
  translations: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  notes: PropTypes.arrayOf(PropTypes.object).isRequired,
  notifications: PropTypes.object,
  isPrivateOnly: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  checkCanManageNote: PropTypes.func,
  canAccessUserProfile: PropTypes.func,
  checkCanManageTask: PropTypes.func,
  checkIsDisabledEdit: PropTypes.func,
  goToUserProfile: PropTypes.func,
  onTaskStatusChange: PropTypes.func,
  canSeeUserProfileCheck: PropTypes.func,
  goToUserProfilePage: PropTypes.func,
  onClearNotifications: PropTypes.func,
};

export default NotesList;
