export const getActionButtons = (
  labels,
  shouldRenderAddButton,
  shouldRenderAssignButton,
  onAssign,
  onAdd,
  isUserSuspended
) => {
  return [
    ...(shouldRenderAssignButton
      ? [
          {
            type: 'addRoundedNew',
            label: labels.assignLevel,
            isDisabled: isUserSuspended,
            disabledTooltipText: labels.disabledAssignActionTooltip,
            onButtonClick: onAssign,
            shouldShowTooltip: true,
          },
        ]
      : []),
    ...(shouldRenderAddButton
      ? [
          {
            type: 'addRoundedNew',
            label: labels.addLevel,
            onButtonClick: onAdd,
          },
        ]
      : []),
  ];
};
