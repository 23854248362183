import ColorBox from 'components/shared/colorBox';
import RichTextPreview from 'components/shared/richTextPreview';
import { ReactComponent as StatusIcon } from '../../../assets/icons/status-icon.svg';
import { ReactComponent as ImpressionIcon } from '../../../assets/icons/impression.svg';
import { ReactComponent as ModeratorIcon } from '../../../assets/icons/people-grey.svg';
import { ReactComponent as TagsIcon } from '../../../assets/icons/tags-icon.svg';
import {
  canManagePerson,
  canSeeEmployeeProfile,
  isArrayEmpty,
  isUserDeactivated,
} from '../../../utility/helpers';
import {
  getPersonFullName,
  getUserProfileLink,
  getFilterableUsers,
} from '../../../utility/uiUtils';
import { formatDate, isDateInPast } from '../../../utility/dateUtils';
import { filterTags } from '../../../utility/tagUtils';
import { ONE_ON_ONE_COMPLETION_STATUSES } from '../../../constants/statuses';
import {
  IMPRESSION_VALUES,
  RECURRING_TYPES_MAP,
} from '../../../constants/oneOnOne';
import { PARAMS } from '../../../constants/pages';

export const getPageFilters = (
  translations,
  isAdmin,
  tagCategories,
  quickFilters = [],
  allManagers = []
) => {
  const { COMPLETION_STATUS, IMPRESSION, MODERATOR, TAGS } = PARAMS;
  const defaultOption = { id: null, name: translations.all };
  const statuses = ONE_ON_ONE_COMPLETION_STATUSES.map(status => ({
    ...status,
    name: translations.statuses[status.id],
  }));
  const impressions = IMPRESSION_VALUES.map(impression => ({
    ...impression,
    id: impression.value,
    name: impression.value.toString(),
  }));

  return [
    ...(isAdmin
      ? [
          {
            id: MODERATOR,
            name: translations.filters.labels[MODERATOR],
            icon: ModeratorIcon,
            items: getFilterableUsers(allManagers),
          },
        ]
      : []),
    {
      id: COMPLETION_STATUS,
      name: translations.filters.labels.status,
      icon: StatusIcon,
      isSingleSelect: true,
      items: [defaultOption, ...statuses],
    },
    {
      id: IMPRESSION,
      name: translations.filters.labels.impression,
      icon: ImpressionIcon,
      items: impressions,
    },
    {
      id: TAGS,
      name: translations.filters.labels.tags,
      icon: TagsIcon,
      categoryItemsKey: TAGS,
      hasCategoryColorBox: true,
      hasSubmenuHash: true,
      isCategorized: true,
      items: tagCategories,
      categorySearch: filterTags,
    },
    ...(!isArrayEmpty(quickFilters)
      ? quickFilters?.map(filter => {
          return {
            id: filter.name,
            name: filter.name,
            icon: () => {
              return <ColorBox bgColor={filter.color} isSmall />;
            },
            hasParent: true,
            parent: {
              id: TAGS,
            },
            items: filter?.tags,
          };
        }) || {}
      : []),
  ];
};

export const getHeaderActions = labels => [
  {
    id: 'actions-column',
    title: labels.actions,
    rowKey: 'actions',
    rowCellClass: 'actions-cell',
    align: 'right',
    isHeaderAction: true,
    minWidth: 60,
    maxWidth: 145,
  },
];

export const getTableHeaders = (labels, isAdmin) => [
  {
    id: 1,
    title: labels.with,
    rowKey: 'user',
    isHighlighted: true,
    isSortingDisabled: true,
    minWidth: 160,
    maxWidth: '1fr',
  },
  {
    id: 2,
    title: labels.sharedNote,
    rowKey: 'sharedNote',
    isSortingDisabled: true,
    hasConditionalTooltip: true,
    minWidth: 145,
    maxWidth: '1.5fr',
  },
  ...(isAdmin
    ? [
        {
          id: 3,
          title: labels.moderator,
          rowKey: 'moderator',
          minWidth: 160,
          maxWidth: '1fr',
          hasConditionalTooltip: true,
          isSortingDisabled: true,
          isHighlighted: true,
        },
      ]
    : []),
  {
    id: 4,
    title: labels.date,
    rowKey: 'date',
    rowCellClass: 'date-cell',
    isSortingDisabled: true,
    maxWidth: '1fr',
  },
  {
    id: 5,
    title: labels.status,
    rowKey: 'status',
    isSortingDisabled: true,
    maxWidth: '1fr',
  },
];

export const prepareTableData = (
  records,
  renderUser,
  renderStatus,
  renderActions,
  currentUser,
  canSeeHimself
) => {
  return records.map(record => {
    const detailsUrl = `/1-1/${record.id}/details`;
    const canAccessUserProfile = canSeeEmployeeProfile(
      currentUser,
      record.user.id,
      canSeeHimself
    );
    const isRequest = !record.meeting_time;
    const isCreatedByCurrentUser = currentUser.id === record.moderator?.id;
    const isPastMeeting = isDateInPast(record.meeting_time);
    const hasImpression = !!record.meeting_impression;
    const canManageUser = canManagePerson(currentUser, record.user.id);
    const isActiveUser = !isUserDeactivated(record.user);
    const hasTasks = !isArrayEmpty(record.tasks);
    const hasPlans = !isArrayEmpty(record.plans);

    return {
      id: record.id,
      actions: renderActions(
        record,
        isCreatedByCurrentUser,
        hasImpression,
        isRequest,
        isPastMeeting,
        canManageUser,
        isActiveUser
      ),
      user: renderUser(record.user, canAccessUserProfile),
      moderator: record.moderator ? getPersonFullName(record.moderator) : '-',
      sharedNote: record.shared_note ? (
        <RichTextPreview text={record.shared_note} />
      ) : (
        ''
      ),
      status: renderStatus(
        record.meeting_time,
        record.meeting_impression,
        record.previous_impression,
        record.recurring !== RECURRING_TYPES_MAP.DOES_NOT_REPEAT.id,
        record.requested,
        hasTasks,
        hasPlans
      ),
      date: formatDate(record.meeting_time),
      cellLinks: {
        user: {
          to: getUserProfileLink(record.user.id, canAccessUserProfile),
        },
        ...(record.moderator
          ? {
              moderator: {
                to: getUserProfileLink(
                  record.moderator.id,
                  canSeeEmployeeProfile(
                    currentUser,
                    record.moderator.id,
                    canSeeHimself
                  )
                ),
              },
            }
          : {}),
        ...(!record.moderator && !isRequest
          ? {
              moderator: {
                to: detailsUrl,
              },
            }
          : {}),
        ...(isRequest
          ? {}
          : {
              status: {
                to: detailsUrl,
              },
              date: {
                to: detailsUrl,
              },
              sharedNote: {
                to: detailsUrl,
              },
            }),
      },
    };
  });
};
