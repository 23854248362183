import { useState, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Collapse, alpha, makeStyles } from '@material-ui/core';
import DotsMenu from '../../../../dotsMenu';
import CustomButton from '../../../../customButton';
import { ACTION_BUTTON_TYPES } from '../../../../actionButton/config';
import { isArrayEmpty, reorder } from '../../../../../../utility/helpers';
import ReorderItems from '../../../../reorderItems';
import ActionButton from '../../../../actionButton';
import TextBoxWithTooltip from '../../../../textBoxWithTooltip';
import { getDotsMenuItems } from '../../../../../../utility/leveling';
import Level from './level';

const useStyles = makeStyles(
  ({ palette: { primary }, spacing, breakpoints }) => ({
    header: {
      cursor: 'pointer',
      borderRadius: 8,
      backgroundColor: primary.bluish9,
      display: 'grid',
      gridTemplateColumns:
        'minmax(30px, 30px) minmax(182px, 811px) minmax(320px, 380px) minmax(160px, 170px) minmax(60px, 60px)',
      alignItems: 'center',
      justifyContent: 'space-between',
      height: 32,
      marginBottom: spacing(3),
      [breakpoints.up('xLg')]: {
        gridTemplateColumns:
          'minmax(30px, 30px) minmax(182px, 1506px) minmax(320px, 380px) minmax(160px, 170px) minmax(60px, 60px)',
      },
      '& > div:first-of-type': {
        opacity: 0,
        transition: 'opacity .3s ease',
      },
      '&:active': {
        '& > div:first-of-type': {
          opacity: 1,
          transition: 'opacity .3s ease',
        },
        boxShadow: `0px 2px 8px 0px ${alpha(primary.black, 0.17)}`,
        transition: 'box-shadow .3s ease',
      },
      '&:hover': {
        '& > div:first-of-type': {
          opacity: ({ isVisible }) => {
            return isVisible ? 1 : 0;
          },
          transition: 'opacity .3s ease',
          cursor: ({ isVisible }) => {
            return isVisible ? 'pointer' : 'grabbing';
          },
        },
        cursor: ({ isVisible }) => {
          return isVisible ? 'pointer' : 'grabbing';
        },
        boxShadow: `0px 2px 8px 0px ${alpha(primary.black, 0.17)}`,
        transition: 'box-shadow .3s ease',
      },
    },
    templateHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      width: 1081,
    },
    headerDisabled: {
      gridTemplateColumns:
        'minmax(132px, 811px) minmax(320px, 380px) minmax(160px, 190px) minmax(30px, 40px)',
      [breakpoints.up('xLg')]: {
        gridTemplateColumns:
          'minmax(182px, 1561px) minmax(320px, 380px) minmax(160px, 170px) minmax(30px, 40px)',
      },
    },
    visibleCollapseButton: {
      '& > div:first-of-type': {
        opacity: 1,
        transition: 'opacity .3s ease',
      },
    },
    expanded: {
      marginBottom: 10,
    },
    disableCollapse: {
      cursor: 'default',
      pointerEvents: 'none',
    },
    actionsWrapper: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    actionButton: {
      marginRight: 7,
      width: 24,
      height: 24,
    },
    templateActionButton: {
      marginRight: spacing(2),
    },
    expandIcon: {
      transform: 'rotate(0deg)',
      transition: 'transform .3s cubic-bezier(0.77, 0.2, 0.05, 1)',
    },
    expandIconOpened: {
      transform: 'rotate(180deg)',
    },
    button: {
      borderBottom: `1px solid ${primary.bluish7}`,
      borderTop: `1px solid ${primary.bluish7}`,
      borderRadius: 'unset',
      marginBottom: 14,
      marginTop: 2,
      width: '100%',
      height: 40,
      justifyContent: 'center',
      '&:hover': {
        backgroundColor: primary.white,
      },
    },
    dragButton: {
      position: 'relative',
      width: 22,
      height: 22,
      marginLeft: spacing(2),
      flexShrink: 0,
      marginRight: spacing(1.5),
      zIndex: 11,
      '& button': {
        width: 22,
        height: 22,
      },
    },
    dragIcon: {
      width: 8,
      height: 14,
    },
    dragTooltipLabel: {
      fontFamily: 'ProximaNova-Regular',
    },
    cell: {
      marginLeft: 0,
      fontSize: 14,
      lineHeight: '18px',
    },
    disabledActions: {
      cursor: 'default',
      justifyContent: 'end',
    },
    titleText: {
      fontSize: 14,
      lineHeight: '18px',
      marginLeft: spacing(8),
    },
    titleWithDragButton: {
      marginLeft: 2,
    },
    levelItem: {
      '&:last-of-type': {
        '& > div': {
          borderRadius: 8,
          borderBottom: 'none',
          position: 'relative',
        },
      },
    },
  })
);

const LevelingTrack = ({
  translations,
  levelingFramework,
  levelingTrack,
  dragRef,
  dragProps,
  isReadOnly,
  onEditTrackTitle,
  onDuplicateTrack,
  handleDeleteTrackDialog,
  handleCreateLevel,
  handleMoveTrack,
  onReorderLevel,
  isExpandedOnFirstOpen,
  toggleExpansion,
  isAdmin,
  isVisible,
}) => {
  const classes = useStyles({ isVisible });
  const { name, levels } = levelingTrack;
  const hasLevels = !isArrayEmpty(levels);

  const [isExpanded, setIsExpanded] = useState(isExpandedOnFirstOpen || false);
  const isExpandedDropdown = isExpanded || isExpandedOnFirstOpen;
  const toggleExpand = () => {
    if (isExpandedOnFirstOpen) {
      toggleExpansion(false);
      setIsExpanded(false);
    }
    if (!isExpandedOnFirstOpen) {
      setIsExpanded(prevExpanded => !prevExpanded);
    }
  };

  const handleReorder = useCallback(
    result => {
      if (
        !result.destination ||
        result.source.index === result.destination.index
      )
        return;

      const updatedOrder = reorder(
        levels,
        result.source.index,
        result.destination.index
      );

      return onReorderLevel(levelingFramework, levelingTrack.id, updatedOrder);
    },
    [levels, levelingTrack, levelingFramework, onReorderLevel]
  );

  return (
    <div ref={dragRef} {...(dragProps ? { ...dragProps.draggableProps } : {})}>
      <div
        className={classNames(classes.header, {
          [classes.expanded]: isExpandedDropdown,
          [classes.templateHeader]: isReadOnly,
          [classes.headerDisabled]: !isAdmin,
          [classes.visibleCollapseButton]: isReadOnly || !isAdmin,
          [classes.disableCollapse]: !isAdmin && !hasLevels,
        })}
        onClick={toggleExpand}
      >
        {!isReadOnly && isAdmin && (
          <ActionButton
            className={classes.dragButton}
            iconClass={classes.dragIcon}
            tooltipLabelClass={classes.dragTooltipLabel}
            type={ACTION_BUTTON_TYPES.DRAG}
            tooltipText={translations.trackDragTooltip}
            {...(dragProps ? { ...dragProps.dragHandleProps } : {})}
          />
        )}
        <TextBoxWithTooltip
          className={classNames(classes.titleText, {
            [classes.titleWithDragButton]: !isReadOnly && isAdmin,
          })}
          variant="h5"
          text={name}
        />
        {!isReadOnly && (
          <>
            <TextBoxWithTooltip
              className={classes.cell}
              variant="h5"
              text={translations.attributes}
            />
            <TextBoxWithTooltip
              className={classes.cell}
              variant="h5"
              text={translations.people}
            />
          </>
        )}
        <div
          className={classNames(classes.actionsWrapper, {
            [classes.disabledActions]: isReadOnly || !hasLevels,
            [classes.templateActionButton]: !hasLevels,
          })}
        >
          {isAdmin && !isReadOnly && (
            <DotsMenu
              menuItems={getDotsMenuItems(
                translations,
                levelingTrack,
                levelingFramework,
                handleDeleteTrackDialog,
                onEditTrackTitle,
                onDuplicateTrack,
                handleMoveTrack,
                true
              )}
              isDisabled={isReadOnly}
            />
          )}
          {!hasLevels && !isAdmin ? null : (
            <ActionButton
              className={classNames(
                { [classes.templateActionButton]: isReadOnly },
                classes.actionButton
              )}
              type={ACTION_BUTTON_TYPES.ARROW}
              iconClass={classNames(classes.expandIcon, {
                [classes.expandIconOpened]: isExpandedDropdown,
              })}
            />
          )}
        </div>
      </div>
      <Collapse in={isExpandedDropdown} timeout="auto" unmountOnExit>
        <div>
          {hasLevels && (
            <ReorderItems
              onSave={handleReorder}
              itemClass={classes.levelItem}
              isDragDisabled={!isAdmin}
            >
              {levels.map(level => (
                <Level
                  key={`level_item_${level.id}`}
                  id={level.id}
                  translations={translations}
                  level={level}
                  isReadOnly={isReadOnly}
                  isAdmin={isAdmin}
                />
              ))}
            </ReorderItems>
          )}
          {!isReadOnly && isAdmin && (
            <CustomButton
              className={classes.button}
              type="addWithTextDarkNewSmall"
              onClick={() =>
                handleCreateLevel(levelingTrack, levelingFramework)
              }
            >
              {translations.createLevelButton}
            </CustomButton>
          )}
        </div>
      </Collapse>
    </div>
  );
};

LevelingTrack.defaultProps = {
  onEditTrackTitle: () => {},
  onDuplicateTrack: () => {},
  onReorderLevel: () => {},
  handleCreateLevel: () => {},
  handleMoveTrack: () => {},
  handleDeleteTrackDialog: () => {},
  dragRef: () => {},
  dragProps: null,
  isExpandedOnFirstOpen: false,
  toggleExpansion: () => {},
};

LevelingTrack.propTypes = {
  translations: PropTypes.object.isRequired,
  isVisible: PropTypes.bool.isRequired,
  levelingFramework: PropTypes.object.isRequired,
  levelingTrack: PropTypes.object.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  handleDeleteTrackDialog: PropTypes.func,
  isAdmin: PropTypes.bool.isRequired,
  onEditTrackTitle: PropTypes.func,
  onDuplicateTrack: PropTypes.func,
  onReorderLevel: PropTypes.func,
  handleCreateLevel: PropTypes.func,
  handleMoveTrack: PropTypes.func,
  dragProps: PropTypes.object,
  dragRef: PropTypes.func,
  isExpandedOnFirstOpen: PropTypes.bool,
  toggleExpansion: PropTypes.func,
};

export default memo(LevelingTrack);
