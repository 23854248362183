import { useState, memo } from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import { isFuture, parseJSON } from 'date-fns';
import StatusIndicator from '../statusIndicator';
import ListWithTooltip from '../listWithTooltip';
import SurveyType from '../surveyType';
import UserAvatar from '../userAvatar';
import ActionButton from '../actionButton';
import DateAndTimeDialog from '../dateAndTimeDialog';
import TextBoxWithTooltip from '../textBoxWithTooltip';
import CustomSelectDialog from '../customSelectDialog';
import { getItemById, isUserDeactivated } from '../../../utility/helpers';
import { formatDate } from '../../../utility/dateUtils';
import { getActiveAttributes } from '../../../utility/attribute';
import { SURVEY_GROUP_FIELD } from '../../../constants/survey';
import { SURVEY_STATUS_TYPES } from '../../../constants/statuses';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  main: {
    borderBottom: `1px solid ${primary.bluish7}`,
    paddingBottom: spacing(8),
    marginBottom: spacing(5),
  },
  info: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr)) repeat(2, 130px)',
    gridColumnGap: 32,
    gridRowGap: 60,
  },
  label: {
    color: primary.bluish3,
    lineHeight: '20px',
    marginBottom: spacing(2),
  },
  attributes: {
    marginTop: spacing(8),
  },
  userMoreItems: {
    backgroundColor: 'transparent',
    fontSize: 16,
    lineHeight: '20px',
    padding: spacing(0, 2),
    marginTop: spacing(0.5),
    maxWidth: 'fit-content',
  },
  dateWrapper: {
    position: 'relative',
  },
  editButton: {
    position: 'absolute',
    top: -5,
    right: 0,
  },
  groupWrapper: {
    marginTop: spacing(6),
    width: '100%',
  },
  groupTitleWrapper: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 900,
  },
  groupTitle: {
    width: 'auto',
  },
  editGroupButton: {
    marginLeft: spacing(1),
  },
}));

const SurveyInfo = ({
  translations,
  survey,
  surveyGroups,
  statuses,
  types,
  expiryFields,
  organizationUser,
  shouldShowGroup,
  isUserClickable,
  onGoToPersonProfile,
  onEditExpiryDate,
  onChangeGroup,
}) => {
  const classes = useStyles();

  const [isEditExpiryOpened, setEditExpiryOpened] = useState(false);
  const [isManageGroupOpened, setIsManageGroupOpened] = useState(false);

  const {
    creator,
    createdFor,
    sentAt,
    expireAt,
    scheduledFor,
    attributes,
    status,
    type,
    completed,
    group,
  } = survey;
  const isScheduled = scheduledFor && isFuture(parseJSON(scheduledFor));
  const currentStatus = getItemById(statuses, status);
  const currentType = getItemById(types, type);
  const isAccesibleUser = isUserClickable(creator);
  const initialExpiryDate = new Date(expireAt);
  const isDraft = currentStatus.id === SURVEY_STATUS_TYPES.DRAFT;
  const currentGroup = shouldShowGroup
    ? surveyGroups.find(surveyGroup => surveyGroup.id === group)?.title
    : null;
  const isCreatorActive = !isUserDeactivated(creator);
  const activeAttributes = isDraft
    ? getActiveAttributes(attributes)
    : attributes;
  const activeCreatedFor = isDraft
    ? createdFor.filter(user => !isUserDeactivated(user))
    : createdFor;

  const toggleEditExpiryDate = () =>
    setEditExpiryOpened(prevOpened => !prevOpened);

  const handleEditExpiryDate = newDate => {
    onEditExpiryDate(newDate);
    toggleEditExpiryDate();
  };

  const toggleManageGroup = () => setIsManageGroupOpened(prev => !prev);

  const renderLabel = label => (
    <Typography className={classes.label} variant="body2">
      {label}
    </Typography>
  );

  const renderStatus = label => (
    <div>
      {renderLabel(label)}
      <StatusIndicator statuses={statuses} status={currentStatus} />
    </div>
  );

  const renderDate = (label, date, isExpiryEdit = false) => {
    return (
      <div>
        {renderLabel(label)}
        <div className={classes.dateWrapper}>
          <Typography variant="subtitle1">
            {date ? formatDate(date) : '-'}
          </Typography>
          {isExpiryEdit && !isDraft && (
            <ActionButton
              className={classes.editButton}
              type={ACTION_BUTTON_TYPES.EDIT}
              onClickHandler={toggleEditExpiryDate}
            />
          )}
        </div>
      </div>
    );
  };

  const renderCreator = () => (
    <div>
      {renderLabel(translations.createdBy)}
      {isCreatorActive ? (
        <UserAvatar
          variant="subtitle1"
          user={creator}
          clickableCaption={isAccesibleUser}
          onClickHandler={() => onGoToPersonProfile(creator.id)}
          small
          caption
        />
      ) : (
        <Typography variant="subtitle1">-</Typography>
      )}
    </div>
  );

  const renderUsersToReview = () => (
    <div>
      <ListWithTooltip
        labelClass={classes.label}
        moreItemsClass={classes.userMoreItems}
        label={translations.reviewedUsers}
        items={activeCreatedFor}
        isItemClickable={isUserClickable}
        onItemClick={onGoToPersonProfile}
        asyncListProps={{
          position: 'top',
        }}
        organizationUser={organizationUser}
        isUserList
      />
    </div>
  );

  const renderCompletedCounter = () => (
    <div>
      {renderLabel(translations.completed)}
      <Typography variant="subtitle1">{isDraft ? '-' : completed}</Typography>
    </div>
  );

  const renderSurveyGroup = () => (
    <div className={classes.groupWrapper}>
      {renderLabel(translations.group)}
      <div className={classes.groupTitleWrapper}>
        <TextBoxWithTooltip
          className={classes.groupTitle}
          variant="subtitle1"
          text={currentGroup || '-'}
        />
        <ActionButton
          className={classes.editGroupButton}
          type={ACTION_BUTTON_TYPES.EDIT}
          onClickHandler={toggleManageGroup}
        />
      </div>
    </div>
  );

  return (
    <div className={classes.main}>
      <div className={classes.info}>
        {renderStatus(translations.status, status, statuses, types)}
        <SurveyType label={translations.type} type={currentType} hasTooltip />
        {renderDate(
          isScheduled ? translations.scheduledFor : translations.dateSent,
          isScheduled ? scheduledFor : sentAt
        )}
        {renderDate(translations.expireAt, expireAt, true)}
        {renderCreator()}
        {renderUsersToReview()}
        {renderCompletedCounter()}
      </div>
      <ListWithTooltip
        className={classes.attributes}
        labelClass={classes.label}
        label={translations.attributes}
        items={activeAttributes}
        asyncListProps={{
          isDisabled: true,
          position: 'top',
        }}
      />
      {shouldShowGroup && renderSurveyGroup()}
      <DateAndTimeDialog
        translations={translations.expiryDialog}
        isOpened={isEditExpiryOpened}
        initialDate={initialExpiryDate}
        fields={expiryFields}
        onClose={toggleEditExpiryDate}
        onSave={handleEditExpiryDate}
      />
      {shouldShowGroup && (
        <CustomSelectDialog
          translations={translations.manageGroupDialog}
          isOpened={isManageGroupOpened}
          options={surveyGroups}
          isEdit={!!group}
          initialValue={group}
          field={SURVEY_GROUP_FIELD}
          onCancel={toggleManageGroup}
          onSave={onChangeGroup}
        />
      )}
    </div>
  );
};

SurveyInfo.defaultProps = {
  surveyGroups: [],
  shouldShowGroup: false,
};

SurveyInfo.propTypes = {
  translations: PropTypes.object.isRequired,
  survey: PropTypes.shape({}).isRequired,
  surveyGroups: PropTypes.arrayOf(PropTypes.object),
  statuses: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  types: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  expiryFields: PropTypes.arrayOf(PropTypes.object).isRequired,
  organizationUser: PropTypes.object.isRequired,
  isUserClickable: PropTypes.func.isRequired,
  shouldShowGroup: PropTypes.bool,
  onGoToPersonProfile: PropTypes.func.isRequired,
  onEditExpiryDate: PropTypes.func.isRequired,
  onChangeGroup: PropTypes.func.isRequired,
};
export default memo(SurveyInfo);
