import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import CustomFormDrawer from '../customFormDrawer';

const useStyles = makeStyles(({ spacing }) => ({
  header: {
    padding: spacing(5, 6),
  },
  columns: {
    gridTemplateColumns: 'minmax(0, 384px) minmax(0, 1fr)',
    '& .selection': {
      padding: spacing(5, 6, 0),
    },
  },
  formContent: {
    padding: 0,
  },
}));

const TemplateForm = ({ translations, isOpened, columns, onSave, onClose }) => {
  const classes = useStyles();

  return (
    <CustomFormDrawer
      customHeaderClass={classes.header}
      customColumnsClass={classes.columns}
      customContentClass={classes.formContent}
      translations={translations}
      isOpened={isOpened}
      columns={columns}
      onClose={onClose}
      onSave={onSave}
      hasColumnScroll
      hasCancelButton
      isFullWidth
    />
  );
};

TemplateForm.propTypes = {
  translations: PropTypes.object.isRequired,
  isOpened: PropTypes.bool.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

export default TemplateForm;
