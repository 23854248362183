export const FIELD_TYPES = {
  ADD_COURSES: 'addCourses',
  ADD_IMAGE: 'addImage',
  ADD_USERS_TABLE: 'addUsersTable',
  CREATABLE_TAGS: 'creatableTags',
  TEXT: 'text',
  SELECT: 'select',
  AUTOCOMPLETE: 'autocomplete',
  SELECT_WITH_RANGE: 'selectWithRange',
  RANGE_FROM: 'range_from',
  RANGE_TO: 'range_to',
  BUTTON_SWITCH: 'toggleButtonSwitch',
  COLOR_PICKER: 'color_picker',
  CREATED_BY: 'created_by',
  ATTRIBUTES_QUESTIONS_FORM: 'add_attribute_questions',
  SEARCH: 'search',
  SELECT_COURSE: 'selectCourse',
  SWITCH: 'switch',
  CHECKBOX: 'checkbox',
  CHECKBOX_GROUP: 'checkboxGroup',
  DATE_PICKER: 'date',
  RICH_TEXT_EDITOR: 'richTextEditor',
  SUBTITLE: 'subtitle',
  TIME_PICKER: 'time',
  SURVEY_PREVIEW: 'surveyPreview',
  READ_ONLY: 'readOnly',
  PREVIEW: 'preview',
  PEOPLE_PICKER: 'peoplePicker',
  MEETING_AGENDA: 'meetingAgenda',
  MEETING_IMPRESSION: 'meetingImpression',
  MEETING_STATUS: 'meetingStatus',
  FILTERS_SETUP: 'filtersSetup',
  TASKS: 'tasks',
  SLIDER: 'slider',
  SELECTABLE_LIST: 'selectableList',
  PASSWORD: 'password',
  REVIEWS: 'reviews',
};
