import { ACTION_BUTTON_TYPES } from '../actionButton/config';
import { USER_STATUSES } from '../../../constants/statuses';

export const getUserStatatusActions = (
  labels,
  status,
  onInvite,
  onReinvite,
  onRevokeAccess,
  onDeactivate,
  onReactivate,
  onDelete
) => {
  const {
    ACTIVE_WITHOUT_ACCESS,
    ACTIVE_INVITED,
    ACTIVE_WITH_ACCESS,
    DEACTIVATED,
  } = USER_STATUSES;

  return [
    {
      type: ACTION_BUTTON_TYPES.USER_INVITE,
      tooltipText: labels.invite,
      isSquared: true,
      isActive: status === ACTIVE_WITHOUT_ACCESS,
      onClickHandler: onInvite,
    },
    {
      type: ACTION_BUTTON_TYPES.USER_REINVITE,
      tooltipText: labels.reinvite,
      isSquared: true,
      isActive: status === ACTIVE_INVITED,
      onClickHandler: onReinvite,
    },
    {
      type: ACTION_BUTTON_TYPES.USER_REVOKE_ACCESS,
      tooltipText: labels.revokeAccess,
      isSquared: true,
      isActive: status === ACTIVE_WITH_ACCESS || status === ACTIVE_INVITED,
      onClickHandler: onRevokeAccess,
    },
    {
      type: ACTION_BUTTON_TYPES.USER_REACTIVATE,
      tooltipText: labels.reactivate,
      isSquared: true,
      isActive: status === DEACTIVATED,
      onClickHandler: onReactivate,
    },
    {
      type: ACTION_BUTTON_TYPES.USER_DEACTIVATE,
      tooltipText: labels.deactivate,
      isSquared: true,
      isActive: status !== DEACTIVATED,
      onClickHandler: onDeactivate,
    },
    {
      type: ACTION_BUTTON_TYPES.DELETE_FILL,
      tooltipText: labels.delete,
      isSquared: true,
      isActive: status === DEACTIVATED,
      onClickHandler: onDelete,
    },
  ];
};
