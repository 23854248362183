import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Typography, withStyles } from '@material-ui/core';
import { getCategoriesWithTags } from '../../../utility/tagUtils';
import {
  isActiveUserView,
  isDeactivatedUserView,
  isExternalUserView,
} from '../../../utility/person';
import CustomCheckbox from '../../shared/customCheckbox';
import UserAvatar from '../../shared/userAvatar';
import GridTable from '../../shared/gridTable';
import Button from '../../shared/customButton';
import SelectField from '../../shared/selectField';
import Search from '../../shared/search';
import VisibleForPermission from '../../shared/visibleForPermission';
import BulkEditDialog from '../../shared/bulkEditDialog';
import ConditionalTooltip from '../../shared/conditionalTooltip';
import Filters from '../../shared/filters';
import DotsMenu from '../../shared/dotsMenu';
import AlertDialog from '../../shared/alertDialog';
import NotificationCard from '../../shared/notificationCard';
import TransferEmployeesDialog from '../../shared/transferEmployeesDialog';
import AddUsersDialog from '../../shared/addUsersDialog';
import UserStatus from '../../shared/userStatus';
import CustomFormDrawer from '../../shared/customFormDrawer';
import AssignUsersDialog from '../../shared/assignUsersDialog';
import { formatDate } from '../../../utility/dateUtils';
import {
  parseDuplicateParameters,
  showSuccessMessage,
  hasNextPage,
  prepareTracksWithLevels,
  parseQueryParams,
  isEmployeeInReporters,
  prepareFrameworksWithTracks,
} from '../../../utility/uiUtils';
import {
  isArray,
  isArrayEmpty,
  checkUserRole,
  getUnionOfTwoArrays,
  trimString,
  isObjectEmpty,
  updateAuthData,
  getItemById,
} from '../../../utility/helpers';
import http from '../../../utility/http';
import {
  EVENT_ACTION_TYPES,
  tagManagerDataLayer,
} from '../../../utility/tagManager';
import { getUserStatuses } from '../../../utility/people';
import {
  EMPLOYEE_ROLES_LIST,
  PERMISSIONS,
  ROLES,
} from '../../../constants/rolesAndPermissionList';
import { BULK_ACTION_OPTIONS } from '../../../constants/bulkActionOptions';
import {
  API_ORG_CHART,
  api_user,
  API_USERS_BASIC,
  API_USERS,
  API_USERS_MULTIPLE,
  API_VALIDATE_EMAILS,
} from '../../../constants/apiRoutes';
import { sticky } from '../../../constants/helperCssRules';
import { USER_STATUSES } from '../../../constants/statuses';
import {
  PARAMS,
  PEOPLE_DEFAULT_PARAMS,
  PAGE_WHITELISTED_PARAMS,
} from '../../../constants/pages';
import {
  INITIAL_SORT,
  ADD_USER_OPTION_KEYS,
  USER_INFO,
  USER_INFO_KEYS,
  PEOPLE_DEFAULT_ORDERING,
  USER_VIEW_OPTIONS,
} from '../../../constants/people';
import {
  getPageFilters,
  getTableHeaders,
  getHeaderActions,
  prepareTableData,
  getPreHeaderActions,
  getVisibleEmployees,
  getBulkActionOptions,
  getCheckboxTooltip,
  getDisabledMenuTooltip,
  isCheckboxChecked,
  isCheckboxDisabled,
  isMenuDisabled,
  areAllUsersChecked,
  getUserRoles,
  getInitialUserData,
  getEditUserFields,
  getAddUserOptions,
  getValidImportedUsers,
  getAddUsersFields,
  getAddUsersInitialValues,
  getAddUsersInfo,
  prepareUsersForSave,
  getUserViewOptions,
  getWhitelistedFilters,
  getViewDescription,
  getMenuItems,
} from './config';
import { isEmpty } from '../../../utility/validation';

const styles = ({ palette: { primary }, breakpoints, spacing }) => ({
  stickyHeader: {
    ...sticky(primary.white, 105, 1000),
  },
  description: {
    padding: spacing(8, 0, 6, 0),
  },
  search: { width: 300 },
  addButton: {
    marginLeft: spacing(2),
    width: 'max-content',
  },
  table: {
    '& .grid-table-header': {
      ...sticky(primary.bluish8, 235),
    },
    '& .name-header-cell': {
      paddingLeft: spacing(10),

      [breakpoints.up('xLg')]: {
        paddingLeft: spacing(12),
      },
    },
    '& .actions-dots-menu': {
      padding: spacing(3, 1),
      overflow: 'visible',

      [breakpoints.up('xLg')]: {
        padding: spacing(3),
      },
    },
  },
  userLabel: {
    fontFamily: 'ProximaNova-Bold',
    fontSize: 16,
    lineHeight: '24px',
  },
  bulkEditAndFiltersWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  bulkEditAndFiltersItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: spacing(4),
  },
  bulkEdit: { height: 35 },
  selectBulkAction: {
    minWidth: 118,
    marginRight: spacing(2),
  },
  bulkActionMenu: {
    minWidth: 210,
    maxHeight: 294,
  },
  bulkActionMenuList: {
    maxHeight: '260px !important',
  },
  tooltipText: { margin: 0 },
  checkbox: {
    marginLeft: 0,
    minWidth: 24,
  },
  userActionsTooltip: {
    maxWidth: 24,
    marginLeft: 'auto',
  },
  userActions: {
    maxWidth: 24,
  },
  tableContent: {
    padding: spacing(6, 3, 6, 8),
  },
  actionButton: {
    marginLeft: spacing(2),
    width: 225,
  },
});

const DATE_FORMAT = 'y-MM-dd';
const {
  PAGE,
  SEARCH,
  STATUS,
  ROLE,
  JOB_TITLE,
  TAGS,
  TRACK,
  LEVEL,
  SCOPE,
  REPORT_TO,
  ORDERING,
  EXCLUDE_ROLE,
  EXCLUDE_STATUS,
  AVAILABLE_MANAGERS,
  WITHOUT_ACTION_PLANS,
} = PARAMS;
const { MANUAL, IMPORT, ASSOCIATE } = ADD_USER_OPTION_KEYS;

class PeoplePage extends PureComponent {
  state = {
    [PAGE]: PEOPLE_DEFAULT_PARAMS[PAGE],
    isInitialLoad: true,
    filters: {
      [ROLE]: [],
      [STATUS]: [],
      [SCOPE]: [],
      [JOB_TITLE]: [],
      [TRACK]: [],
      [LEVEL]: [],
      [TAGS]: [],
      [WITHOUT_ACTION_PLANS]: [],
    },
    [ORDERING]: INITIAL_SORT,
    [SEARCH]: '',
    checkedUsers: [],
    reportToOptions: [],
    selectAllChecked: false,
    selectedBulkAction: {},
    organizationMentor: null,
    usersToTransfer: [],
    updatedUserData: {},
    isTransferUsersDialogOpened: false,
    isEditUserDialogOpened: false,
    isAddUsersDialogOpened: false,
    isAddUsersDrawerOpened: false,
    isChangeRoleDialogOpened: false,
    user: {},
    selectedAddUsersOption: MANUAL,
    csvImport: {
      validUsers: [],
    },
    importedUsers: [],
    selectedView: USER_VIEW_OPTIONS[0].value,
    availableManagers: [],
    isInviteDialogOpened: false,
    isReinviteDialogOpened: false,
    isRevokeAccessDialogOpened: false,
    isDeactivateDialogOpened: false,
    isReactivateDialogOpened: false,
    isDeleteDialogOpened: false,
  };

  componentDidMount() {
    this.getInitialData();
  }

  componentWillUnmount() {
    const {
      clearJobTitles,
      clearLevelsAndTracks,
      clearTagsCategories,
      clearPeople,
      clearPageQuickFilters,
    } = this.props;

    clearJobTitles();
    clearLevelsAndTracks();
    clearTagsCategories();
    clearPeople();
    clearPageQuickFilters();
  }

  getFilterParams = (params, selectedView) => {
    const { filters } = this.state;
    const isExternalUsersView = isExternalUserView(selectedView);
    const isDeactivatedUsersView = isDeactivatedUserView(selectedView);

    let paramsRole = params[ROLE]
      ? [...(isArray(params[ROLE]) ? params[ROLE] : [params[ROLE]])]
      : [];
    paramsRole = isExternalUsersView ? filters[ROLE] : paramsRole;

    let paramsStatus = params[STATUS]
      ? [...(isArray(params[STATUS]) ? params[STATUS] : [params[STATUS]])]
      : [];
    paramsStatus = isDeactivatedUsersView ? filters[STATUS] : paramsStatus;

    const paramsScope = params[SCOPE]
      ? [...(isArray(params[SCOPE]) ? params[SCOPE] : [params[SCOPE]])]
      : [];

    return {
      [ROLE]: paramsRole,
      [STATUS]: paramsStatus,
      [SCOPE]: paramsScope,
      [JOB_TITLE]: params[JOB_TITLE]
        ? [
            ...(isArray(params[JOB_TITLE])
              ? params[JOB_TITLE]
              : [params[JOB_TITLE]]),
          ]
        : [],
      [TRACK]: params[TRACK]
        ? [...(isArray(params[TRACK]) ? params[TRACK] : [params[TRACK]])]
        : [],
      [LEVEL]: params[LEVEL]
        ? [...(isArray(params[LEVEL]) ? params[LEVEL] : [params[LEVEL]])]
        : [],
      [TAGS]: params[TAGS]
        ? [...(isArray(params[TAGS]) ? params[TAGS] : [params[TAGS]])]
        : [],
      [WITHOUT_ACTION_PLANS]:
        params[WITHOUT_ACTION_PLANS] !== undefined
          ? [JSON.parse(params[WITHOUT_ACTION_PLANS])]
          : [],
    };
  };

  getInitialData = () => {
    const {
      location,
      translations,
      getPageQuickFilters,
      getTagsCategories,
      getJobTitles,
      getLevelsAndTracks,
      getOrganizationSettings,
    } = this.props;
    const columns = getTableHeaders(translations.columns);
    const params = parseQueryParams(
      location.search,
      PAGE_WHITELISTED_PARAMS.PEOPLE
    );

    const selectedView = location.state?.view || this.state.selectedView;

    if (!isObjectEmpty(params)) {
      const [ascending, descending] = params[ORDERING].split('-');
      const isAscending = !!ascending;

      return this.setState(
        {
          selectedView,
          [ORDERING]: {
            column: getItemById(
              columns,
              isAscending ? ascending : descending,
              'sortAs'
            )?.rowKey,
            asc: isAscending,
            sortKey: params[ORDERING],
          },
          [SEARCH]: params[SEARCH]?.toString() || '',
          filters: this.getFilterParams(params, selectedView),
        },
        () => {
          return Promise.all([
            this.handlePageChange(false),
            getJobTitles(),
            getLevelsAndTracks(),
            getTagsCategories(),
            getPageQuickFilters(),
            getOrganizationSettings(),
            this.getOrganizationMentor(),
          ]).then(() => {
            this.setState({ isInitialLoad: false });
          });
        }
      );
    }

    return Promise.all([
      this.handlePageChange(),
      getJobTitles(),
      getLevelsAndTracks(),
      getTagsCategories(),
      getPageQuickFilters(),
      getOrganizationSettings(),
      this.getOrganizationMentor(),
    ]).then(() => {
      this.setState({ isInitialLoad: false });
    });
  };

  getOrganizationMentor = () => {
    const { translations } = this.props;

    return http.get(API_ORG_CHART).then(({ data }) => {
      this.setState({
        organizationMentor: {
          ...data,
          last_name: translations.organization,
          isCeo: true,
        },
      });
    });
  };

  handleUpdateCurrentUserReporters = userData => {
    const { auth, updateCurrentUserInfo } = this.props;
    const reporters = {
      ...auth.reporters,
      [auth.id]: true,
    };
    const isModerator = checkUserRole(auth[ROLE], ROLES.MODERATOR);
    const isDeactivated = userData?.[STATUS] === USER_STATUSES.DEACTIVATED;
    const isReportToInReporters = isArray(userData)
      ? userData.every(user =>
          isEmployeeInReporters(user?.[USER_INFO.REPORT_TO], reporters)
        )
      : isEmployeeInReporters(userData?.[USER_INFO.REPORT_TO], reporters);

    if ((isDeactivated || !isReportToInReporters) && isModerator) {
      updateAuthData(updateCurrentUserInfo);
    }
  };

  handlePageChange = (isLoadMore = false, selectedUserView = '') => {
    const { history, location, auth, getPeople } = this.props;
    const { page, filters, ordering, checkedUsers, search, selectedView } =
      this.state;

    const view = isEmpty(selectedUserView) ? selectedUserView : selectedView;

    const isExternalUsersView = isExternalUserView(view);
    const isDeactivatedUsersView = isDeactivatedUserView(view);
    const viewsFilters = isDeactivatedUsersView
      ? { [STATUS]: [USER_STATUSES.DEACTIVATED] }
      : {
          [EXCLUDE_ROLE]: [ROLES.ASSOCIATE],
          [EXCLUDE_STATUS]: [USER_STATUSES.DEACTIVATED],
        };
    if (isDeactivatedUsersView && viewsFilters[EXCLUDE_ROLE]) {
      delete viewsFilters[EXCLUDE_ROLE];
      delete viewsFilters[EXCLUDE_STATUS];
    }

    const addedParams = isExternalUsersView
      ? { [ROLE]: [ROLES.ASSOCIATE], [STATUS]: [], [SCOPE]: [] }
      : {
          ...viewsFilters,
          ...(filters[ROLE].includes(ROLES.ASSOCIATE) ? { [ROLE]: [] } : {}),
          ...(filters[STATUS].includes(USER_STATUSES.DEACTIVATED) &&
          !isDeactivatedUsersView
            ? { [STATUS]: [] }
            : {}),
          ...(isDeactivatedUsersView ? { [SCOPE]: [] } : {}),
        };

    const params = {
      page,
      [ORDERING]: ordering.sortKey,
      ...(isExternalUsersView ? {} : filters),
      ...addedParams,
      ...(search ? { search } : {}),
    };
    const query = parseDuplicateParameters(params);

    if (location.search !== `?${query}`) {
      history.replace(`/people/?${query}`, { view });
    }

    return getPeople(isLoadMore, params).then(() => {
      this.setState({
        selectAllChecked: areAllUsersChecked(
          checkedUsers,
          this.props.people.results,
          auth
        ),
      });
    });
  };

  onLoadMore = () => {
    return this.setState(
      prevState => ({ ...prevState, [PAGE]: prevState[PAGE] + 1 }),
      () => this.handlePageChange(true)
    );
  };

  onSort = newOrdering => {
    this.setState(
      {
        [PAGE]: PEOPLE_DEFAULT_PARAMS[PAGE],
        [ORDERING]: newOrdering,
      },
      () => this.handlePageChange()
    );
  };

  onSearch = text => {
    const searchTerm = trimString(text);

    this.setState(
      { [PAGE]: PEOPLE_DEFAULT_PARAMS[PAGE], search: searchTerm },
      () => this.handlePageChange()
    );
  };

  handleApplyFilters = newFilters =>
    this.setState(
      { [PAGE]: PEOPLE_DEFAULT_PARAMS[PAGE], filters: newFilters },
      () => this.handlePageChange()
    );

  handleSelectAllChange = checked => {
    const {
      auth,
      people: { results },
    } = this.props;

    this.setState(prevState => {
      const updatedCheckedUsers = checked
        ? getUnionOfTwoArrays(
            prevState.checkedUsers,
            getVisibleEmployees(results, auth),
            'id'
          )
        : [
            ...prevState.checkedUsers.filter(
              user => !results.some(x => x.id === user.id)
            ),
          ];
      return {
        ...prevState,
        checkedUsers: updatedCheckedUsers,
        selectAllChecked: checked,
      };
    });
  };

  handleUserCheckboxChange = id => checked => {
    const {
      auth,
      people: { results },
    } = this.props;

    this.setState(prevState => {
      const updatedCheckedUsers = checked
        ? [...prevState.checkedUsers, results.find(user => user.id === id)]
        : prevState.checkedUsers.filter(user => user.id !== id);

      return {
        ...prevState,
        checkedUsers: updatedCheckedUsers,
        selectAllChecked: areAllUsersChecked(
          updatedCheckedUsers,
          results,
          auth
        ),
      };
    });
  };

  handleSelectBulkAction = value => {
    const { setDialogVisibility } = this.props;
    const selectedBulkAction = BULK_ACTION_OPTIONS.find(
      el => el.value === value
    );

    this.setState({ selectedBulkAction }, () =>
      setDialogVisibility({
        dialogName: 'bulkEditDialog',
        opened: true,
      })
    );
  };

  handleBulkEditClose = () => {
    const { setDialogVisibility } = this.props;

    this.setState(() => ({
      selectedBulkAction: {},
    }));
    setDialogVisibility({
      dialogName: 'bulkEditDialog',
      opened: false,
    });
  };

  handleBulkEditSave = async postData => {
    const { translations, setDialogVisibility } = this.props;
    const { selectedBulkAction } = this.state;

    if (isArrayEmpty(postData)) {
      return Promise.resolve(
        this.setState(
          {
            selectAllChecked: false,
            checkedUsers: [],
            selectedBulkAction: {},
          },
          () => {
            setDialogVisibility({
              dialogName: 'bulkEditDialog',
              opened: false,
            });
          }
        )
      );
    }

    try {
      await http.patch(API_USERS_MULTIPLE, postData);
      showSuccessMessage(
        translations.bulkEditDialog[
          `${selectedBulkAction.translationKey}Success`
        ] || translations.bulkEditDialog.success
      );
      this.setState(
        {
          [PAGE]: PEOPLE_DEFAULT_PARAMS[PAGE],
          checkedUsers: [],
          selectedBulkAction: {},
        },
        () => {
          setDialogVisibility({
            dialogName: 'bulkEditDialog',
            opened: false,
          });
          this.handleUpdateCurrentUserReporters(postData);
          return this.handlePageChange();
        }
      );
    } catch {
      this.setState(
        {
          selectAllChecked: false,
          checkedUsers: [],
          selectedBulkAction: {},
        },
        () => {
          setDialogVisibility({
            dialogName: 'bulkEditDialog',
            opened: false,
          });
        }
      );
    }
  };

  getAvailableManagers = async user => {
    const { organizationMentor } = this.state;

    const { data } = await http.get(API_USERS_BASIC, {
      params: {
        ...PEOPLE_DEFAULT_ORDERING,
        [EXCLUDE_ROLE]: ROLES.ASSOCIATE,
        [EXCLUDE_STATUS]: USER_STATUSES.DEACTIVATED,
        [AVAILABLE_MANAGERS]: user.id,
      },
      paramsSerializer: d => parseDuplicateParameters(d),
    });

    return [organizationMentor].concat(data);
  };

  handleUserMentorChange =
    (user = {}) =>
    (value = '') => {
      const { organizationMentor } = this.state;
      const trimmedValue = trimString(value);

      const params = {
        page: 1,
        page_size: 12,
        ordering: 'first_name',
        search: trimmedValue,
        status: [
          USER_STATUSES.ACTIVE_WITHOUT_ACCESS,
          USER_STATUSES.ACTIVE_INVITED,
          USER_STATUSES.ACTIVE_WITH_ACCESS,
        ],
        role: EMPLOYEE_ROLES_LIST,
      };

      if (user?.id) {
        params.available_managers = user?.id;
      }

      if (trimmedValue) {
        return http
          .get(API_USERS, {
            params,
            paramsSerializer: data => parseDuplicateParameters(data),
          })
          .then(({ data }) => {
            this.setState({
              reportToOptions: [organizationMentor].concat(data.results),
            });
          });
      }
      return Promise.resolve(this.setState({ reportToOptions: [] }));
    };

  toggleDeleteUserDialog =
    (user = {}) =>
    () =>
      this.setState(prevState => ({
        isDeleteDialogOpened: !prevState.isDeleteDialogOpened,
        user,
      }));

  onDelete = async () => {
    const { translations, getOrganizationSettings } = this.props;
    const { user } = this.state;

    try {
      await http.delete(api_user(user.id));
      showSuccessMessage(translations.deleteUserDialog.deleteSuccess);
    } finally {
      this.setState(
        {
          [PAGE]: PEOPLE_DEFAULT_PARAMS[PAGE],
          isDeleteDialogOpened: false,
          user: {},
        },
        () => {
          getOrganizationSettings();
          this.handlePageChange();
        }
      );
    }
  };

  toggleChangeUserRoleDialog = (updatedUserData = {}) =>
    this.setState(prevState => ({
      isChangeRoleDialogOpened: !prevState.isChangeRoleDialogOpened,
      updatedUserData,
    }));

  handleTransferExternalToEmployees = () => {
    const { updatedUserData } = this.state;
    this.saveUserInfoChanges(updatedUserData.id, {
      [USER_INFO.ROLE]: updatedUserData[USER_INFO.ROLE],
      [USER_INFO.REPORT_TO]: updatedUserData[USER_INFO.REPORT_TO],
    });
    this.toggleChangeUserRoleDialog();
  };

  handleUploadUsers = async ({ data }) => {
    const { organizationMentor } = this.state;
    const users = await getValidImportedUsers(data, organizationMentor);

    if (!isArrayEmpty(users.validEmails)) {
      return http
        .post(API_VALIDATE_EMAILS, {
          emails: users.validEmails,
        })
        .then(results => {
          const takenEmails = results.data.taken;
          const validUsers = users.validUsers.filter(
            user => !takenEmails.includes(user.email)
          );
          this.setState({
            csvImport: { ...users, validUsers, existingUsers: takenEmails },
          });
        });
    }

    this.setState({ csvImport: { ...users, existingUsers: [] } });
  };

  handleDeleteUpload = () => this.setState({ csvImport: { validUsers: [] } });

  handleSelectAddUsersOption = option => () => {
    const { selectedAddUsersOption } = this.state;

    if (selectedAddUsersOption !== option) {
      this.setState({
        selectedAddUsersOption: option,
        csvImport: { validUsers: [] },
      });
    }
  };

  handleOpenAddUsers = isAdmin => () =>
    this.setState({
      isAddUsersDialogOpened: isAdmin,
      isAddUsersDrawerOpened: !isAdmin,
    });

  handleCloseAddUsers = () =>
    this.setState({
      isAddUsersDialogOpened: false,
      selectedAddUsersOption: MANUAL,
      csvImport: { validUsers: [] },
    });

  handleConfirmAddUsers = isUserImport => () => {
    const { csvImport } = this.state;

    if (isUserImport) {
      return this.onAddUsers({ users: csvImport.validUsers }).then(
        ({ data }) => {
          this.setState({
            isAddUsersDrawerOpened: true,
            isAddUsersDialogOpened: false,
            importedUsers: data,
          });
        }
      );
    }

    this.setState({
      isAddUsersDrawerOpened: true,
      isAddUsersDialogOpened: false,
    });
  };

  handleCloseAddUsersDrawer = () => {
    this.setState({
      isAddUsersDrawerOpened: false,
      csvImport: { validUsers: [] },
      importedUsers: [],
      selectedAddUsersOption: MANUAL,
    });
  };

  onAddUsers = async ({ users }) => {
    const { translations, updateCurrentUserInfo, getOrganizationSettings } =
      this.props;
    const { importedUsers } = this.state;
    const { AddUser } = EVENT_ACTION_TYPES;
    const isEdit = !isArrayEmpty(importedUsers);
    let addedUsers = [];

    if (isEdit) {
      await http.patch(
        API_USERS_MULTIPLE,
        prepareUsersForSave(users, importedUsers)
      );
    } else {
      addedUsers = await http.post(
        API_USERS_MULTIPLE,
        prepareUsersForSave(users)
      );
    }

    await updateAuthData(updateCurrentUserInfo);
    getOrganizationSettings();
    tagManagerDataLayer(AddUser.action, AddUser.name, users.length);
    showSuccessMessage(
      isEdit
        ? translations.addUsersTable.success.update
        : translations.addUsersTable.success.add
    );
    this.setState({ [PAGE]: PEOPLE_DEFAULT_PARAMS[PAGE], user: {} }, () =>
      this.handlePageChange()
    );

    return Promise.resolve(addedUsers);
  };

  toggleTransferUsersDialog = (user = {}, usersToTransfer = []) =>
    this.setState(prevState => ({
      isTransferUsersDialogOpened: !prevState.isTransferUsersDialogOpened,
      user,
      usersToTransfer,
    }));

  onTransferUsers = async () => {
    const { translations } = this.props;
    const { user } = this.state;
    const userData = {
      [STATUS]: USER_STATUSES.DEACTIVATED,
    };

    try {
      await http.patch(api_user(user.id), userData);
      showSuccessMessage(translations.deactivateUserDialog.deactivateSuccess);
    } finally {
      this.setState(
        {
          [PAGE]: PEOPLE_DEFAULT_PARAMS[PAGE],
        },
        () => {
          this.handlePageChange();
          this.handleUpdateCurrentUserReporters(userData);
        }
      );
    }
  };

  handleUserEditOpen = async user => {
    const availableManagers = await this.getAvailableManagers(user);

    this.setState(prevState => ({
      isEditUserDialogOpened: !prevState.isEditUserDialogOpened,
      user,
      availableManagers,
    }));
  };

  handleUserEditClose = () =>
    this.setState({
      isEditUserDialogOpened: false,
      availableManagers: [],
      user: {},
    });

  saveUserInfoChanges = (id, userInfo, shouldRefresh = true) =>
    http.patch(api_user(id), userInfo).then(() => {
      const { translations } = this.props;

      if (shouldRefresh) {
        this.setState({ [PAGE]: PEOPLE_DEFAULT_PARAMS[PAGE], user: {} }, () => {
          this.handlePageChange();
          this.handleUpdateCurrentUserReporters(userInfo);
          showSuccessMessage(translations.userEditSuccess);
        });
      }
    });

  handleToggleInvite =
    (user = {}) =>
    () =>
      this.setState(prevState => ({
        isInviteDialogOpened: !prevState.isInviteDialogOpened,
        user,
      }));

  onInvite = async () => {
    const { translations } = this.props;
    const { user } = this.state;

    try {
      await http.patch(api_user(user.id), {
        [STATUS]: USER_STATUSES.ACTIVE_INVITED,
      });
      showSuccessMessage(translations.inviteUserDialog.inviteSuccess);
    } finally {
      this.setState(
        {
          [PAGE]: PEOPLE_DEFAULT_PARAMS[PAGE],
          user: {},
          isInviteDialogOpened: false,
        },
        () => {
          this.handlePageChange();
        }
      );
    }
  };

  handleToggleReinvite =
    (user = {}) =>
    () =>
      this.setState(prevState => ({
        isReinviteDialogOpened: !prevState.isReinviteDialogOpened,
        user,
      }));

  onReinvite = async () => {
    const { translations } = this.props;
    const { user } = this.state;

    try {
      await http.patch(api_user(user.id), {
        [STATUS]: USER_STATUSES.ACTIVE_INVITED,
      });
      showSuccessMessage(translations.reinviteUserDialog.reinviteSuccess);
    } finally {
      this.setState({
        user: {},
        isReinviteDialogOpened: false,
      });
    }
  };

  handleToggleRevokeAccess =
    (user = {}) =>
    () =>
      this.setState(prevState => ({
        isRevokeAccessDialogOpened: !prevState.isRevokeAccessDialogOpened,
        user,
      }));

  onRevokeAccess = async () => {
    const { translations } = this.props;
    const { user } = this.state;

    try {
      await http.patch(api_user(user.id), {
        [STATUS]: USER_STATUSES.ACTIVE_WITHOUT_ACCESS,
      });
      showSuccessMessage(translations.revokeAccessUserDialog.revokeSuccess);
    } finally {
      this.setState(
        {
          [PAGE]: PEOPLE_DEFAULT_PARAMS[PAGE],
          user: {},
          isRevokeAccessDialogOpened: false,
        },
        () => {
          this.handlePageChange();
        }
      );
    }
  };

  handleToggleDeactivate =
    (user = {}) =>
    () =>
      this.setState(prevState => ({
        isDeactivateDialogOpened: !prevState.isDeactivateDialogOpened,
        user,
      }));

  handleOpenDeactivate = user => async () => {
    const reporters = await this.checkUsersDirectReports(user.id);

    if (!isArrayEmpty(reporters)) {
      return this.toggleTransferUsersDialog(user, reporters);
    }

    this.handleToggleDeactivate(user)();
  };

  onDeactivate = async () => {
    const { translations } = this.props;
    const { user } = this.state;
    const userData = {
      [STATUS]: USER_STATUSES.DEACTIVATED,
    };

    try {
      await http.patch(api_user(user.id), userData);
      showSuccessMessage(translations.deactivateUserDialog.deactivateSuccess);
    } finally {
      this.setState(
        {
          [PAGE]: PEOPLE_DEFAULT_PARAMS[PAGE],
          user: {},
          isDeactivateDialogOpened: false,
        },
        () => {
          this.handlePageChange();
          this.handleUpdateCurrentUserReporters(userData);
        }
      );
    }
  };

  handleToggleReactivate =
    (user = {}, availableManagers = []) =>
    () =>
      this.setState(prevState => ({
        isReactivateDialogOpened: !prevState.isReactivateDialogOpened,
        user,
        availableManagers,
      }));

  handleOpenReactivate = user => async () => {
    const availableManagers = await this.getAvailableManagers(user);

    return this.handleToggleReactivate(user, availableManagers)();
  };

  onReactivate = async reportTo => {
    const { translations } = this.props;
    const { user } = this.state;

    try {
      await http.patch(api_user(user.id), {
        [STATUS]: USER_STATUSES.ACTIVE_WITHOUT_ACCESS,
        [REPORT_TO]: reportTo,
      });
      showSuccessMessage(translations.reactivateUserDialog.reactivateSuccess);
    } finally {
      this.setState(
        {
          [PAGE]: PEOPLE_DEFAULT_PARAMS[PAGE],
          user: {},
          availableManagers: [],
          isReactivateDialogOpened: false,
        },
        () => {
          this.handlePageChange();
        }
      );
    }
  };

  onUpdateUser = async values => {
    const { user } = this.state;
    const userData = {};

    USER_INFO_KEYS.forEach(key => {
      if (user[key] !== values[key]) {
        if (key === USER_INFO.ROLE) {
          if (user[key] !== values[key]) {
            userData[key] = values[key];
          }
          return;
        }
        if (key === USER_INFO.EMPLOYMENT_DATE && values[key]) {
          const formattedDate = formatDate(values[key], DATE_FORMAT);
          if (user[key] !== formattedDate) {
            userData[key] = formattedDate;
          }
          return;
        }
        if (key === USER_INFO.REPORT_TO) {
          if (values[key] && user[key] !== values[key]) {
            userData[key] = values[key];
          }
          return;
        }
        if (key === USER_INFO.JOB_TITLE) {
          if (user[key]?.id !== values[key]) {
            userData[key] = values[key];
          }
          return;
        }
        userData[key] = values[key];
      }
    });

    if (
      user[USER_INFO.ROLE] === ROLES.ASSOCIATE &&
      userData[USER_INFO.ROLE] &&
      userData[USER_INFO.ROLE] !== ROLES.ASSOCIATE
    ) {
      return this.toggleChangeUserRoleDialog({ ...userData, id: user.id });
    }

    if (!isObjectEmpty(userData)) {
      return this.saveUserInfoChanges(user.id, userData);
    }

    return Promise.resolve();
  };

  checkUsersDirectReports = async userId => {
    const { data } = await http.get(API_USERS_BASIC, {
      params: {
        ...PEOPLE_DEFAULT_ORDERING,
        [REPORT_TO]: userId,
      },
    });

    return data;
  };

  renderCheckbox = (onChange, isChecked = false, disabled = false) => {
    const { classes } = this.props;

    return (
      <CustomCheckbox
        customRootClass={classes.checkbox}
        isChecked={isChecked}
        onChange={onChange}
        disabled={disabled}
        isControlled
      />
    );
  };

  renderUserCheckbox = user => {
    const { classes, translations, auth, people } = this.props;
    const { checkedUsers } = this.state;
    const isAdmin = checkUserRole(auth.role, ROLES.ADMIN);
    const isDisabled = isCheckboxDisabled(people.results, auth, isAdmin, user);

    return (
      <ConditionalTooltip
        customLabelClass={classes.tooltipText}
        message={getCheckboxTooltip(translations, user, auth)}
        addTooltip={isDisabled}
        position="right"
      >
        {this.renderCheckbox(
          this.handleUserCheckboxChange(user.id),
          isCheckboxChecked(user.id, checkedUsers),
          isDisabled
        )}
      </ConditionalTooltip>
    );
  };

  renderAvatar = (user, canSeeUserProfile) => {
    const { classes } = this.props;

    return (
      <UserAvatar
        labelClass={classes.userLabel}
        user={user}
        clickableCaption={canSeeUserProfile}
        caption
        small
      />
    );
  };

  renderUserStatus = userStatus => <UserStatus status={userStatus} />;

  renderDotsMenu = (user, userStatus, isCurrentUser, isAdmin) => {
    const { classes, translations, auth } = this.props;

    const isDisabled = isMenuDisabled(user.id, auth, isAdmin);

    return (
      <ConditionalTooltip
        className={classes.userActionsTooltip}
        customLabelClass={classes.tooltipText}
        message={getDisabledMenuTooltip(translations, user, auth)}
        addTooltip={isDisabled}
        position="right"
      >
        <DotsMenu
          className={classNames({ [classes.userActions]: isDisabled })}
          menuItems={getMenuItems(
            translations.menuItemLabels,
            user,
            userStatus,
            isCurrentUser,
            isAdmin,
            this.handleUserEditOpen,
            this.handleToggleInvite,
            this.handleToggleReinvite,
            this.handleToggleRevokeAccess,
            this.handleOpenDeactivate,
            this.handleOpenReactivate,
            this.toggleDeleteUserDialog
          )}
          isDisabled={isDisabled}
        />
      </ConditionalTooltip>
    );
  };

  handleChangeView = view => {
    const { filters } = this.state;

    const newFilters = this.getFilterParams(
      {
        ...filters,
        [WITHOUT_ACTION_PLANS]: isArrayEmpty(filters[WITHOUT_ACTION_PLANS])
          ? undefined
          : filters[WITHOUT_ACTION_PLANS][0],
      },
      view
    );

    this.setState(
      {
        filters: newFilters,
        selectedView: view,
        [PAGE]: PEOPLE_DEFAULT_PARAMS[PAGE],
        ...(!isActiveUserView(view)
          ? { selectAllChecked: false, checkedUsers: [] }
          : {}),
      },
      () => this.handlePageChange(false, view)
    );
  };

  render() {
    const {
      translations,
      classes,
      people,
      jobTitles,
      grantedPermissions,
      auth,
      isPremiumUser,
      isSubscriptionExpired,
      dialogs,
      organizationSettings,
      frameworks,
      tracks,
      tagCategories,
      pageQuickFilters,
      getOrganizationSettings,
    } = this.props;

    const {
      isInitialLoad,
      checkedUsers,
      selectedBulkAction,
      selectAllChecked,
      organizationMentor,
      filters,
      search,
      ordering,
      isDeleteDialogOpened,
      isTransferUsersDialogOpened,
      isChangeRoleDialogOpened,
      isEditUserDialogOpened,
      usersToTransfer,
      reportToOptions,
      isAddUsersDialogOpened,
      isAddUsersDrawerOpened,
      selectedAddUsersOption,
      user,
      csvImport,
      importedUsers,
      availableManagers,
      selectedView,
      isInviteDialogOpened,
      isReinviteDialogOpened,
      isRevokeAccessDialogOpened,
      isDeactivateDialogOpened,
      isReactivateDialogOpened,
    } = this.state;
    const { canAddNewEmployee, canUseBulkEdit } = PERMISSIONS;
    const isAdmin = checkUserRole(auth.role, ROLES.ADMIN);
    const isUser = checkUserRole(auth.role, ROLES.USER);
    const isUserAdmin = checkUserRole(user[ROLE], ROLES.ADMIN);
    const isOwnProfile = auth.id === user.id;
    const userRoles = getUserRoles(
      translations,
      isAdmin,
      selectedAddUsersOption === ASSOCIATE,
      user
    );
    const userStatuses = getUserStatuses(translations.userStatuses.statuses);
    const userViewOptions = getUserViewOptions(translations.userViewOptions);
    const isUserImport = selectedAddUsersOption === IMPORT;
    const isAddUsersDisabled =
      isUserImport && isArrayEmpty(csvImport.validUsers);
    const isExternalUsersView = isExternalUserView(selectedView);
    const isActiveUsersView = isActiveUserView(selectedView);
    const description = getViewDescription(selectedView, translations);

    return (
      !isInitialLoad && (
        <div>
          <div className={classes.stickyHeader}>
            <Typography className={classes.description} variant="body2">
              {description}
            </Typography>
            <div className={classes.bulkEditAndFiltersWrapper}>
              <div className={classes.bulkEditAndFiltersItem}>
                <VisibleForPermission
                  permission={canUseBulkEdit}
                  permissions={grantedPermissions}
                >
                  <SelectField
                    className={classes.selectBulkAction}
                    menuClass={classes.bulkActionMenu}
                    menuListClass={classes.bulkActionMenuList}
                    placeholder={translations.action}
                    options={getBulkActionOptions(
                      translations.selectBulkActions
                    )}
                    isDisabled={
                      isArrayEmpty(checkedUsers) || !isActiveUsersView
                    }
                    onChange={this.handleSelectBulkAction}
                    isSearchDisabled
                    shouldRemoveLabel
                  />
                </VisibleForPermission>
                <Filters
                  translations={translations.filters}
                  disabled={isExternalUsersView}
                  selectedFilters={getWhitelistedFilters(filters, selectedView)}
                  filters={getPageFilters(
                    translations,
                    jobTitles,
                    tracks,
                    tagCategories,
                    getUserRoles(translations, true, isPremiumUser, {}, true),
                    isUser,
                    auth,
                    true,
                    pageQuickFilters,
                    selectedView
                  )}
                  onApplyFilters={this.handleApplyFilters}
                />
              </div>
              <div className={classes.bulkEditAndFiltersItem}>
                <Search
                  className={classes.search}
                  placeholder={translations.search}
                  value={search}
                  onChange={this.onSearch}
                />
                <SelectField
                  className={classes.actionButton}
                  value={selectedView}
                  options={userViewOptions}
                  parser={{ value: 'value', label: 'name' }}
                  onChange={this.handleChangeView}
                  isSearchDisabled
                  shouldRemoveLabel
                />
                <VisibleForPermission
                  permission={canAddNewEmployee}
                  permissions={grantedPermissions}
                >
                  <Button
                    className={classes.addButton}
                    type="addRoundedNew"
                    onClick={this.handleOpenAddUsers(isAdmin)}
                  >
                    {translations.addPeopleButton}
                  </Button>
                </VisibleForPermission>
              </div>
            </div>
          </div>
          <div>
            {!isArrayEmpty(people.results) ? (
              <GridTable
                className={classes.table}
                translations={translations}
                initialSort={ordering}
                headerActions={getHeaderActions(translations.columns, isUser)}
                headers={getTableHeaders(translations.columns, isUser)}
                rows={prepareTableData(
                  isAdmin,
                  auth,
                  grantedPermissions,
                  people.results,
                  userStatuses,
                  this.renderAvatar,
                  this.renderUserCheckbox,
                  this.renderDotsMenu,
                  this.renderUserStatus,
                  translations,
                  organizationSettings?.global_see_himself
                )}
                preHeaderActions={getPreHeaderActions(
                  this.renderCheckbox(
                    this.handleSelectAllChange,
                    selectAllChecked,
                    isCheckboxDisabled(people.results, auth, isAdmin)
                  ),
                  grantedPermissions
                )}
                hasLoadMore={hasNextPage(people)}
                onLoadMore={this.onLoadMore}
                onSort={this.onSort}
              />
            ) : (
              <NotificationCard content={translations.noSearchResultsMessage} />
            )}
            <CustomFormDrawer
              translations={translations}
              titleText={translations.editUser}
              isOpened={isEditUserDialogOpened}
              initialData={getInitialUserData(user, tracks, frameworks)}
              fields={getEditUserFields(
                user,
                isAdmin,
                isUserAdmin,
                isOwnProfile,
                isPremiumUser,
                isSubscriptionExpired
              )}
              roles={userRoles}
              positions={jobTitles}
              frameworks={prepareFrameworksWithTracks(frameworks)}
              tracks={prepareTracksWithLevels(tracks)}
              availableManagers={availableManagers}
              onClose={this.handleUserEditClose}
              onSave={this.onUpdateUser}
              hideDelete
              hasCancelButton
              isInitialValid
            />
            <CustomFormDrawer
              customContentClass={classes.tableContent}
              translations={translations.addUsersTable}
              titleText={getAddUsersInfo(
                translations.addUsersTable.title,
                selectedAddUsersOption
              )}
              isInitialValid={isUserImport}
              isOpened={isAddUsersDrawerOpened}
              initialData={getAddUsersInitialValues(
                auth,
                isAdmin,
                selectedAddUsersOption,
                organizationMentor,
                importedUsers
              )}
              fields={getAddUsersFields(
                translations.addUsersTable.description,
                auth,
                organizationMentor,
                selectedAddUsersOption,
                isAdmin,
                userRoles,
                reportToOptions,
                importedUsers,
                isUserImport,
                organizationSettings,
                getOrganizationSettings,
                this.saveUserInfoChanges,
                this.handleUserMentorChange
              )}
              organizationUser={organizationMentor}
              onAutoCompleteChange={this.handleUserMentorChange}
              onClose={this.handleCloseAddUsersDrawer}
              onSave={this.onAddUsers}
              isFullWidth
              hasCancelButton
              hideDelete
            />
            <BulkEditDialog
              translations={translations.bulkEditDialog}
              isOpened={dialogs.bulkEditDialogOpened}
              users={checkedUsers}
              jobTitles={jobTitles}
              roles={userRoles}
              frameworks={prepareFrameworksWithTracks(frameworks)}
              tracks={prepareTracksWithLevels(tracks)}
              categories={getCategoriesWithTags(tagCategories)}
              selectedBulkAction={selectedBulkAction}
              idleUsers={
                checkedUsers?.filter(
                  u => u.status === USER_STATUSES.ACTIVE_WITHOUT_ACCESS
                ) || []
              }
              organizationUser={organizationMentor}
              organizationSettings={organizationSettings}
              isCurrentUserAdmin={isAdmin}
              getOrganizationSettings={getOrganizationSettings}
              onCancel={this.handleBulkEditClose}
              onSave={this.handleBulkEditSave}
            />
            <TransferEmployeesDialog
              translations={translations.transferEmployeesDialog}
              isOpened={isTransferUsersDialogOpened}
              currentUserId={user?.id}
              users={usersToTransfer}
              organizationUser={organizationMentor}
              onClose={this.toggleTransferUsersDialog}
              onConfirm={this.onTransferUsers}
            />
            <AddUsersDialog
              translations={translations.addUsersDialog}
              isOpened={isAddUsersDialogOpened}
              isConfirmDisabled={isAddUsersDisabled}
              options={getAddUserOptions(translations.addUsersDialog.options)}
              selectedOption={selectedAddUsersOption}
              usersData={csvImport}
              onSelect={this.handleSelectAddUsersOption}
              onUpload={this.handleUploadUsers}
              onDeleteUpload={this.handleDeleteUpload}
              onCancel={this.handleCloseAddUsers}
              onConfirm={this.handleConfirmAddUsers(isUserImport)}
            />
            <AssignUsersDialog
              translations={translations.reactivateUserDialog}
              isOpened={isReactivateDialogOpened}
              allUsers={availableManagers}
              onCancel={this.handleToggleReactivate()}
              onSave={this.onReactivate}
              shouldResetSelection
            />
            <AlertDialog
              translations={translations.inviteUserDialog}
              isOpened={isInviteDialogOpened}
              onClose={this.handleToggleInvite()}
              onConfirm={this.onInvite}
            />
            <AlertDialog
              translations={translations.reinviteUserDialog}
              isOpened={isReinviteDialogOpened}
              onClose={this.handleToggleReinvite()}
              onConfirm={this.onReinvite}
            />
            <AlertDialog
              translations={translations.revokeAccessUserDialog}
              isOpened={isRevokeAccessDialogOpened}
              onClose={this.handleToggleRevokeAccess()}
              onConfirm={this.onRevokeAccess}
            />
            <AlertDialog
              translations={translations.deactivateUserDialog}
              isOpened={isDeactivateDialogOpened}
              onClose={this.handleToggleDeactivate()}
              onConfirm={this.onDeactivate}
            />
            <AlertDialog
              translations={translations.deleteUserDialog}
              isOpened={isDeleteDialogOpened}
              onClose={this.toggleDeleteUserDialog()}
              onConfirm={this.onDelete}
              isWarning
            />
            <AlertDialog
              translations={translations.changeExternalRoleDialog}
              isOpened={isChangeRoleDialogOpened}
              onClose={() => this.toggleChangeUserRoleDialog()}
              onConfirm={this.handleTransferExternalToEmployees}
            />
          </div>
        </div>
      )
    );
  }
}

PeoplePage.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  isPremiumUser: PropTypes.bool.isRequired,
  isSubscriptionExpired: PropTypes.bool.isRequired,
  people: PropTypes.object.isRequired,
  tagCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
  dialogs: PropTypes.object.isRequired,
  grantedPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  organizationSettings: PropTypes.shape({}).isRequired,
  jobTitles: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  frameworks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  tracks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getPeople: PropTypes.func.isRequired,
  pageQuickFilters: PropTypes.arrayOf(PropTypes.object).isRequired,
  setDialogVisibility: PropTypes.func.isRequired,
  getJobTitles: PropTypes.func.isRequired,
  getLevelsAndTracks: PropTypes.func.isRequired,
  getTagsCategories: PropTypes.func.isRequired,
  getPageQuickFilters: PropTypes.func.isRequired,
  updateCurrentUserInfo: PropTypes.func.isRequired,
  getOrganizationSettings: PropTypes.func.isRequired,
  clearJobTitles: PropTypes.func.isRequired,
  clearLevelsAndTracks: PropTypes.func.isRequired,
  clearTagsCategories: PropTypes.func.isRequired,
  clearPeople: PropTypes.func.isRequired,
  clearPageQuickFilters: PropTypes.func.isRequired,
};

export default withStyles(styles)(PeoplePage);
