import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const UpdateItem = ({ data }) => {
  return <Typography variant="body2">{data}</Typography>;
};

UpdateItem.propTypes = {
  data: PropTypes.string.isRequired,
};

export default UpdateItem;
