import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Fade, makeStyles } from '@material-ui/core';
import NotificationCard from '../notificationCard';
import CustomScrollBar from '../customScrollBar';
import BackgroundImage from '../../../assets/images/bck_leveling.png';
import { isObject, isObjectEmpty } from '../../../utility/helpers';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    height: 'calc(100vh - 164px)',
    position: 'relative',
    backgroundColor: primary.bluish9,
    padding: spacing(5, 9, 0),
  },
  rootNoData: {
    background: `${primary.white} url(${BackgroundImage}) center center / cover no-repeat`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  content: {
    height: 'calc(100% - 16px)',
  },
  previewWrapper: {
    boxSizing: 'border-box',
    backgroundColor: primary.white,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    padding: spacing(2),
  },
  noSelection: {
    backgroundColor: 'transparent',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    padding: 0,
  },
  noSelectionTitle: {
    width: 538,
  },
  noSelectionContent: {
    fontSize: 16,
    lineHeight: '24px',
    width: 538,
    marginTop: spacing(4),
  },
  scroll: {
    backgroundColor: primary.bluish7,
  },
  scrollY: {
    backgroundColor: primary.bluish9,
    top: 0,
    right: -24,
    height: '100%',
    width: 8,
  },
}));

const PreviewField = ({
  translations,
  component: Preview,
  data,
  isExpandedOnFirstOpen,
  handleIsExpandedOnFirstOpen,
  isExpandedOnFirstOpenFramework,
  handleIsExpandedOnFirstOpenFramework,
}) => {
  const classes = useStyles();
  const hasPreview = isObject(data) && !isObjectEmpty(data);

  return (
    <div
      className={classNames(classes.root, {
        [classes.rootNoData]: !hasPreview,
      })}
    >
      <Fade in={hasPreview} unmountOnExit>
        <div className={classNames(classes.content, classes.previewWrapper)}>
          <CustomScrollBar
            customScrollClass={classes.scroll}
            customScrollBarYClass={classNames(classes.scrollY)}
            passContentHeight
            verticalScroll
            removeScrollX
          >
            <div>
              <Preview
                translations={translations.preview}
                data={data?.value?.frameworks}
                isExpandedOnFirstOpen={isExpandedOnFirstOpen}
                toggleExpansion={handleIsExpandedOnFirstOpen}
                isExpandedOnFirstOpenFramework={isExpandedOnFirstOpenFramework}
                toggleExpansionFramework={handleIsExpandedOnFirstOpenFramework}
              />
            </div>
          </CustomScrollBar>
        </div>
      </Fade>
      <NotificationCard
        className={classes.noSelection}
        titleClass={classes.noSelectionTitle}
        contentClass={classes.noSelectionContent}
        title={translations.noSelection.title}
        titleVariant="h3"
        content={translations.noSelection.content}
        shouldFade={!hasPreview}
      />
    </div>
  );
};

PreviewField.defaultProps = {
  data: null,
};

PreviewField.propTypes = {
  translations: PropTypes.object.isRequired,
  component: PropTypes.elementType.isRequired,
  data: PropTypes.object,
};

export default PreviewField;
