import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Collapse,
  FormHelperText,
  Typography,
  makeStyles,
} from '@material-ui/core';
import RichTextPreview from '../richTextPreview';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    position: 'relative',
  },
  options: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  option: {
    cursor: 'pointer',
    boxSizing: 'border-box',
    border: `1px solid ${primary.bluish6}`,
    borderRadius: 8,
    display: 'flex',
    flexDirection: 'column',
    transition: 'border .3s ease',
    marginBottom: spacing(4),
    padding: spacing(4),
    '&:last-of-type': {
      marginBottom: 0,
    },
  },
  selected: {
    border: `1px solid ${primary.bluish1}`,
  },
  text: {
    fontSize: 16,
    lineHeight: '20px',
  },
  description: {
    marginTop: spacing(2),
  },
  subtitle: {
    fontSize: 16,
    lineHeight: '24px',
    color: primary.bluish1,
    marginBottom: spacing(4),
  },
}));

const SelectableListField = ({
  options,
  label,
  selected,
  hasError,
  errorMessage,
  onSelect,
}) => {
  const classes = useStyles();

  const handleSelect = option => () => {
    onSelect(option.id !== selected?.id ? option : null);
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.subtitle}>{label}</Typography>
      <div className={classes.options}>
        {options.map(option => {
          const isSelected = selected?.id === option.id;

          return (
            <div
              key={`option_item_${option.id}`}
              className={classNames(classes.option, {
                [classes.selected]: isSelected,
              })}
              onClick={handleSelect(option)}
            >
              <RichTextPreview
                className={classes.text}
                text={option.value.title}
              />
              <Collapse in={isSelected} unmountOnExit>
                <div className={classes.description}>
                  <RichTextPreview
                    className={classes.text}
                    text={option.value.description}
                  />
                </div>
              </Collapse>
            </div>
          );
        })}
        {hasError && errorMessage && (
          <FormHelperText error={hasError}>{errorMessage}</FormHelperText>
        )}
      </div>
    </div>
  );
};

SelectableListField.defaultProps = {
  selected: null,
  hasError: false,
  errorMessage: '',
};

SelectableListField.propTypes = {
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  selected: PropTypes.object,
  hasError: PropTypes.bool,
  errorMessage: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
};

export default SelectableListField;
