import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Typography, withStyles } from '@material-ui/core';
import CustomDateRange from '../../shared/customDateRange';
import NotificationCard from '../../shared/notificationCard';
import ReadLessMore from '../../shared/readLessMore';
import CustomFormDrawer from '../../shared/customFormDrawer';
import AlertDialog from '../../shared/alertDialog';
import CustomButton from '../../shared/customButton';
import NotesList from '../../shared/notesList';
import { ReactComponent as AnonymousIcon } from '../../../assets/icons/anonymous-user.svg';
import {
  showSuccessMessage,
  hasNextPage,
  parseDuplicateParameters,
  parseQueryParams,
} from '../../../utility/uiUtils';
import {
  isObjectEmpty,
  isUserDeactivated,
  isUserProfileAccessible,
} from '../../../utility/helpers';
import { sticky } from '../../../constants/helperCssRules';
import { onSaveCreatableTag } from '../../../utility/tagUtils';
import {
  getManageNoteFields,
  getManageNoteInitialData,
  prepareNoteForSave,
  getNoteFormTitle,
} from '../../../utility/notes';
import {
  PARAMS,
  PRIVATE_NOTES_DEFAULT_PARAMS,
  PAGE_WHITELISTED_PARAMS,
} from '../../../constants/pages';
import { DefaultFilterValues } from '../peopleInsightsPage/config';
import { GENERAL_CATEGORY_ID } from '../../../constants/tags';
import { AUTOMATION_ID } from '../../../constants/automationId';

const styles = ({ palette: { primary }, spacing }) => ({
  header: {
    ...sticky(primary.white, 105),
    padding: spacing(8, 0, 6, 0),
  },
  descriptionWrapper: {
    marginBottom: spacing(6),
  },
  filter: {
    width: 220,
  },
  iconWrapper: {
    display: 'flex',
    marginBottom: spacing(4),
  },
  icon: {
    width: 19,
    height: 16,
    marginRight: spacing(2),
    marginTop: 2,
  },
  loadMoreWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: spacing(4),
  },
  loadMoreButton: {
    border: `1px solid ${primary.bluish3}`,
    borderRadius: 44,
    color: primary.bluish4,
    lineHeight: '24px',
    justifySelf: 'center',
    padding: spacing(1, 4),
    maxHeight: 32,
  },
  form: {
    display: 'grid',
    gridColumnGap: 24,
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    gridTemplateAreas: `
      "note note"
      "tags ."
    `,
  },
  note: {
    gridArea: 'note',
    '& .ql-container': {
      height: 376,
    },
    '& > div': {
      height: 445,
    },
  },
  tags: {
    gridArea: 'tags',
  },
});

const { ranges } = DefaultFilterValues;
const { PAGE, START_AT, END_AT, START, END } = PARAMS;

class PrivateNotesPage extends PureComponent {
  state = {
    isInitialLoad: true,
    [PAGE]: PRIVATE_NOTES_DEFAULT_PARAMS[PAGE],
    period: ranges,
    isReadMore: true,
    noteId: null,
    userId: null,
    note: null,
    isManageNoteOpened: false,
    isDeleteDialogOpened: false,
  };

  componentDidMount() {
    this.getInitialData();
  }

  componentWillUnmount() {
    const { clearPrivateNotes, clearTagsCategories } = this.props;

    clearPrivateNotes();
    clearTagsCategories();
  }

  getInitialData = () => {
    const { location, getTagsCategories } = this.props;
    const params = parseQueryParams(
      location.search,
      PAGE_WHITELISTED_PARAMS.PRIVATE_NOTES
    );

    if (!isObjectEmpty(params)) {
      return this.setState(
        {
          period:
            params[START_AT] && params[END_AT]
              ? {
                  [START]: params[START_AT],
                  [END]: params[END_AT],
                }
              : ranges,
        },
        () => {
          return Promise.all([
            this.handlePageChange(false),
            getTagsCategories(),
          ]).then(() => {
            this.setState({ isInitialLoad: false });
          });
        }
      );
    }

    return Promise.all([this.handlePageChange(), getTagsCategories()]).then(
      () => {
        this.setState({ isInitialLoad: false });
      }
    );
  };

  handleOnPeriodChange = period => {
    this.setState(
      { [PAGE]: PRIVATE_NOTES_DEFAULT_PARAMS[PAGE], period },
      this.handlePageChange
    );
  };

  handlePageChange = (isLoadMore = false) => {
    const { location, history, getPrivateNotes } = this.props;
    const { page, period } = this.state;

    const params = {
      page,
      [START_AT]: period[START],
      [END_AT]: period[END],
    };

    const query = parseDuplicateParameters(params);

    if (location.search !== `?${query}`) {
      history.replace(`/my-private-notes/?${query}`);
    }

    return getPrivateNotes(isLoadMore, params);
  };

  onLoadMore = () => {
    return this.setState(
      prevState => ({ ...prevState, [PAGE]: prevState[PAGE] + 1 }),
      () => this.handlePageChange(true)
    );
  };

  handleToggleReadMore = () => {
    this.setState(prevState => ({
      isReadMore: !prevState.isReadMore,
    }));
  };

  onOpenEditNote = selectedNote =>
    this.setState({ isManageNoteOpened: true, note: selectedNote });

  onCloseManageNote = () => {
    this.setState({ isManageNoteOpened: false, note: null });
  };

  handleSaveNote = values => {
    const { translations, editNote } = this.props;
    const { note } = this.state;

    const noteData = prepareNoteForSave(values, true, note.user);

    return editNote(noteData).then(() => {
      showSuccessMessage(translations.manageNotesDialog.successMessages.edit);
      this.setState(
        { [PAGE]: PRIVATE_NOTES_DEFAULT_PARAMS[PAGE] },
        this.handlePageChange
      );
    });
  };

  handleOpenDelete = selectedNote =>
    this.setState({ isDeleteDialogOpened: true, note: selectedNote });

  handleDelete = () => this.setState({ isDeleteDialogOpened: true });

  handleCloseDelete = () => this.setState({ isDeleteDialogOpened: false });

  onDelete = () => {
    const { translations, deleteNote } = this.props;
    const { note } = this.state;

    return deleteNote(note.id)
      .then(() => {
        showSuccessMessage(translations.noteDeleteDialog.deleteSuccess);
      })
      .finally(() =>
        this.setState(
          {
            [PAGE]: PRIVATE_NOTES_DEFAULT_PARAMS[PAGE],
            isManageNoteOpened: false,
            isDeleteDialogOpened: false,
            note: null,
          },
          this.handlePageChange
        )
      );
  };

  onCreateTag = async tagName => {
    const { addMultipleTags } = this.props;
    const tags = await onSaveCreatableTag(tagName);
    const [tag] = tags.create;

    addMultipleTags({ categoryId: GENERAL_CATEGORY_ID, tags });

    return tag;
  };

  render() {
    const {
      classes,
      translations,
      auth,
      organizationSettings,
      privateNotes,
      categories,
    } = this.props;
    const {
      period,
      isReadMore,
      isDeleteDialogOpened,
      isManageNoteOpened,
      isInitialLoad,
      note,
    } = this.state;

    const noteExist = privateNotes?.count > 0;
    const isRangeDefault =
      !isObjectEmpty(period) &&
      period.start === ranges.start &&
      period.end === ranges.end;
    const hasLoadMore = hasNextPage(privateNotes);

    return (
      <div>
        <div className={classes.header}>
          <div className={classes.descriptionWrapper}>
            <div className={classes.iconWrapper}>
              <AnonymousIcon className={classes.icon} />
              <Typography variant="h4">{translations.subtitle}</Typography>
            </div>
            <ReadLessMore
              translations={translations}
              isReadMore={isReadMore}
              toggleReadMore={this.handleToggleReadMore}
            >
              <Typography variant="body2">
                {translations.descriptionSections.first}
              </Typography>
              <Typography variant="body2">
                {translations.descriptionSections.second}
              </Typography>
              <Typography variant="body2">
                {translations.descriptionSections.third}
              </Typography>
              <Typography variant="body2">
                {translations.descriptionSections.fourth}
              </Typography>
            </ReadLessMore>
          </div>
          <CustomDateRange
            className={classes.filter}
            initialRange={{
              startDate: period[START],
              endDate: period[END],
            }}
            startAtKey={START}
            endAtKey={END}
            onChange={this.handleOnPeriodChange}
          />
        </div>
        <div>
          {noteExist && (
            <NotesList
              translations={translations.note}
              currentUser={auth}
              notes={privateNotes.results}
              canAccessUserProfile={isUserProfileAccessible(
                auth,
                organizationSettings.global_see_himself
              )}
              checkIsDisabledEdit={isUserDeactivated}
              onEdit={this.onOpenEditNote}
              onDelete={this.handleOpenDelete}
              isPrivateOnly
            />
          )}
          {hasLoadMore && (
            <div className={classes.loadMoreWrapper}>
              <CustomButton
                id={AUTOMATION_ID.LOAD_MORE_BUTTON}
                type="addWithTextRounded"
                className={classes.loadMoreButton}
                onClick={this.onLoadMore}
              >
                {translations.loadMore}
              </CustomButton>
            </div>
          )}
          <NotificationCard
            title={isRangeDefault ? translations.noResultsTitle : ''}
            content={
              isRangeDefault
                ? translations.noResultsMessage
                : translations.noSearchResultsMessage
            }
            shouldFade={!isInitialLoad && !noteExist}
          />
        </div>
        <AlertDialog
          translations={translations.noteDeleteDialog}
          isOpened={isDeleteDialogOpened}
          onClose={this.handleCloseDelete}
          onConfirm={this.onDelete}
          isWarning
        />
        <CustomFormDrawer
          customFormClass={classes.form}
          translations={translations.manageNotesDialog}
          getTitle={getNoteFormTitle}
          initialData={getManageNoteInitialData(note, true)}
          isOpened={isManageNoteOpened}
          fields={getManageNoteFields(classes, this.onCreateTag)}
          categories={categories}
          onClose={this.onCloseManageNote}
          onSave={this.handleSaveNote}
          onDelete={this.handleDelete}
          hasCancelButton
          isMiddleWidth
          isInitialValid
        />
      </div>
    );
  }
}

PrivateNotesPage.propTypes = {
  classes: PropTypes.object.isRequired,
  translations: PropTypes.object.isRequired,
  privateNotes: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  organizationSettings: PropTypes.object.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  editNote: PropTypes.func.isRequired,
  deleteNote: PropTypes.func.isRequired,
  addMultipleTags: PropTypes.func.isRequired,
  getTagsCategories: PropTypes.func.isRequired,
  clearTagsCategories: PropTypes.func.isRequired,
};

export default withStyles(styles)(PrivateNotesPage);
