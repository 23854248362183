export const getOptions = (options, parser, color) =>
  options.map(option => ({
    ...option,
    value: option[parser.value],
    label: option[parser.label],
    color: color || option?.color || option?.category?.color,
    isDisabled: option.isDisabled,
    ...(option.action ? { action: option.action } : []),
  }));

export const getParsedValueWithRange = (options, value) => {
  const optionsAsDict = options?.reduce((acc, curr) => {
    return {
      ...acc,
      [curr.value]: curr,
    };
  }, {});

  return value
    ? value?.reduce((acc, val) => {
        return optionsAsDict[val.id]
          ? [...acc, { ...val, value: val.id }]
          : acc;
      }, [])
    : [];
};
