import { useState, useCallback, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Typography, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import PageContainer from '../../shared/pageContainer';
import CustomButton from '../../shared/customButton';
import NotificationCard from '../../shared/notificationCard';
import AlertDialog from '../../shared/alertDialog';
import WarningDialog from '../../shared/warningDialog';
import Search from '../../shared/search';
import GridTable from '../../shared/gridTable';
import DotsMenu from '../../shared/dotsMenu';
import ChooseNameDialog from '../../shared/chooseNameDialog';
import PreviewAttributeDialog from '../../shared/previewAttributeDialog';
import ReadLessMore from '../../shared/readLessMore';
import ActionButton from '../../shared/actionButton';
import Filters from '../../shared/filters';
import ArrowTooltip from '../../shared/arrowTooltip';
import CustomFormDrawer from '../../shared/customFormDrawer';
import SelectField from '../../shared/selectField';
import TextBoxWithTooltip from '../../shared/textBoxWithTooltip';
import Tooltip from '../../shared/tooltip';
import { ReactComponent as EditIcon } from '../../../assets/icons/edit-dark.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/delete-outlined.svg';
import { ReactComponent as DuplicateIcon } from '../../../assets/icons/content_copy.svg';
import { ReactComponent as TextReplyIcon } from '../../../assets/icons/text_snippet.svg';
import {
  setAttribute,
  getAttribute,
  clearAttribute,
  reorderQuestions,
  deleteAllAttributeQuestions,
  deleteAttributeQuestion,
  setAttributeQuestions,
  updateAttribute,
  updateAttributeQuestion,
  updateAttributeQuestions,
  createQuestion,
} from '../../../store/modules/attribute';
import {
  setAttributes,
  getAttributes,
  clearAttributes,
} from '../../../store/modules/attributes';
import {
  getTagsCategories,
  getPageQuickFilters,
  addMultipleTags,
  clearTagsCategories,
  clearPageQuickFilters,
} from '../../../store/modules/tags';
import { attributesSelector } from '../../../store/selectors/attributesSelector';
import { attributeSelector } from '../../../store/selectors/attributeSelector';
import { tagsSelector } from '../../../store/selectors/tagsSelector';
import {
  showSuccessMessage,
  parseDuplicateParameters,
  parseQueryParams,
  getPersonalAttributeTooltipText,
  hasNextPage,
} from '../../../utility/uiUtils';
import { hasSelectedFilters } from '../../shared/filters/config';
import http from '../../../utility/http';
import {
  trimString,
  isArrayEmpty,
  isArray,
  checkUserRole,
  isObjectEmpty,
  getItemById,
} from '../../../utility/helpers';
import {
  EVENT_ACTION_TYPES,
  tagManagerDataLayer,
} from '../../../utility/tagManager';
import { useTranslations } from '../../../utility/useTranslations';
import { onSaveCreatableTag } from '../../../utility/tagUtils';
import { validateFreemiumAction } from '../../../utility/subscriptionHelper';
import { getAttributesViewOptions } from '../../../utility/attribute';
import {
  API_ATTRIBUTES,
  api_duplicate_attribute,
  api_attribute_usage,
  API_IMPORT_ATTRIBUTES,
} from '../../../constants/apiRoutes';
import {
  APP_PAGES,
  PARAMS,
  PAGE_WHITELISTED_PARAMS,
  ATTRIBUTES_DEFAULT_PARAMS,
} from '../../../constants/pages';
import { GENERAL_CATEGORY_ID, PLACEMENTS } from '../../../constants/tags';
import { ACTION_BUTTON_TYPES } from '../../shared/actionButton/config';
import { sticky } from '../../../constants/helperCssRules';
import { ROLES } from '../../../constants/rolesAndPermissionList';
import { FREEMIUM_LIMIT_TYPES } from '../../../constants/appConfig';
import {
  INITIAL_SORT,
  ATTRIBUTE_NAME_FIELD,
  ATTRIBUTE_SHORT_NAME_FIELD,
  SAVE,
  QUESTIONS_ACTIONS,
  getNewAttributeData,
  getTableHeaders,
  prepareTableData,
  getHeaderActions,
  getAttributeFields,
  getAttributeQuestionsFields,
  getPageFilters,
  getAttributeTypes,
  getAttributeDeleteTranslations,
  prepareAttributeData,
  getNoResultsLabels,
} from './config';
import { ReactComponent as PlusIconWhite } from '../../../assets/icons/plus-icon-white.svg';

const useStyles = makeStyles(
  ({ breakpoints, palette: { primary }, spacing }) => ({
    form: {
      display: 'grid',
      gridColumnGap: 24,
      gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
      gridTemplateAreas: `
        "name code"
        "types tags"
        "isPrivate isActive"
        "color createdBy"
        "description description"
      `,
    },
    name: {
      gridArea: 'name',
    },
    code: {
      gridArea: 'code',
    },
    attributeTypes: {
      gridArea: 'types',
    },
    tags: {
      gridArea: 'tags',
    },
    isPrivate: {
      gridArea: 'isPrivate',
    },
    isActive: {
      gridArea: 'isActive',
      width: '100%',
    },
    color: {
      gridArea: 'color',
    },
    createdBy: {
      gridArea: 'createdBy',
    },
    description: {
      gridArea: 'description',
      '& .ql-container': {
        height: 376,
      },
      '& > div': {
        height: 445,
      },
    },
    stickyHeader: {
      ...sticky(primary.white, 105),
      width: '100%',
    },
    actionContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: spacing(4),
    },
    pageDescription: {
      padding: spacing(8, 0, 6, 0),
    },
    rightSide: {
      display: 'flex',
      alignSelf: 'center',
      alignItems: 'center',
      marginLeft: 'auto',
    },
    search: {
      width: 234,
    },
    addAttribute: {
      marginLeft: spacing(2),
      width: 'max-content',
    },
    filters: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    table: {
      '& .grid-table-header': {
        ...sticky(primary.bluish8, 235, 998),
      },

      '& .grid-table-header$readMoreActive': {
        top: 289,
      },

      '& .actions-dots-menu': {
        padding: spacing(3, 1),
        overflow: 'visible',

        [breakpoints.up('xLg')]: {
          padding: spacing(3),
        },
      },
    },
    readMoreActive: {},
    questionsMenu: {
      cursor: 'pointer',
    },
    tooltipText: {
      lineHeight: '20px',
      color: primary.bluish2,
    },
    attributeNameWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    attributeName: {
      width: 'fit-content',
      maxWidth: '100%',
    },
    openEndedAttributeIcon: {
      cursor: 'default',
      display: 'flex',
      alignItems: 'center',
      flexShrink: 0,
      marginLeft: spacing(1),
      marginTop: spacing(-0.25),
      '& svg': {
        '& g, & path': {
          fill: primary.bluish1,
        },
      },
      '& g, & path': {
        fill: primary.bluish1,
      },
    },
    privateAttribute: {
      display: 'flex',
    },
    tableActions: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    viewReport: {
      marginRight: spacing(2),
    },
    reportIcon: {
      width: 16,
      height: 16,
    },
    checkbox: {
      width: '100%',
      height: '100%',
    },
    view: {
      marginLeft: spacing(2),
      minWidth: 190,
      width: 190,
    },
    viewMenu: {
      zIndex: 1000,
    },
  })
);

const AttributesPage = ({
  location,
  navigate,
  dispatch,
  dialogs,
  auth,
  organizationSettings,
  getOrganizationSettings,
  setDialogVisibility,
  ...rest
}) => {
  const { page: defaultPage } = ATTRIBUTES_DEFAULT_PARAMS;
  const { PAGE, ORDERING, SEARCH, TYPE, TAGS, IS_ACTIVE } = PARAMS;
  const classes = useStyles();
  const translations = useTranslations(APP_PAGES.ATTRIBUTES);
  const {
    confirmAttributeDeletionOpened,
    chooseNameDialogOpened,
    previewAttributeDialogOpened,
    warningDialogOpened,
  } = dialogs;

  const { attributes } = useSelector(attributesSelector);
  const { attribute } = useSelector(attributeSelector);
  const { categories, pageQuickFilters } = useSelector(tagsSelector);

  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingImport, setIsLoadingImport] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState('');
  const [ordering, setOrdering] = useState(INITIAL_SORT);
  const [selectedFilters, setSelectedFilters] = useState({
    [TYPE]: [],
    [TAGS]: [],
  });
  const [isActiveView, setIsActiveView] = useState(true);
  const [attributeId, setAttributeId] = useState(null);
  const [attributeName, setAttributeName] = useState('');
  const [attributeShortName, setAttributeShortName] = useState('');
  const [attributeUsedCount, setAttributeUsedCount] = useState(0);
  const [attributeTimesUsed, setAttributeTimesUsed] = useState(null);
  const [attributeQuestionNumber, setAttributeQuestionNumber] = useState(null);
  const [assignedJobTitles, setAssignedJobTitles] = useState({});
  const [assignedLevels, setAssignedLevels] = useState({});
  const [isReadMore, setIsReadMore] = useState(false);
  const [isCreateDrawerOpened, setIsCreateDrawerOpened] = useState(false);
  const [isQuestionsDrawerOpened, setIsQuestionsDrawerOpened] = useState(false);
  const [isEditAttribute, setIsEditAttribute] = useState(false);

  const isAdmin = checkUserRole(auth.role, ROLES.ADMIN);
  const gridHeaders = getTableHeaders(translations.columns);
  const gridHeaderActions = getHeaderActions(translations.columns);
  const types = getAttributeTypes(translations.attributeTypes);
  const attributeFilters = [{ id: null, name: translations.all }, ...types];
  const showLoadMore = hasNextPage(attributes);
  const filterIsActive = hasSelectedFilters(selectedFilters);
  const attributeData = prepareAttributeData(attribute);
  const attributesViewOptions = getAttributesViewOptions(
    translations.attributesViewOptions
  );
  const noResultsLabels = getNoResultsLabels(
    translations.noData,
    search,
    filterIsActive,
    isActiveView
  );

  const handlePageChange = ({
    newPage,
    newOrdering,
    newFilters,
    newSearch,
    view,
    isLoadMore,
  }) => {
    const params = {
      [PAGE]: newPage,
      [ORDERING]: newOrdering?.sortKey,
      [IS_ACTIVE]: view,
      ...newFilters,
      ...(newSearch ? { [SEARCH]: newSearch } : {}),
    };

    const query = parseDuplicateParameters(params);

    if (location.search !== `?${query}`) {
      navigate(`/attributes/?${query}`, { replace: true });
    }

    return getAttributes(dispatch, isLoadMore, params);
  };

  const getInitialData = async () => {
    try {
      const columns = getTableHeaders(translations.columns);
      const params = parseQueryParams(
        location.search,
        PAGE_WHITELISTED_PARAMS.ATTRIBUTES
      );

      if (!isObjectEmpty(params)) {
        const [ascending, descending] = params[ORDERING].split('-');
        const isAscending = !!ascending;
        const initialOrdering = {
          column: getItemById(
            columns,
            isAscending ? ascending : descending,
            'sortAs'
          )?.rowKey,
          asc: isAscending,
          sortKey: params[ORDERING],
        };
        const initialSearch = params[SEARCH]?.toString() || '';
        const initialFilters = {
          [TYPE]: params[TYPE] ? [params[TYPE]] : [],
          [TAGS]: params[TAGS]
            ? [...(isArray(params[TAGS]) ? params[TAGS] : [params[TAGS]])]
            : [],
        };
        const initialView = JSON.parse(params[IS_ACTIVE]);

        setIsActiveView(initialView);
        setOrdering(initialOrdering);
        setSearch(initialSearch);
        setSelectedFilters(initialFilters);
        await Promise.all([
          handlePageChange({
            newPage: page,
            newOrdering: initialOrdering,
            newFilters: initialFilters,
            newSearch: initialSearch,
            view: initialView,
          }),
          getTagsCategories(dispatch),
          getPageQuickFilters(dispatch, PLACEMENTS.ATTRIBUTE.id),
        ]);
      } else {
        await Promise.all([
          handlePageChange({
            newPage: page,
            newOrdering: ordering,
            newFilters: selectedFilters,
            newSearch: search,
            view: isActiveView,
          }),
          getTagsCategories(dispatch),
          getPageQuickFilters(dispatch, PLACEMENTS.ATTRIBUTE.id),
        ]);
      }
    } finally {
      setIsInitialLoad(false);
    }
  };

  const cleanup = useCallback(() => {
    dispatch(clearAttributes());
    dispatch(clearAttribute());
    dispatch(clearTagsCategories());
    dispatch(clearPageQuickFilters());
  }, [dispatch]);

  useEffect(() => {
    getInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return cleanup;
  }, [cleanup]);

  const onChangeView = async view => {
    try {
      setIsActiveView(view);
      setIsLoading(true);
      setPage(defaultPage);
      await handlePageChange({
        newPage: defaultPage,
        newSearch: search,
        newOrdering: ordering,
        newFilters: selectedFilters,
        view,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onSearch = async text => {
    const searchTerm = trimString(text);

    try {
      setPage(defaultPage);
      setSearch(searchTerm);
      setIsLoading(true);
      await handlePageChange({
        newPage: defaultPage,
        newSearch: searchTerm,
        newOrdering: ordering,
        newFilters: selectedFilters,
        view: isActiveView,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onSort = newOrdering => {
    setPage(defaultPage);
    setOrdering(newOrdering);
    handlePageChange({
      newPage: defaultPage,
      newSearch: search,
      newOrdering,
      newFilters: selectedFilters,
      view: isActiveView,
    });
  };

  const onFiltersChange = async newFilters => {
    try {
      setIsLoading(true);
      setPage(defaultPage);
      setSelectedFilters(newFilters);
      handlePageChange({
        newPage: defaultPage,
        newSearch: search,
        newOrdering: ordering,
        newFilters,
        view: isActiveView,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onLoadMore = () => {
    setPage(page + 1);
    handlePageChange({
      newPage: page + 1,
      newSearch: search,
      newOrdering: ordering,
      newFilters: selectedFilters,
      view: isActiveView,
      isLoadMore: true,
    });
  };

  const onAddAttribute = () => setIsCreateDrawerOpened(true);

  const onEditClick = async currentAttribute => {
    await getAttribute(dispatch, currentAttribute.id);

    setIsCreateDrawerOpened(true);
    setIsEditAttribute(true);
    setAttributeId(currentAttribute.id);
    setAttributeTimesUsed(currentAttribute.times_used);
  };

  const onCloseCreateDrawer = () => {
    setIsCreateDrawerOpened(false);
    setIsEditAttribute(false);
    setAttributeId(null);
    setAttributeTimesUsed(null);
    dispatch(clearAttribute());
  };

  const onCloseQuestionsDrawer = () => {
    if (
      attributeQuestionNumber !== null &&
      attributeQuestionNumber !== attribute.questions.length
    ) {
      handlePageChange({
        newPage: page,
        newOrdering: ordering,
        newFilters: selectedFilters,
        newSearch: search,
        view: isActiveView,
      });
    }
    setIsQuestionsDrawerOpened(false);
    setAttributeId(null);
    setAttributeQuestionNumber(null);
    dispatch(clearAttribute());
  };

  const onPreview = row =>
    getAttribute(dispatch, row.id).then(() => {
      setDialogVisibility({
        dialogName: 'previewAttributeDialog',
        opened: true,
      });
    });

  const onClosePreview = () => {
    dispatch(clearAttribute());
    setDialogVisibility({
      dialogName: 'previewAttributeDialog',
      opened: false,
    });
  };

  const closeDeleteAttributeDialog = () => {
    setAttributeId(null);
    setAttributeUsedCount(0);
    setAssignedJobTitles({});
    setAssignedLevels({});
    setDialogVisibility({
      dialogName: 'confirmAttributeDeletion',
      opened: false,
    });
  };

  const onConfirmDeleteAttribute = currentAttributeId => async () => {
    try {
      await http.delete(`${API_ATTRIBUTES}${currentAttributeId}/`);
      setPage(defaultPage);
      handlePageChange({
        newPage: defaultPage,
        newOrdering: ordering,
        newFilters: selectedFilters,
        newSearch: search,
        view: isActiveView,
      });
      closeDeleteAttributeDialog();
      onCloseCreateDrawer();
      onCloseQuestionsDrawer();
      showSuccessMessage(translations.deleteAttributeDialog.deleteSuccess);
    } catch {
      closeDeleteAttributeDialog();
    }
  };

  const onDuplicate = currentAttribute => {
    setAttributeId(currentAttribute.id);
    setAttributeName(currentAttribute.name);
    setAttributeShortName(currentAttribute.code);
    setDialogVisibility({
      dialogName: 'chooseNameDialog',
      opened: true,
    });
  };

  const onCloseDuplicate = () => {
    setAttributeId(null);
    setAttributeName('');
    setAttributeShortName('');
    setDialogVisibility({
      dialogName: 'chooseNameDialog',
      opened: false,
    });
  };

  const onDuplicateAttribute = async (name, code) => {
    try {
      await http.post(api_duplicate_attribute(attributeId), {
        name,
        code,
      });
      setPage(defaultPage);
      handlePageChange({
        newPage: defaultPage,
        newOrdering: ordering,
        newFilters: selectedFilters,
        newSearch: search,
        view: isActiveView,
      });
    } finally {
      onCloseDuplicate();
    }
  };

  const openAddQuestionsDrawer = async attData => {
    await getAttribute(dispatch, attData.id);
    setIsCreateDrawerOpened(false);
    setIsQuestionsDrawerOpened(true);
    setAttributeId(attData.id);
    setAttributeQuestionNumber(attData.question_number);
  };

  const onCreateAttribute = attData => {
    const saveType = attData.save_type;
    delete attData.save_type;
    const updatedData = {
      ...attData,
      description: attData.description || undefined,
    };
    const { AddNewAttribute } = EVENT_ACTION_TYPES;
    return http.post(API_ATTRIBUTES, updatedData).then(({ data }) => {
      tagManagerDataLayer(
        AddNewAttribute.action,
        AddNewAttribute.name,
        attData.name
      );
      if (saveType === SAVE) {
        setIsCreateDrawerOpened(false);
        setPage(defaultPage);
        return handlePageChange({
          newPage: defaultPage,
          newOrdering: ordering,
          newFilters: selectedFilters,
          newSearch: search,
          view: isActiveView,
        });
      }
      const trimmedData = { id: data.id, question_number: null };

      openAddQuestionsDrawer(trimmedData).then(() => {
        setPage(defaultPage);
        handlePageChange({
          newPage: defaultPage,
          newOrdering: ordering,
          newFilters: selectedFilters,
          newSearch: search,
          view: isActiveView,
        });
      });
    });
  };

  const onAttributeEditSave = updatedData => {
    const isSaveType = updatedData.save_type === SAVE;
    const { isDirty } = updatedData;

    delete updatedData.save_type;
    delete updatedData.isDirty;
    const attributeInfo = {
      ...updatedData,
      description: updatedData.description || null,
    };

    if (isDirty) {
      return updateAttribute(dispatch, updatedData.id, attributeInfo).then(
        () => {
          if (isSaveType) {
            setIsCreateDrawerOpened(false);
            setAttributeId(null);
            setAttributeTimesUsed(null);
            setPage(defaultPage);
            dispatch(clearAttribute());
            return handlePageChange({
              newPage: defaultPage,
              newOrdering: ordering,
              newFilters: selectedFilters,
              newSearch: search,
              view: isActiveView,
            });
          }
          openAddQuestionsDrawer(updatedData).then(() => {
            setPage(defaultPage);
            handlePageChange({
              newPage: defaultPage,
              newOrdering: ordering,
              newFilters: selectedFilters,
              newSearch: search,
              view: isActiveView,
            });
          });
        }
      );
    }
    if (!isSaveType) {
      return openAddQuestionsDrawer(updatedData);
    }
    setIsCreateDrawerOpened(false);
    setAttributeId(null);
    setAttributeTimesUsed(null);
    dispatch(clearAttribute());
    return Promise.resolve();
  };

  const onCreateTag = async tagName => {
    const tags = await onSaveCreatableTag(tagName);
    const [tag] = tags.create;

    dispatch(addMultipleTags({ categoryId: GENERAL_CATEGORY_ID, tags }));

    return tag;
  };

  const handleQuestionsClick = async currentAttribute => {
    await getAttribute(dispatch, currentAttribute.id);
    setIsQuestionsDrawerOpened(true);
    setAttributeId(currentAttribute.id);
    setAttributeQuestionNumber(attribute.question_number);
  };

  const onDeleteAttribute = async ({ id }) => {
    const { data } = await http.get(api_attribute_usage(id));
    const isAssignedToJobTitles = !isArrayEmpty(data.positions);
    const isAssignedToLevels = !isArrayEmpty(data.levels);

    if (isAssignedToJobTitles || isAssignedToLevels) {
      setAttributeId(id);
      if (isAssignedToJobTitles) {
        setAssignedJobTitles({
          count: data.positions.length,
          results: [...data.positions],
        });
      }

      if (isAssignedToLevels) {
        setAssignedLevels({
          count: data.levels.length,
          results: [...data.levels],
        });
      }
      setDialogVisibility({
        dialogName: 'warningDialog',
        opened: true,
      });
    } else {
      setAttributeId(id);
      setAttributeUsedCount(data.inquiries.length);
      setDialogVisibility({
        dialogName: 'confirmAttributeDeletion',
        opened: true,
      });
    }
  };

  const getMenuItems = (
    isManageable,
    currentAttribute,
    questionOption,
    hasPreview
  ) => {
    const actionName = questionOption.name;

    return [
      {
        type: 'edit',
        label: translations.menuItemLabels.edit,
        shouldItemRender: () => isManageable,
        action: () => onEditClick(currentAttribute),
        icon: EditIcon,
      },
      {
        type: 'duplicate',
        label: translations.menuItemLabels.duplicate,
        shouldItemRender: () => true,
        action: () =>
          validateFreemiumAction(
            onDuplicate,
            FREEMIUM_LIMIT_TYPES.ATTRIBUTES,
            organizationSettings,
            setDialogVisibility,
            getOrganizationSettings
          )(currentAttribute),
        icon: DuplicateIcon,
      },
      {
        type: 'delete',
        label: translations.menuItemLabels.delete,
        shouldItemRender: () => isManageable,
        action: () => {
          setAttributeId(currentAttribute.id);
          onDeleteAttribute({ id: currentAttribute.id });
        },
        icon: DeleteIcon,
      },
      {
        type: translations.menuItemLabels[actionName],
        label: translations.menuItemLabels[actionName],
        shouldItemRender: () => !hasPreview,
        action: () => handleQuestionsClick(currentAttribute),
        icon: questionOption.icon,
      },
    ];
  };

  const onCloseWarningDialog = () => {
    setAssignedJobTitles({});
    setAssignedLevels({});
    setAttributeId(null);
    setDialogVisibility({
      dialogName: 'warningDialog',
      opened: false,
    });
  };

  const onToggleReadMore = () => setIsReadMore(prevReadMore => !prevReadMore);

  const handleCreateQuestion = async questionData => {
    await createQuestion(dispatch, questionData);
    setPage(defaultPage);
    handlePageChange({
      newPage: defaultPage,
      newOrdering: ordering,
      newFilters: selectedFilters,
      newSearch: search,
      view: isActiveView,
    });
  };

  const chooseQuestionOption = (isManagable, currentAttribute) => {
    if (isManagable && currentAttribute.is_editable) {
      if (currentAttribute.question_number === 0) {
        return QUESTIONS_ACTIONS.CREATE;
      }
      return QUESTIONS_ACTIONS.EDIT;
    }
    return QUESTIONS_ACTIONS.PREVIEW;
  };

  const onImportAttributes = async () => {
    try {
      setIsLoadingImport(true);
      const { data } = await http.get(API_IMPORT_ATTRIBUTES, {
        params: ATTRIBUTES_DEFAULT_PARAMS,
      });
      dispatch(setAttributes(data));
    } finally {
      setIsLoadingImport(false);
    }
  };

  const handleGetAttribute = id => getAttribute(dispatch, id);

  const handleSetAttribute = data => dispatch(setAttribute(data));

  const handleUpdateAttribute = (id, data) =>
    updateAttribute(dispatch, id, data);

  const handleSetAttributeQuestions = (id, data) =>
    setAttributeQuestions(dispatch, id, data);

  const handleUpdateAttributeQuestions = (id, data) =>
    updateAttributeQuestions(dispatch, id, data);

  const handleUpdateAttributeQuestion = (id, data) =>
    updateAttributeQuestion(dispatch, id, data);

  const handleReorderQuestions = data => reorderQuestions(dispatch, data);

  const handleDeleteAllAttributeQuestions = id =>
    deleteAllAttributeQuestions(dispatch, id);

  const handleDeleteAttributeQuestion = questionId =>
    deleteAttributeQuestion(dispatch, questionId);

  const renderActions = (isManageable, currentAttribute) => {
    const questionOption = chooseQuestionOption(isManageable, currentAttribute);
    const hasPreview = questionOption.name === QUESTIONS_ACTIONS.PREVIEW.name;

    return (
      <div className={classes.tableActions}>
        {currentAttribute.question_number ? (
          <ActionButton
            type={ACTION_BUTTON_TYPES.PREVIEW_DESCRIPTION}
            tooltipText={
              translations.menuItemLabels[QUESTIONS_ACTIONS.PREVIEW.name]
            }
            onClickHandler={() => onPreview(currentAttribute)}
          />
        ) : null}
        {isAdmin &&
        currentAttribute.is_active &&
        currentAttribute.times_used ? (
          <ActionButton
            className={classes.viewReport}
            iconClass={classes.reportIcon}
            type={ACTION_BUTTON_TYPES.ATTRIBUTE_REPORT}
            tooltipText={translations.viewReport}
            linkProps={{
              to: `/reports/attribute`,
              state: { attribute: currentAttribute },
            }}
            isLink
          />
        ) : null}
        <DotsMenu
          className={classes.dotsMenu}
          menuItems={getMenuItems(
            isManageable,
            currentAttribute,
            questionOption,
            hasPreview
          )}
        />
      </div>
    );
  };

  const renderTooltipText = createdBy => (
    <Typography variant="body2" className={classes.tooltipText}>
      {getPersonalAttributeTooltipText(
        translations.personalAttributeTooltip,
        createdBy
      )}
    </Typography>
  );

  const renderPersonalAttribute = createdBy => (
    <div className={classes.privateAttribute}>
      <ArrowTooltip position="top" tooltipLabel={renderTooltipText(createdBy)}>
        <Typography>{translations.personal}</Typography>
      </ArrowTooltip>
    </div>
  );

  const renderName = currentAttribute => (
    <div className={classes.attributeNameWrapper}>
      <TextBoxWithTooltip
        className={classes.attributeName}
        variant="subtitle1"
        text={currentAttribute.name}
      />
      {currentAttribute.text_reply_questions && (
        <Tooltip
          customIconClass={classes.openEndedAttributeIcon}
          text={translations.openEndedAttribute}
          icon={TextReplyIcon}
        />
      )}
    </div>
  );

  return (
    <PageContainer
      {...rest}
      translations={translations}
      location={location}
      navigate={navigate}
      auth={auth}
      organizationSettings={organizationSettings}
      shouldPassProps={false}
      isFullWidthContent
    >
      {!isInitialLoad && (
        <div>
          <div className={classes.stickyHeader}>
            <div className={classes.pageDescription}>
              <ReadLessMore
                translations={translations}
                isReadMore={isReadMore}
                toggleReadMore={onToggleReadMore}
              >
                <Typography variant="body2">
                  {translations.descriptionPartOne}
                </Typography>
                <Typography variant="body2">
                  {translations.descriptionPartTwo}
                </Typography>
                <Typography variant="body2">
                  {translations.descriptionPartThree}
                </Typography>
              </ReadLessMore>
            </div>
            <div className={classes.actionContainer}>
              <div className={classes.filters}>
                <Filters
                  translations={translations.filters}
                  selectedFilters={selectedFilters}
                  filters={getPageFilters(
                    translations.filters.labels,
                    attributeFilters,
                    categories,
                    pageQuickFilters
                  )}
                  onApplyFilters={onFiltersChange}
                />
              </div>
              <div className={classes.rightSide}>
                <Search
                  className={classes.search}
                  placeholder={translations.searchPlaceholder}
                  value={search}
                  onChange={onSearch}
                />
                <SelectField
                  className={classes.view}
                  menuClass={classes.viewMenu}
                  value={isActiveView}
                  options={attributesViewOptions}
                  parser={{ value: 'value', label: 'name' }}
                  onChange={onChangeView}
                  isSearchDisabled
                  shouldRemoveLabel
                />
                <CustomButton
                  className={classes.addAttribute}
                  type="addRoundedNew"
                  onClick={validateFreemiumAction(
                    onAddAttribute,
                    FREEMIUM_LIMIT_TYPES.ATTRIBUTES,
                    organizationSettings,
                    setDialogVisibility,
                    getOrganizationSettings
                  )}
                >
                  {translations.addAttribute}
                </CustomButton>
              </div>
            </div>
          </div>
          <div>
            {isArray(attributes.results) &&
              !isArrayEmpty(attributes.results) && (
                <GridTable
                  className={classes.table}
                  translations={translations}
                  customHeaderClass={classNames({
                    [classes.readMoreActive]: !isReadMore,
                  })}
                  initialSort={ordering}
                  headers={gridHeaders}
                  headerActions={gridHeaderActions}
                  hasLoadMore={showLoadMore}
                  rows={prepareTableData(
                    translations,
                    auth,
                    isAdmin,
                    attributes.results,
                    types,
                    renderName,
                    renderActions,
                    renderPersonalAttribute
                  )}
                  onLoadMore={onLoadMore}
                  onSort={onSort}
                />
              )}
            {!isLoading &&
              isArray(attributes.results) &&
              isArrayEmpty(attributes.results) && (
                <NotificationCard
                  title={noResultsLabels.title}
                  content={noResultsLabels.content}
                  actionButtons={
                    isAdmin && !search && !filterIsActive && isActiveView
                      ? [
                          {
                            label: translations.addRecommendedLabel,
                            type: 'rounded',
                            startIcon: <PlusIconWhite />,
                            onButtonClick: onImportAttributes,
                          },
                        ]
                      : []
                  }
                  isLoading={isLoadingImport}
                  loadingText={translations.loadingImportText}
                />
              )}
          </div>
          <AlertDialog
            isOpened={confirmAttributeDeletionOpened}
            translations={getAttributeDeleteTranslations(
              translations,
              confirmAttributeDeletionOpened,
              attributeUsedCount
            )}
            onClose={closeDeleteAttributeDialog}
            onConfirm={onConfirmDeleteAttribute(attributeId)}
            isWarning
          />
          <WarningDialog
            translations={translations.attributeDeleteWarningDialog}
            isOpened={warningDialogOpened}
            items={assignedJobTitles.results || []}
            levels={assignedLevels.results || []}
            isAssignedToJobTitle={!isObjectEmpty(assignedJobTitles)}
            isAssignedToLevels={!isObjectEmpty(assignedLevels)}
            onClose={onCloseWarningDialog}
          />
          <ChooseNameDialog
            translations={translations.chooseNameDialog}
            title={translations.chooseNameDialog.title}
            description={translations.chooseNameDialog.description}
            isOpened={chooseNameDialogOpened}
            initialValue={attributeName}
            field={ATTRIBUTE_NAME_FIELD}
            onSave={onDuplicateAttribute}
            onCancel={onCloseDuplicate}
            shortNameField={ATTRIBUTE_SHORT_NAME_FIELD}
            initialShortNameValue={attributeShortName}
            withShortNameInput
          />
          <PreviewAttributeDialog
            isOpened={previewAttributeDialogOpened}
            translations={translations.previewAttributeDialog}
            attribute={attribute}
            onClose={onClosePreview}
          />
          <CustomFormDrawer
            customFormClass={classes.form}
            translations={translations.addAttributeDrawerForm}
            titleText={
              isEditAttribute
                ? attributeData.name
                : translations.addAttributeDrawerForm.add
            }
            initialData={
              isEditAttribute ? attributeData : getNewAttributeData(auth)
            }
            isOpened={isCreateDrawerOpened}
            isInitialValid={isEditAttribute}
            fields={getAttributeFields(isEditAttribute, onCreateTag, classes)}
            attributeTypes={types}
            categories={categories}
            hasAddButton={!attributeTimesUsed}
            onDelete={onDeleteAttribute}
            onClose={onCloseCreateDrawer}
            onSave={isEditAttribute ? onAttributeEditSave : onCreateAttribute}
            hasCancelButton
            isMiddleWidth
          />
          <CustomFormDrawer
            translations={translations.addAttributeDrawerForm}
            titleText={attribute.name}
            initialData={attributeData}
            isOpened={isQuestionsDrawerOpened}
            fields={getAttributeQuestionsFields(
              translations.addAttributeDrawerForm.formLabels
            )}
            dialogs={dialogs}
            questionsLimit={organizationSettings.question_limit}
            createQuestion={handleCreateQuestion}
            setDialogVisibility={setDialogVisibility}
            setAttribute={handleSetAttribute}
            reorderQuestions={handleReorderQuestions}
            deleteAllAttributeQuestions={handleDeleteAllAttributeQuestions}
            updateAttribute={handleUpdateAttribute}
            updateAttributeQuestion={handleUpdateAttributeQuestion}
            setAttributeQuestions={handleSetAttributeQuestions}
            updateAttributeQuestions={handleUpdateAttributeQuestions}
            deleteAttributeQuestion={handleDeleteAttributeQuestion}
            getAttribute={handleGetAttribute}
            onClose={onCloseQuestionsDrawer}
            onDelete={onDeleteAttribute}
            isInitialValid
            isMiddleWidth
            isFinish
          />
        </div>
      )}
    </PageContainer>
  );
};

AttributesPage.propTypes = {
  location: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  organizationSettings: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  dialogs: PropTypes.object.isRequired,
  setDialogVisibility: PropTypes.func.isRequired,
  getOrganizationSettings: PropTypes.func.isRequired,
};

export default memo(AttributesPage);
