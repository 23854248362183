import { ReactComponent as ManualIcon } from '../assets/icons/add_circle_dark.svg';
import { ReactComponent as ImportIcon } from '../assets/icons/upload.svg';
import { ReactComponent as AssociateIcon } from '../assets/icons/vpn_lock.svg';
import { ReactComponent as PrivateNoteIcon } from '../assets/icons/anonymous-user.svg';
import { ReactComponent as PublicNoteIcon } from '../assets/icons/public-note-icon.svg';
import {
  isEmpty,
  validateEmail,
  validateLength,
  validateName,
} from '../utility/validation';
import { isArrayEmpty, trimString } from '../utility/helpers';
import http from '../utility/http';
import { FIELD_TYPES } from './fieldTypes';
import { API_VALIDATE_EMAILS } from './apiRoutes';

const { CHECKBOX, TEXT, AUTOCOMPLETE, SELECT, DATE_PICKER } = FIELD_TYPES;

export const PEOPLE_PARAM_SCOPE_VALUES = {
  ME: 'ME',
  ASSOCIATES: 'ASSOCIATES',
  ALL_REPORTS: 'ALL_REPORTS',
  DIRECT_REPORTS: 'DIR_REPORTS',
  SHARED_COLLAB: 'SHARED_COLLAB',
  SHARED_VIEW: 'SHARED_VIEW',
};

export const USER_INFO = {
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  STATUS: 'status',
  EMAIL: 'email',
  JOB_TITLE: 'position',
  EMPLOYMENT_DATE: 'date_of_employment',
  FRAMEWORK: 'framework',
  TRACK: 'track',
  LEVEL: 'level',
  REPORT_TO: 'report_to',
  ROLE: 'role',
  POSITION: 'position',
};

export const USER_INFO_KEYS = [
  USER_INFO.FIRST_NAME,
  USER_INFO.LAST_NAME,
  USER_INFO.EMAIL,
  USER_INFO.JOB_TITLE,
  USER_INFO.EMPLOYMENT_DATE,
  USER_INFO.FRAMEWORK,
  USER_INFO.TRACK,
  USER_INFO.LEVEL,
  USER_INFO.REPORT_TO,
  USER_INFO.ROLE,
];

export const PEOPLE_DEFAULT_ORDERING = {
  ordering: `${USER_INFO.FIRST_NAME},id`,
};

export const PEOPLE_MAX_PAGE_SIZE = {
  page_size: 500,
};

export const INITIAL_SORT = {
  column: 'name',
  asc: true,
  sortKey: `${USER_INFO.FIRST_NAME},id`,
};

export const ADD_USER_OPTION_KEYS = {
  MANUAL: 'MANUAL',
  IMPORT: 'IMPORT',
  ASSOCIATE: 'ASSOCIATE',
};

export const USER_VALIDATIONS = {
  [USER_INFO.FIRST_NAME]: [
    {
      validator: isEmpty,
      type: 'required',
    },
    {
      validator: validateName,
      type: 'invalidName',
    },
    {
      validator: value => validateLength(value, 2, undefined, true),
      type: 'minLength',
    },
    {
      validator: value => validateLength(value, 0, 50),
      type: 'maxLength',
    },
  ],
  [USER_INFO.LAST_NAME]: [
    {
      validator: isEmpty,
      type: 'required',
    },
    {
      validator: validateName,
      type: 'invalidName',
    },
    {
      validator: value => validateLength(value, 2, undefined, true),
      type: 'minLength',
    },
    {
      validator: value => validateLength(value, 0, 50),
      type: 'maxLength',
    },
  ],
  [USER_INFO.EMAIL]: [
    {
      validator: isEmpty,
      type: 'required',
    },
    {
      validator: validateEmail,
      type: 'invalidEmail',
    },
    {
      validator: value => validateLength(value, 0, 100),
      type: 'exceededLengthEmail',
    },
    {
      type: 'availableEmail',
      async: true,
      shouldSkipValidation: true,
      validator: value => {
        const sanitizedUserEmail = trimString(value).toLowerCase();
        return http
          .post(API_VALIDATE_EMAILS, {
            emails: [sanitizedUserEmail],
          })
          .then(({ data }) => !data?.taken?.includes(sanitizedUserEmail));
      },
    },
  ],
  [USER_INFO.REPORT_TO]: [
    {
      validator: value => !isArrayEmpty(value),
      type: 'required',
    },
  ],
  [USER_INFO.REPORT]: [
    {
      validator: isEmpty,
      type: 'required',
    },
  ],
  [USER_INFO.TRACK]: [
    {
      validator: isEmpty,
      type: 'required',
    },
  ],
  [USER_INFO.LEVEL]: [
    {
      validator: isEmpty,
      type: 'required',
    },
  ],
};

export const ADD_USER_OPTIONS = [
  {
    id: ADD_USER_OPTION_KEYS.MANUAL,
    icon: ManualIcon,
    key: 'manual',
  },
  {
    id: ADD_USER_OPTION_KEYS.IMPORT,
    icon: ImportIcon,
    key: 'import',
    hasImport: true,
  },
  {
    id: ADD_USER_OPTION_KEYS.ASSOCIATE,
    icon: AssociateIcon,
    key: 'associate',
  },
];

export const USER_FIELDS = {
  FIRST_NAME: {
    name: USER_INFO.FIRST_NAME,
    key: 'firstName',
    type: TEXT,
    required: true,
    validators: [...USER_VALIDATIONS[USER_INFO.FIRST_NAME]],
  },
  LAST_NAME: {
    name: USER_INFO.LAST_NAME,
    key: 'lastName',
    type: TEXT,
    required: true,
    validators: [...USER_VALIDATIONS[USER_INFO.LAST_NAME]],
  },
  EMAIL: {
    name: USER_INFO.EMAIL,
    key: 'email',
    type: TEXT,
    required: true,
    validators: [...USER_VALIDATIONS[USER_INFO.EMAIL]],
  },
  ROLE: {
    name: USER_INFO.ROLE,
    key: 'role',
    type: SELECT,
    optionsKey: 'roles',
    parser: {
      value: 'value',
      label: 'label',
    },
    shouldRemoveLabel: true,
  },
  REPORT_TO: {
    name: USER_INFO.REPORT_TO,
    key: 'reportingTo',
    type: AUTOCOMPLETE,
    initialValue: '',
    optionsKey: 'reportToOptions',
    organizationUserKey: 'organizationUser',
    isAsync: true,
    isSingleSelect: true,
    hasAvatar: true,
    required: true,
    validators: [...USER_VALIDATIONS[USER_INFO.REPORT_TO]],
  },
  REPORT: {
    name: USER_INFO.REPORT_TO,
    key: 'reportingTo',
    type: SELECT,
    optionsKey: 'availableManagers',
    isUser: true,
    required: true,
    parser: {
      value: 'id',
      label: 'first_name',
    },
    validators: [...USER_VALIDATIONS[USER_INFO.REPORT]],
  },
  STATUS: {
    name: USER_INFO.STATUS,
    type: SELECT,
    optionsKey: 'statuses',
    parser: {
      value: 'value',
      label: 'name',
    },
    isSearchDisabled: true,
  },
  JOB_TITLE: {
    name: USER_INFO.JOB_TITLE,
    type: SELECT,
    optionsKey: 'positions',
    parser: {
      value: 'id',
      label: 'name',
    },
  },
  FRAMEWORK: {
    name: USER_INFO.FRAMEWORK,
    type: SELECT,
    optionsKey: 'frameworks',
    parser: {
      value: 'id',
      label: 'name',
    },
    dependants: [
      {
        name: USER_INFO.TRACK,
      },
    ],
  },
  TRACK: {
    name: USER_INFO.TRACK,
    type: SELECT,
    optionsKey: 'tracks',
    parser: {
      value: 'id',
      label: 'name',
    },
    dependants: [
      {
        name: USER_INFO.LEVEL,
      },
    ],
    parent: {
      name: USER_INFO.FRAMEWORK,
      options: 'frameworks',
    },
    validators: [...USER_VALIDATIONS[USER_INFO.TRACK]],
  },
  LEVEL: {
    name: USER_INFO.LEVEL,
    type: SELECT,
    optionsKey: 'levels',
    parser: {
      value: 'id',
      label: 'name',
    },
    parent: {
      name: USER_INFO.TRACK,
      options: 'tracks',
    },
    validators: [...USER_VALIDATIONS[USER_INFO.LEVEL]],
  },
  EMPLOYMENT_DATE: {
    name: USER_INFO.EMPLOYMENT_DATE,
    type: DATE_PICKER,
  },
  INVITED: {
    name: 'status',
    key: 'invited',
    type: CHECKBOX,
  },
};

export const USER_VIEW_OPTIONS = [
  {
    value: 'active',
    name: 'Active',
    key: 'active',
  },
  {
    value: 'deactivated',
    name: 'Deactivated',
    key: 'deactivated',
  },
  {
    value: 'external',
    name: 'External',
    key: 'external',
  },
];

export const ACTIVE_DEFAULT_NAME = 'active';

export const DEACTIVATED_DEFAULT_NAME = 'deactivated';

export const EXTERNAL_DEFAULT_NAME = 'external';

export const NOTE_TYPES = {
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE',
};

export const NOTE_TYPES_MAP = [
  {
    id: NOTE_TYPES.PRIVATE,
    name: 'Private',
    key: 'private',
    icon: PrivateNoteIcon,
  },
  {
    id: NOTE_TYPES.PUBLIC,
    name: 'Public',
    key: 'public',
    icon: PublicNoteIcon,
  },
];
