import { ReactComponent as ActionPlansIcon } from '../assets/icons/assignment.svg';
import { ReactComponent as OneOnOneIcon } from '../assets/icons/people.svg';
import { ReactComponent as NotesIcon } from '../assets/icons/public-note-icon.svg';
import { ACTION_PLAN_FIELDS } from './actionPlan';

export const TEAM_NOTIFICATION_TYPES = {
  CREATE: 'create',
  UPDATE: 'update',
};

export const TEAM_NOTIFICATION_TYPES_MAP = [
  {
    value: 'NEW',
    key: TEAM_NOTIFICATION_TYPES.CREATE,
  },
  {
    value: 'UPDATE',
    key: TEAM_NOTIFICATION_TYPES.UPDATE,
  },
];

export const TEAM_NOTIFICATION_MODELS = {
  ACTION_PLAN: 'Plan',
  NOTE: 'UserNote',
  ONE_ON_ONE: 'OneOnOne',
};

export const TEAM_NOTIFICATION_MODELS_MAP = [
  {
    value: TEAM_NOTIFICATION_MODELS.NOTE,
    key: 'note',
  },
  {
    value: TEAM_NOTIFICATION_MODELS.ONE_ON_ONE,
    key: 'oneOnOne',
  },
  {
    value: TEAM_NOTIFICATION_MODELS.ACTION_PLAN,
    key: 'actionPlan',
  },
];

export const TEAM_NOTIFICATION_MODEL_ICONS = {
  actionPlan: ActionPlansIcon,
  note: NotesIcon,
  oneOnOne: OneOnOneIcon,
};

export const TEAM_NOTIFICATION_ROUTES = {
  actionPlan: 'action-plans',
  notes: 'notes',
  oneOnOne: '1-1',
};

export const TEAM_NOTIFICATION_CREATE_TYPES = {
  ACTION_PLAN: 'createdPlan',
  NOTE: 'createdNote',
  ONE_ON_ONE: 'createdOneOnOne',
};

export const TEAM_NOTIFICATION_UPDATE_TYPES = {
  actionPlan: {
    [ACTION_PLAN_FIELDS.TITLE]: 'title',
    [ACTION_PLAN_FIELDS.DUE_DATE]: 'dueDate',
    [ACTION_PLAN_FIELDS.STATUS]: 'status',
    [ACTION_PLAN_FIELDS.PROGRESS]: 'completeness',
    [ACTION_PLAN_FIELDS.TAGS]: 'tags',
    [ACTION_PLAN_FIELDS.COURSES]: 'courses',
    [ACTION_PLAN_FIELDS.DESCRIPTION]: 'description',
    [ACTION_PLAN_FIELDS.TASKS]: 'tasks',
    [TEAM_NOTIFICATION_CREATE_TYPES.ACTION_PLAN]:
      TEAM_NOTIFICATION_CREATE_TYPES.ACTION_PLAN,
  },
  note: {
    text: 'text',
    tasks: 'tasks',
    tags: 'tags',
    [TEAM_NOTIFICATION_CREATE_TYPES.NOTE]: TEAM_NOTIFICATION_CREATE_TYPES.NOTE,
  },
  oneOnOne: {
    meeting_time: 'meetingTime',
    recurring: 'recurring',
    tags: 'tags',
    agenda: 'agenda',
    plans: 'plans',
    shared_note: 'sharedNote',
    meeting_impression: 'meetingImpression',
    [TEAM_NOTIFICATION_CREATE_TYPES.ONE_ON_ONE]:
      TEAM_NOTIFICATION_CREATE_TYPES.ONE_ON_ONE,
  },
};
