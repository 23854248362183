import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import ActionButton from '../actionButton';
import { ACTION_BUTTON_TYPES } from '../actionButton/config';

const useStyles = makeStyles(({ palette: { primary } }) => ({
  root: {
    display: 'flex',
    width: 'fit-content',
    position: 'relative',
  },
  countWrapper: {
    backgroundColor: primary.pink1,
    borderRadius: '50%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: -2,
    right: 0,
    width: 18,
    height: 18,
  },
  count: {
    color: primary.white,
    fontSize: 10,
    lineHeight: '12px',
  },
}));

const NotificationButton = ({ tooltipText, notificationCount }) => {
  const classes = useStyles();

  const maxCount = 9;

  return (
    <div className={classes.root}>
      <ActionButton
        type={ACTION_BUTTON_TYPES.NOTIFICATION}
        tooltipText={tooltipText}
      />
      {notificationCount !== 0 && (
        <div className={classes.countWrapper}>
          <Typography className={classes.count} variant="overline">
            {notificationCount > maxCount ? `${maxCount}+` : notificationCount}
          </Typography>
        </div>
      )}
    </div>
  );
};

NotificationButton.defaultProps = {
  tooltipText: '',
  notificationCount: 0,
};

NotificationButton.propTypes = {
  tooltipText: PropTypes.string,
  notificationCount: PropTypes.number,
};

export default NotificationButton;
