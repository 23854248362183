import { memo, useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { APP_PAGES, PARAMS } from '../../../constants/pages';
import { useTranslations } from '../../../utility/useTranslations';
import {
  clearLevelingFrameworks,
  getLevelingFrameworks,
  setFrameworkReordering,
  setLevelReordering,
  setTrackReordering,
} from '../../../store/modules/levelsAndTracks';
import PageContainer from '../../shared/pageContainer';
import LevelingFrameworks from '../../shared/levelingFrameworks';
import { levelsAndTracksSelector } from '../../../store/selectors/levelsAndTracksSelector';
import {
  getAssignedUsersToLevelFields,
  getDotsMenuItems,
  getFields,
  getFieldsToMove,
  getInitialUsersData,
  getLevelFields,
  prepareInitialData,
  prepareInitialDataToMove,
  prepareLevelingFrameworksData,
} from '../../../utility/leveling';
import CustomFormDrawer from '../../shared/customFormDrawer';
import TextBoxWithTooltip from '../../shared/textBoxWithTooltip';
import {
  clearAttributesWithQuestions,
  getAttributesWithRange,
} from '../../../store/modules/surveys';
import { surveysSelector } from '../../../store/selectors/surveysSelector';
import DotsMenu from '../../shared/dotsMenu';
import http from '../../../utility/http';
import {
  API_FRAMEWORK_ITEMS_REORDER,
  API_FRAMEWORKS,
  API_LEVELS,
  API_TRACKS,
  API_USERS_MULTIPLE,
  api_framework_duplicate,
  api_levels_reorder,
  api_tracks_reorder,
  api_level_duplicate,
  api_leveling_add_template,
  api_frameworks,
  api_level_move,
  api_levels,
  api_levels_users,
  api_track_move,
  api_tracks,
  api_track_duplicate,
} from '../../../constants/apiRoutes';
import {
  getLevelingDrawerTitle,
  prepareUsersForUpdate,
  showSuccessMessage,
} from '../../../utility/uiUtils';
import AlertDialog from '../../shared/alertDialog';
import AttributeChip from '../../shared/attributeChip';
import UserCount from '../../shared/userCount';
import { clearAllUsers, getAllUsers } from '../../../store/modules/people';
import {
  PEOPLE_PARAM_SCOPE_VALUES,
  USER_INFO,
} from '../../../constants/people';
import { PERMISSIONS, ROLES } from '../../../constants/rolesAndPermissionList';
import {
  canSeeEmployeeProfile,
  checkUserRole,
  getObjectToNumberArray,
  isArrayEmpty,
  isPermissionGranted,
} from '../../../utility/helpers';
import VisibleForPermission from '../../shared/visibleForPermission';
import CustomButton from '../../shared/customButton';
import TemplateForm from '../../shared/templateForm';
import {
  getLevelingTemplateFormColumns,
  prepareDataForSave,
  prepareTemplateForSave,
} from './config';
import Tooltip from '../../shared/tooltip';
import { ReactComponent as TextReplyIcon } from '../../../assets/icons/text_snippet.svg';
import RichTextPreview from '../../shared/richTextPreview';
import { sticky } from '../../../constants/helperCssRules';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  subHeader: {
    padding: spacing(8, 0, 4),
    ...sticky(primary.white, 105),
  },
  levelingPageContainer: {
    width: 'inherit',
  },
  actionWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: 32,
  },
  addTemplateButton: {
    marginLeft: spacing(2),
    width: 162,
    '&:active': {
      animation: '300ms ease-out',
    },
  },
  attribute: {
    marginBottom: spacing(2),
    width: 185,
  },
  range: {
    marginBottom: spacing(2),
    alignItems: 'center',
    height: 28,
    display: 'flex',
  },
  descriptionText: {
    color: primary.black,
    fontSize: 16,
    lineHeight: '20.8px',
  },
  descriptionTitle: {
    color: primary.black,
    fontSize: 16,
    height: 22,
    lineHeight: '18px',
    marginBottom: 12,
  },
  attributeIcon: {
    cursor: 'default',
    display: 'flex',
    alignItems: 'center',
    flexShrink: 0,
    marginRight: spacing(1),
    '& svg': {
      '& g, & path': {
        fill: primary.bluish1,
      },
    },
    '& g, & path': {
      fill: primary.bluish1,
    },
  },
}));

const DELETE_TYPE = {
  FRAMEWORK: 'FRAMEWORK',
  TRACK: 'TRACK',
  LEVEL: 'LEVEL',
};
const { SCOPE, EXCLUDE } = PARAMS;

const LevelingPage = ({
  navigate,
  auth,
  organizationSettings,
  isPremiumUser,
  grantedPermissions,
  ...rest
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const translations = useTranslations(APP_PAGES.LEVELING);

  const { frameworks } = useSelector(levelsAndTracksSelector);

  const { attributesWithQuestions } = useSelector(surveysSelector);

  const [deleteType, setDeleteType] = useState('');
  const [isFrameworkDrawerOpened, setIsFrameworkDrawerOpened] = useState(false);
  const [isTrackDrawerOpened, setIsTrackDrawerOpened] = useState(false);
  const [
    isDuplicateFrameworkDrawerOpened,
    setIsDuplicateFrameworkDrawerOpened,
  ] = useState(false);
  const [isMoveTrackDrawerOpened, setIsMoveTrackDrawerOpened] = useState(false);
  const [isMoveLevelDrawerOpened, setIsMoveLevelDrawerOpened] = useState(false);
  const [isDuplicateTrack, setIsDuplicateTrack] = useState(false);
  const [isDuplicateLevel, setIsDuplicateLevel] = useState(false);
  const [isLevelDrawerOpened, setIsLevelDrawerOpened] = useState(false);
  const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedFramework, setSelectedFramework] = useState({});
  const [selectedTrack, setSelectedTrack] = useState({});
  const [selectedLevel, setSelectedLevel] = useState({});
  const [alertMessage, setAlertMessage] = useState({});
  const [isAddTemplateOpened, setIsAddTemplateOpened] = useState(false);
  const [isAssignUsersToLevelOpened, setIsAssignUsersToLevelOpened] =
    useState(false);
  const [assignableUsers, setAssignableUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const toggleTemplateDrawer = () =>
    setIsAddTemplateOpened(prevIsOpened => !prevIsOpened);
  const toggleAssignUsersToLevelDrawer = () =>
    setIsAssignUsersToLevelOpened(prevIsOpened => !prevIsOpened);

  const { canManageTracks } = PERMISSIONS;
  const isReadOnly = !isPermissionGranted(canManageTracks, grantedPermissions);
  const isUser = checkUserRole(auth.role, ROLES.USER);
  const isModerator = checkUserRole(auth.role, ROLES.MODERATOR);
  const isAdmin = checkUserRole(auth.role, ROLES.ADMIN);

  const initialData = useCallback(async () => {
    getLevelingFrameworks(dispatch);
    getAttributesWithRange(dispatch);
    if (!isUser) {
      const { payload } = await getAllUsers(dispatch, {
        ...(isModerator
          ? {
              [SCOPE]: PEOPLE_PARAM_SCOPE_VALUES.ALL_REPORTS,
              [EXCLUDE]: [auth.id],
            }
          : {}),
      });

      setAllUsers(payload);
    }
  }, [dispatch, auth, isUser, isModerator]);

  useEffect(() => {
    initialData();
  }, [initialData]);

  const cleanup = useCallback(() => {
    dispatch(clearAttributesWithQuestions());
    dispatch(clearAllUsers());
    dispatch(clearLevelingFrameworks());
  }, [dispatch]);

  useEffect(() => {
    return cleanup;
  }, [cleanup]);

  const handleSaveFramework = framework => {
    if (isEdit) {
      return http
        .patch(api_frameworks(framework.id), {
          name: framework.name,
        })
        .then(() => {
          showSuccessMessage(translations.successMessages.frameworkUpdated);
          getLevelingFrameworks(dispatch);
        });
    }
    return http
      .post(API_FRAMEWORKS, {
        name: framework.name,
      })
      .then(() => {
        showSuccessMessage(translations.successMessages.frameworkSaved);
        getLevelingFrameworks(dispatch);
      });
  };

  const handleSaveTrack = track => {
    if (isEdit) {
      return http
        .patch(api_tracks(track.id), {
          name: track.name,
        })
        .then(() => {
          showSuccessMessage(translations.successMessages.trackUpdated);
          getLevelingFrameworks(dispatch);
        });
    }
    return http
      .post(API_TRACKS, {
        name: track.name,
        framework: selectedFramework?.id,
      })
      .then(() => {
        showSuccessMessage(translations.successMessages.trackSaved);
        getLevelingFrameworks(dispatch);
      });
  };

  const handleSaveLevel = level => {
    if (isEdit) {
      return http
        .patch(api_levels(level.id), {
          name: level.name,
          description: level.description,
          attributes: prepareDataForSave(level.attributes),
        })
        .then(() => {
          showSuccessMessage(translations.successMessages.levelUpdated);
          getLevelingFrameworks(dispatch);
        });
    }
    return http
      .post(API_LEVELS, {
        track: selectedTrack?.id,
        name: level.name,
        description: level.description,
        attributes: prepareDataForSave(level.attributes),
      })
      .then(() => {
        showSuccessMessage(translations.successMessages.levelSaved);
        getLevelingFrameworks(dispatch);
      });
  };

  const handleFrameworkDrawer = () => {
    setIsFrameworkDrawerOpened(prevState => !prevState);
  };

  const handleCloseFrameworkDrawer = () => {
    handleFrameworkDrawer();
    setIsEdit(false);
    setSelectedFramework({});
  };

  const handleEditFrameworkTitle = framework => {
    setSelectedFramework(framework);
    setIsEdit(true);
    handleFrameworkDrawer();
  };

  const handleTrackDrawer = (framework = {}) => {
    setSelectedFramework(framework);
    setIsTrackDrawerOpened(prevState => !prevState);
  };

  const handleEditTrackTitle = (track, framework) => {
    setIsTrackDrawerOpened(prevState => !prevState);
    setSelectedTrack(track);
    setSelectedFramework(framework);
    setIsEdit(true);
  };

  const handleCloseTrackDrawer = () => {
    setIsTrackDrawerOpened(prevState => !prevState);
    setIsEdit(false);
    setSelectedTrack({});
    setSelectedFramework({});
  };

  const handleLevelDrawer = (track, framework) => {
    setSelectedTrack(track);
    setSelectedFramework(framework);
    setIsLevelDrawerOpened(prevState => !prevState);
  };

  const handleEditLevel = (level, track, framework) => {
    setSelectedLevel(level);
    setSelectedTrack(track);
    setSelectedFramework(framework);
    setIsEdit(true);
    setIsLevelDrawerOpened(prevState => !prevState);
  };

  const handleCloseLevelDrawer = () => {
    setIsLevelDrawerOpened(prevState => !prevState);
    setIsEdit(false);
    setSelectedLevel({});
    setSelectedTrack({});
    setSelectedFramework({});
  };

  const handleDeleteDialog = type => {
    switch (type) {
      case DELETE_TYPE.FRAMEWORK: {
        setAlertMessage(translations.deleteFrameworkDialog);
        break;
      }
      case DELETE_TYPE.TRACK: {
        setAlertMessage(translations.deleteTrackDialog);
        break;
      }
      case DELETE_TYPE.LEVEL: {
        setAlertMessage(translations.deleteLevelDialog);
        break;
      }
      default:
        break;
    }
    setDeleteType(type);
    setIsDeleteDialogOpened(prevState => !prevState);
  };

  const handleConfirmDelete = () => {
    switch (deleteType) {
      case DELETE_TYPE.FRAMEWORK:
        return http
          .delete(`${API_FRAMEWORKS}${selectedFramework?.id}/`)
          .then(() => {
            showSuccessMessage(translations.successMessages.frameworkDeleted);
            setIsFrameworkDrawerOpened(false);
            setIsDeleteDialogOpened(prevState => !prevState);
            getLevelingFrameworks(dispatch);
          });
      case DELETE_TYPE.TRACK:
        return http.delete(`${API_TRACKS}${selectedTrack?.id}/`).then(() => {
          showSuccessMessage(translations.successMessages.trackDeleted);
          setIsTrackDrawerOpened(false);
          setIsDeleteDialogOpened(prevState => !prevState);
          getLevelingFrameworks(dispatch);
        });
      case DELETE_TYPE.LEVEL:
        return http.delete(`${API_LEVELS}${selectedLevel?.id}/`).then(() => {
          showSuccessMessage(translations.successMessages.levelDeleted);
          setIsLevelDrawerOpened(false);
          setIsDeleteDialogOpened(prevState => !prevState);
          getLevelingFrameworks(dispatch);
        });
      default:
        break;
    }
  };

  const handleReorderFrameworks = reorderedFrameworks => {
    const frameworkIds = getObjectToNumberArray(reorderedFrameworks);

    dispatch(setFrameworkReordering({ frameworkIds }));

    http.patch(API_FRAMEWORK_ITEMS_REORDER, {
      order: frameworkIds,
    });
  };

  const handleReorderTracks = (frameworkId, reorderedTracks) => {
    const trackIds = getObjectToNumberArray(reorderedTracks);
    const frameworkIndex = frameworks.findIndex(
      framework => framework?.id === frameworkId
    );

    dispatch(setTrackReordering({ frameworkIndex, trackIds }));

    return http.patch(api_tracks_reorder(frameworkId), {
      order: trackIds,
    });
  };

  const handleReorderLevels = (framework, trackId, reorderedLevels) => {
    const levelIds = getObjectToNumberArray(reorderedLevels);
    const frameworkIndex = frameworks.findIndex(
      item => item?.id === framework?.id
    );
    const trackIndex = framework.tracks?.findIndex(
      track => track?.id === trackId
    );

    dispatch(setLevelReordering({ frameworkIndex, trackIndex, levelIds }));

    return http.patch(api_levels_reorder(trackId), {
      order: levelIds,
    });
  };

  const renderDescription = level => {
    return (
      <>
        <TextBoxWithTooltip
          className={classes.descriptionTitle}
          variant="h5"
          text={level.name}
        />
        <RichTextPreview
          className={classes.descriptionText}
          text={level.description}
        />
      </>
    );
  };

  const renderAttributes = attributes => {
    const totalNumber = attributes?.length;

    return attributes?.map(item => {
      const { attribute } = item;

      return (
        <AttributeChip
          customClass={classes.attribute}
          key={`attribute_${attribute.id}`}
          name={attribute.name}
          color={attribute.color}
          attributeNumber={totalNumber}
        />
      );
    });
  };

  const renderRange = attributes => {
    return attributes?.map((item, index) => {
      const { range_from, range_to, attribute } = item;

      return attribute.text_reply_questions ? (
        <div className={classes.range} key={`range_${index}`}>
          <Tooltip
            customIconClass={classes.attributeIcon}
            text={translations.openEndedAttribute}
            icon={TextReplyIcon}
          />
        </div>
      ) : (
        <div key={`range_${index}`} className={classes.range}>
          <Typography variant="subtitle2">
            {`${range_from} - ${range_to}`}
          </Typography>
        </div>
      );
    });
  };

  const handleAssignUsersToLevelDialog = async (level, track, framework) => {
    setSelectedLevel(level);
    setSelectedTrack(track);
    setSelectedFramework(framework);
    const { data } = await http.get(api_levels_users(level?.id));
    setAssignableUsers(data);
    toggleAssignUsersToLevelDrawer();
  };

  const handleCloseAssignUsersDrawer = () => {
    toggleAssignUsersToLevelDrawer();
    setSelectedLevel({});
    setSelectedTrack({});
    setSelectedFramework({});
  };

  const handleAssignUsersToLevel = async ({ assignUsers }) => {
    await http.patch(
      API_USERS_MULTIPLE,
      prepareUsersForUpdate(
        allUsers,
        assignUsers,
        assignableUsers,
        selectedLevel.id,
        USER_INFO.LEVEL
      )
    );
    getLevelingFrameworks(dispatch);
    showSuccessMessage(translations.successMessage.assignedUsersToLevel);
  };

  const handleUsers = levelId => http.get(api_levels_users(levelId));

  const canSeeUserProfileCheck = user =>
    canSeeEmployeeProfile(
      auth,
      user.id,
      organizationSettings.global_see_himself
    );

  const goToUserProfilePage = userId => navigate(`/people/${userId}`);

  const renderPeople = (level, track, framework) => {
    const levelUsers = level.user_count;

    return (
      <UserCount
        addButtonLabel={
          translations.frameworkGroupView.assignUsersToLevel.assignLabel
        }
        goToUserProfile={goToUserProfilePage}
        canSeeUserProfile={canSeeUserProfileCheck}
        userCount={levelUsers}
        getUsers={() => handleUsers(level.id)}
        onAdd={() => handleAssignUsersToLevelDialog(level, track, framework)}
        hasAddButton={!isUser}
        isTooltipDisabled={!levelUsers && isUser}
      />
    );
  };

  const handleMoveLevelDrawer = (track, level, framework) => {
    setSelectedFramework(framework);
    setSelectedTrack(track);
    setSelectedLevel(level);
    setIsMoveLevelDrawerOpened(prevState => !prevState);
  };

  const handleMoveLevel = ({ track }) => {
    return http
      .patch(api_level_move(selectedLevel?.id), {
        track_id: track,
      })
      .then(() => {
        getLevelingFrameworks(dispatch);
        showSuccessMessage(translations.successMessages.moveLevel);
      });
  };

  const handleDuplicateLevelDrawer = (track, level, framework) => {
    setIsDuplicateLevel(true);
    setSelectedFramework(framework);
    setSelectedTrack(track);
    setSelectedLevel(level);
    setIsMoveLevelDrawerOpened(prevState => !prevState);
  };

  const handleDuplicateLevel = ({ name, track }) => {
    return http
      .post(api_level_duplicate(selectedLevel?.id), {
        track_id: track,
        name,
      })
      .then(() => {
        getLevelingFrameworks(dispatch);
        setIsDuplicateLevel(false);
        showSuccessMessage(translations.successMessages.duplicateLevel);
      });
  };

  const handleDeleteFrameworkDialog = framework => {
    setSelectedFramework(framework);
    handleDeleteDialog(DELETE_TYPE.FRAMEWORK);
  };

  const handleDeleteTrackDialog = track => {
    setSelectedTrack(track);
    handleDeleteDialog(DELETE_TYPE.TRACK);
  };

  const handleDeleteLevelDialog = level => {
    setSelectedLevel(level);
    handleDeleteDialog(DELETE_TYPE.LEVEL);
  };

  const renderActions = (level, track, framework) => (
    <DotsMenu
      menuItems={getDotsMenuItems(
        translations.frameworkGroupView,
        level,
        track,
        handleDeleteLevelDialog,
        handleEditLevel,
        handleDuplicateLevelDrawer,
        handleMoveLevelDrawer,
        true,
        framework
      )}
    />
  );

  const handleMoveTrackDrawer = (framework, track) => {
    setSelectedFramework(framework);
    setSelectedTrack(track);
    setIsMoveTrackDrawerOpened(prevState => !prevState);
  };

  const handleMoveTrack = ({ framework }) => {
    return http
      .patch(api_track_move(selectedTrack?.id), {
        framework_id: framework,
      })
      .then(() => {
        getLevelingFrameworks(dispatch);
        showSuccessMessage(translations.successMessages.moveTrack);
      });
  };

  const handleDuplicateTrackDrawer = (framework, track) => {
    setIsDuplicateTrack(true);
    setSelectedFramework(framework);
    setSelectedTrack(track);
    setIsMoveTrackDrawerOpened(prevState => !prevState);
  };

  const handleDuplicateTrack = ({ name, framework }) => {
    return http
      .post(api_track_duplicate(selectedTrack?.id), {
        framework_id: framework,
        name,
      })
      .then(() => {
        getLevelingFrameworks(dispatch);
        setIsDuplicateTrack(false);
        showSuccessMessage(translations.successMessages.duplicateTrack);
      });
  };

  const handleDuplicateFrameworkDrawer = (framework = {}, isOpened = false) => {
    setSelectedFramework(framework);
    setIsDuplicateFrameworkDrawerOpened(isOpened);
  };

  const handleDuplicateFramework = ({ name }) => {
    return http
      .post(api_framework_duplicate(selectedFramework?.id), { name })
      .then(() => {
        getLevelingFrameworks(dispatch);
        setSelectedFramework({});
        setIsDuplicateFrameworkDrawerOpened(false);
        showSuccessMessage(translations.successMessages.duplicateFramework);
      });
  };

  const handleAddTemplate = ({ template }) => {
    const frameworksTemp = template?.value?.frameworks;
    const preparedData = isArrayEmpty(frameworksTemp)
      ? []
      : prepareTemplateForSave(frameworksTemp[0]);

    return http
      .post(api_leveling_add_template, {
        framework: preparedData,
      })
      .then(() => {
        showSuccessMessage(translations.successMessages.addTemplate);
        getLevelingFrameworks(dispatch);
      });
  };

  const trackSubtitleText = `${selectedFramework?.name} >`;
  const levelSubtitleText = `${selectedFramework.name} > ${selectedTrack?.name} >`;

  const handleTrackDrawerTitle = useMemo(() => {
    const levelingTranslations = translations.frameworkGroupView;
    const type = levelingTranslations.track;
    const title = isDuplicateTrack
      ? getLevelingDrawerTitle(
          levelingTranslations.moveOrDuplicate.add,
          levelingTranslations.duplicate,
          type
        )
      : getLevelingDrawerTitle(
          levelingTranslations.moveOrDuplicate.add,
          levelingTranslations.move,
          type
        );

    return { ...levelingTranslations.moveOrDuplicate, add: title };
  }, [translations, isDuplicateTrack]);

  const handleLevelDrawerTitle = useMemo(() => {
    const levelingTranslations = translations.frameworkGroupView;
    const type = levelingTranslations.level;
    const title = isDuplicateLevel
      ? getLevelingDrawerTitle(
          levelingTranslations.moveOrDuplicate.add,
          levelingTranslations.duplicate,
          type
        )
      : getLevelingDrawerTitle(
          levelingTranslations.moveOrDuplicate.add,
          levelingTranslations.move,
          type
        );

    return { ...levelingTranslations.moveOrDuplicate, add: title };
  }, [translations, isDuplicateLevel]);

  const prepareAttributes = attributes =>
    attributes.filter(attr => !attr.is_private);

  return (
    <PageContainer
      {...rest}
      translations={translations}
      auth={auth}
      navigate={navigate}
      organizationSettings={organizationSettings}
      isPremiumUser={isPremiumUser}
      shouldPassProps={false}
      isFullWidthContent
      grantedPermissions={grantedPermissions}
    >
      <div>
        <div className={classes.subHeader}>
          <div className={classes.actionWrapper}>
            <Typography variant="body2">
              {isReadOnly
                ? translations.description.read
                : translations.description.admin}
            </Typography>
            <VisibleForPermission
              permission={canManageTracks}
              permissions={grantedPermissions}
            >
              <CustomButton
                className={classes.addTemplateButton}
                type="addLightRoundedNew"
                onClick={toggleTemplateDrawer}
              >
                {translations.addTemplateButton}
              </CustomButton>
            </VisibleForPermission>
          </div>
        </div>
        <div className={classes.levelingPageContainer}>
          <LevelingFrameworks
            translations={translations.frameworkGroupView}
            levelingFrameworks={prepareLevelingFrameworksData(
              frameworks,
              renderDescription,
              renderAttributes,
              renderRange,
              renderPeople,
              renderActions
            )}
            onEditFrameworkTitle={handleEditFrameworkTitle}
            onEditTrackTitle={handleEditTrackTitle}
            onDuplicateFramework={framework =>
              handleDuplicateFrameworkDrawer(framework, true)
            }
            handleDeleteFrameworkDialog={handleDeleteFrameworkDialog}
            handleDeleteTrackDialog={handleDeleteTrackDialog}
            onDuplicateTrack={handleDuplicateTrackDrawer}
            handleCreateFramework={handleFrameworkDrawer}
            handleCreateLevel={handleLevelDrawer}
            handleCreateTrack={handleTrackDrawer}
            onReorderFramework={handleReorderFrameworks}
            onReorderTrack={handleReorderTracks}
            onReorderLevel={handleReorderLevels}
            handleMoveTrack={handleMoveTrackDrawer}
            isAdmin={isAdmin}
          />
        </div>
        <CustomFormDrawer
          translations={translations.frameworkGroupView.addFramework}
          isOpened={isFrameworkDrawerOpened}
          initialData={prepareInitialData(selectedFramework, isEdit)}
          fields={getFields(frameworks)}
          onClose={handleCloseFrameworkDrawer}
          onSave={handleSaveFramework}
          isInitialValid={isEdit}
          onDelete={() => handleDeleteDialog(DELETE_TYPE.FRAMEWORK)}
        />
        <CustomFormDrawer
          translations={translations.frameworkGroupView.addTrack}
          isOpened={isTrackDrawerOpened}
          initialData={prepareInitialData(selectedTrack, isEdit)}
          fields={getFields(frameworks, trackSubtitleText)}
          onClose={handleCloseTrackDrawer}
          onSave={handleSaveTrack}
          isInitialValid={isEdit}
          onDelete={() => handleDeleteDialog(DELETE_TYPE.TRACK)}
        />
        <CustomFormDrawer
          translations={translations.frameworkGroupView.addLevel}
          isOpened={isLevelDrawerOpened}
          initialData={prepareInitialData(selectedLevel, isEdit, true)}
          attributesWithQuestions={prepareAttributes(attributesWithQuestions)}
          fields={getLevelFields(levelSubtitleText)}
          onClose={handleCloseLevelDrawer}
          onSave={handleSaveLevel}
          isInitialValid={isEdit}
          onDelete={() => handleDeleteDialog(DELETE_TYPE.LEVEL)}
        />
        <CustomFormDrawer
          translations={translations.frameworkGroupView.duplicateFramework}
          isOpened={isDuplicateFrameworkDrawerOpened}
          initialData={prepareInitialData(selectedFramework, true)}
          fields={getFields(frameworks)}
          onClose={handleDuplicateFrameworkDrawer}
          onSave={handleDuplicateFramework}
          hideDelete
        />
        <CustomFormDrawer
          translations={handleTrackDrawerTitle}
          isOpened={isMoveTrackDrawerOpened}
          initialData={prepareInitialDataToMove(
            selectedFramework,
            selectedTrack
          )}
          fields={getFieldsToMove(trackSubtitleText, false)}
          onClose={() => {
            setIsDuplicateTrack(false);
            setIsMoveTrackDrawerOpened(prevState => !prevState);
          }}
          frameworks={frameworks}
          onSave={isDuplicateTrack ? handleDuplicateTrack : handleMoveTrack}
        />
        <CustomFormDrawer
          translations={handleLevelDrawerTitle}
          isOpened={isMoveLevelDrawerOpened}
          initialData={prepareInitialDataToMove(
            selectedFramework,
            selectedTrack,
            selectedLevel,
            true
          )}
          fields={getFieldsToMove(levelSubtitleText, true)}
          onClose={() => {
            setIsDuplicateLevel(false);
            setIsMoveLevelDrawerOpened(prevState => !prevState);
          }}
          frameworks={frameworks}
          onSave={isDuplicateLevel ? handleDuplicateLevel : handleMoveLevel}
        />
        <CustomFormDrawer
          translations={translations.frameworkGroupView.assignUsersToLevel}
          isOpened={isAssignUsersToLevelOpened}
          initialData={getInitialUsersData(assignableUsers)}
          fields={getAssignedUsersToLevelFields(levelSubtitleText)}
          allUsers={allUsers}
          onClose={handleCloseAssignUsersDrawer}
          onSave={handleAssignUsersToLevel}
          hasCancelButton
          hideDelete
          isInitialValid
        />
        <AlertDialog
          translations={alertMessage}
          isOpened={isDeleteDialogOpened}
          onClose={handleDeleteDialog}
          onConfirm={handleConfirmDelete}
          isWarning
        />
        <TemplateForm
          translations={translations.addTemplate}
          isOpened={isAddTemplateOpened}
          columns={getLevelingTemplateFormColumns(
            frameworks,
            translations.frameworkGroupView.templateSubtitle,
            renderDescription,
            renderAttributes,
            renderRange,
            renderPeople
          )}
          onClose={toggleTemplateDrawer}
          onSave={handleAddTemplate}
        />
      </div>
    </PageContainer>
  );
};

LevelingPage.propTypes = {
  grantedPermissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  organizationSettings: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  auth: PropTypes.shape({}).isRequired,
  isPremiumUser: PropTypes.bool.isRequired,
};

export default memo(LevelingPage);
