import { memo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import AttributeChipWithRange from '../../attributeChipWithRange';

const useStyles = makeStyles(({ spacing }) => ({
  attribute: {
    minWidth: 0,
    margin: spacing(0, 1, 1, 0),
  },
}));

const SelectedItems = ({ items, onDeselectItem, onChangeRange }) => {
  const classes = useStyles();

  return items.map(item => (
    <AttributeChipWithRange
      key={`value${item.id}`}
      id={item.id}
      customClass={classes.attribute}
      attribute={item}
      name={item.name}
      color={item?.color}
      onRemove={onDeselectItem}
      onChangeRange={onChangeRange}
      isSelected
      hasRemove
    />
  ));
};

SelectedItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  onDeselectItem: PropTypes.func.isRequired,
  onChangeRange: PropTypes.func.isRequired,
};

export default memo(SelectedItems);
