import { useState, useEffect, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { Typography, makeStyles } from '@material-ui/core';
import PeoplePageContainer from '../../shared/peoplePageContainer';
import NotificationCard from '../../shared/notificationCard';
import CustomButton from '../../shared/customButton';
import AlertDialog from '../../shared/alertDialog';
import CustomFormDrawer from '../../shared/customFormDrawer';
import NotesList from '../../shared/notesList';
import ConditionalTooltip from '../../shared/conditionalTooltip';
import ChooseTypeDialog from '../../shared/chooseTypeDialog';
import { showSuccessMessage } from '../../../utility/uiUtils';
import { useTranslations } from '../../../utility/useTranslations';
import { notesSelector } from '../../../store/selectors/notesSelector';
import { personSelector } from '../../../store/selectors/personSelector';
import { peopleSelector } from '../../../store/selectors/peopleSelector';
import { tagsSelector } from '../../../store/selectors/tagsSelector';
import {
  getNotes,
  clearNotes,
  createNote,
  deleteNote,
  editNote,
  editTaskStatus,
} from '../../../store/modules/notes';
import {
  getTagsCategories,
  clearTagsCategories,
  addMultipleTags,
} from '../../../store/modules/tags';
import { getAllUsers, clearAllUsers } from '../../../store/modules/people';
import {
  isUserDeactivated,
  isArrayEmpty,
  isUserProfileAccessible,
  canManagePerson,
  checkUserRole,
  removeObjectFromArray,
  canSeeEmployeeProfile,
} from '../../../utility/helpers';
import { onSaveCreatableTag } from '../../../utility/tagUtils';
import {
  getManageNoteFields,
  getManageNoteInitialData,
  prepareNoteForSave,
  getNoteFormTitle,
} from '../../../utility/notes';
import { getPeopleNoteTypes } from '../../../utility/people';
import { NOTE_TYPES } from '../../../constants/people';
import { sticky } from '../../../constants/helperCssRules';
import { ROLES } from '../../../constants/rolesAndPermissionList';
import { GENERAL_CATEGORY_ID } from '../../../constants/tags';
import { NOTE_FIELD_NAMES } from '../../../constants/notes';
import { APP_PAGES } from '../../../constants/pages';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  header: {
    paddingBottom: spacing(4),
    ...sticky(primary.white, 169),
  },
  descriptionWrap: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  description: {
    marginBottom: spacing(4),
  },
  form: {
    display: 'grid',
    gridColumnGap: 24,
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
    gridTemplateAreas: `
      "visibility visibility"
      "note note"
      "tags ."
      "tasks tasks"
    `,
  },
  visibility: {
    gridArea: 'visibility',
  },
  checkbox: {
    width: '100%',
  },
  note: {
    gridArea: 'note',
    '& .ql-container': {
      height: 376,
    },
    '& > div': {
      height: 445,
    },
  },
  tags: {
    gridArea: 'tags',
  },
  tasks: {
    gridArea: 'tasks',
  },
  disabledUserAction: {
    cursor: 'not-allowed',
  },
}));

const PeopleNotesPage = ({
  auth,
  organizationSettings,
  navigate,
  location,
  dispatch,
  ...rest
}) => {
  const { id } = useParams();
  const userId = (!Number.isNaN(Number(id)) && +id) || -1;

  const classes = useStyles();
  const translations = useTranslations(APP_PAGES.PEOPLE_PRIVATE_NOTES);

  const user = useSelector(personSelector);
  const { notes } = useSelector(notesSelector);
  const { allUsers } = useSelector(peopleSelector);
  const { categories } = useSelector(tagsSelector);

  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [isManageNoteOpened, setIsManageNoteOpened] = useState(false);
  const [isDeleteDialogOpened, setIsDeleteDialogOpened] = useState(false);
  const [note, setNote] = useState(null);
  const [isNoteTypeDialogOpened, setIsNoteTypeDialogOpened] = useState(false);
  const [noteType, setNoteType] = useState(NOTE_TYPES.PRIVATE);

  const isEdit = !!note;
  const isAdmin = checkUserRole(auth.role, ROLES.ADMIN);
  const isDeactivatedUser = isUserDeactivated(user);
  const canAddNote = canManagePerson(auth, user?.id);
  const availableTaskUsers = removeObjectFromArray(allUsers, user);
  const noteTypes = getPeopleNoteTypes(translations.noteTypeDialog);
  const isPrivate =
    note?.[NOTE_FIELD_NAMES.IS_PRIVATE] || noteType === NOTE_TYPES.PRIVATE;

  const getInitialData = useCallback(async () => {
    try {
      await Promise.all([
        getNotes(dispatch, userId),
        getTagsCategories(dispatch),
        getAllUsers(dispatch),
      ]);
    } finally {
      setIsInitialLoad(false);
    }
  }, [dispatch, userId]);

  const cleanup = useCallback(() => {
    dispatch(clearNotes());
    dispatch(clearTagsCategories());
    dispatch(clearAllUsers());
  }, [dispatch]);

  useEffect(() => {
    getInitialData();
  }, [getInitialData]);

  useEffect(() => {
    return cleanup;
  }, [cleanup]);

  const goToUserProfilePage = uId => navigate(`/people/${uId}`);

  const onOpenNoteType = () => setIsNoteTypeDialogOpened(true);

  const onCloseNoteType = () => {
    setIsNoteTypeDialogOpened(false);
    setNoteType(NOTE_TYPES.PRIVATE);
  };

  const onSelectNoteType = selectedType => setNoteType(selectedType);

  const onOpenManageNote = () => {
    setIsManageNoteOpened(true);
    setIsNoteTypeDialogOpened(false);
  };

  const onCloseManageNote = () => {
    setIsManageNoteOpened(false);
    setNote(null);
    setNoteType(NOTE_TYPES.PRIVATE);
  };

  const handleSave = values => {
    const noteData = prepareNoteForSave(values, isEdit, user);

    if (isEdit) {
      return editNote(dispatch, noteData).then(() => {
        showSuccessMessage(translations.manageNotesDialog.successMessages.edit);
      });
    }

    return createNote(dispatch, noteData).then(() => {
      showSuccessMessage(translations.manageNotesDialog.successMessages.add);
    });
  };

  const handleEditNote = selectedNote => {
    setNote(selectedNote);
    setIsManageNoteOpened(true);
  };

  const handleOpenDelete = selectedNote => {
    setNote(selectedNote);
    setIsDeleteDialogOpened(true);
  };

  const handleDelete = () => setIsDeleteDialogOpened(true);

  const handleCloseDelete = () => setIsDeleteDialogOpened(false);

  const onDeleteNote = async () => {
    try {
      await deleteNote(dispatch, note.id);
      showSuccessMessage(translations.noteDeleteDialog.deleteSuccess);
    } finally {
      setNote(null);
      setIsManageNoteOpened(false);
      setIsDeleteDialogOpened(false);
    }
  };

  const onCreateTag = async tagName => {
    const tags = await onSaveCreatableTag(tagName);
    const [tag] = tags.create;

    dispatch(addMultipleTags({ categoryId: GENERAL_CATEGORY_ID, tags }));

    return tag;
  };

  const handleTaskStatusChange = (currentNote, task) =>
    editTaskStatus(dispatch, currentNote, task);

  const checkCanManageNote = currentNote =>
    isAdmin || currentNote.creator?.id === auth.id;

  const checkCanManageTask = task =>
    isAdmin || task.creator === auth.id || task.user.id === auth.id;

  const canSeeUserProfileCheck = currentUser =>
    canSeeEmployeeProfile(
      auth,
      currentUser.id,
      organizationSettings.global_see_himself
    );

  const handleClearNotifications = () =>
    navigate(location.pathname, {
      replace: true,
      state: null,
    });

  return (
    <PeoplePageContainer
      {...rest}
      translations={translations}
      auth={auth}
      userId={userId}
      organizationSettings={organizationSettings}
      navigate={navigate}
      location={location}
      shouldPassProps={false}
    >
      <div>
        {!isInitialLoad && (
          <div className={classes.header}>
            <Typography
              className={classNames({ [classes.description]: canAddNote })}
              variant="body2"
            >
              {translations.description}
            </Typography>
            {canAddNote && (
              <div className={classes.descriptionWrap}>
                <ConditionalTooltip
                  className={classes.disabledUserAction}
                  message={translations.disabledAddActionTooltip}
                  addTooltip={isDeactivatedUser}
                >
                  <CustomButton
                    type="addRoundedNew"
                    disabled={isDeactivatedUser}
                    onClick={onOpenNoteType}
                  >
                    {translations.addNoteButton}
                  </CustomButton>
                </ConditionalTooltip>
              </div>
            )}
          </div>
        )}
        <NotesList
          translations={translations.note}
          notes={notes}
          notifications={location?.state?.notifications}
          currentUser={auth}
          canSeeUserProfileCheck={canSeeUserProfileCheck}
          goToUserProfilePage={goToUserProfilePage}
          checkCanManageNote={checkCanManageNote}
          checkIsDisabledEdit={isUserDeactivated}
          checkCanManageTask={checkCanManageTask}
          canAccessUserProfile={isUserProfileAccessible(
            auth,
            organizationSettings.global_see_himself
          )}
          onTaskStatusChange={handleTaskStatusChange}
          onEdit={handleEditNote}
          onDelete={handleOpenDelete}
          onClearNotifications={handleClearNotifications}
        />
        <NotificationCard
          shouldFade={!isInitialLoad && isArrayEmpty(notes)}
          title={translations.noResults.title}
          content={translations.noResults.message}
        />
        <AlertDialog
          translations={translations.noteDeleteDialog}
          isOpened={isDeleteDialogOpened}
          onClose={handleCloseDelete}
          onConfirm={onDeleteNote}
          isWarning
        />
        <ChooseTypeDialog
          translations={translations.noteTypeDialog}
          isOpened={isNoteTypeDialogOpened}
          types={noteTypes}
          selectedType={noteType}
          onSelect={onSelectNoteType}
          onCancel={onCloseNoteType}
          onConfirm={onOpenManageNote}
        />
        <CustomFormDrawer
          customFormClass={classes.form}
          translations={translations.manageNotesDialog}
          getTitle={getNoteFormTitle}
          initialData={getManageNoteInitialData(note, isEdit, isPrivate)}
          isOpened={isManageNoteOpened}
          isInitialValid={isEdit}
          fields={getManageNoteFields(classes, onCreateTag, isPrivate)}
          categories={categories}
          allUsers={availableTaskUsers}
          onClose={onCloseManageNote}
          onSave={handleSave}
          onDelete={handleDelete}
          hasCancelButton
          isMiddleWidth
        />
      </div>
    </PeoplePageContainer>
  );
};

PeopleNotesPage.propTypes = {
  auth: PropTypes.object.isRequired,
  organizationSettings: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  navigate: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default memo(PeopleNotesPage);
