import { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';
import CustomModal from '../customModal';
import UsersList from '../usersList';
import SelectField from '../selectField';
import CustomScrollBar from '../customScrollBar';
import http from '../../../utility/http';
import { getObjectToNumberArray, isArrayEmpty } from '../../../utility/helpers';
import { useAvailableUsers } from '../../../utility/hooks';
import { PARAMS } from '../../../constants/pages';
import { API_USERS_MULTIPLE } from '../../../constants/apiRoutes';

const useStyles = makeStyles(({ spacing }) => ({
  description: {
    marginBottom: spacing(4),
  },
  users: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: 188,
  },
  managersWrapper: {
    width: '100%',
    marginTop: spacing(4),
  },
  scrollY: {
    top: 0,
    right: -21,
    height: '100%',
  },
}));

const TransferEmployeesDialog = ({
  translations,
  isOpened,
  currentUserId,
  organizationUser,
  users,
  onClose,
  onConfirm,
}) => {
  const classes = useStyles();
  const [selectedManagerId, setSelectedManagerId] = useState(null);

  const params = useMemo(() => {
    if (!isOpened) return {};

    return currentUserId !== -1 && !isArrayEmpty(users)
      ? {
          [PARAMS.AVAILABLE_MANAGERS]: [
            currentUserId,
            ...getObjectToNumberArray(users),
          ],
        }
      : {};
  }, [isOpened, users, currentUserId]);

  const availableManagers = useAvailableUsers(params, organizationUser);

  const handleSelect = managerId => setSelectedManagerId(managerId);

  const handleClose = useCallback(() => {
    setSelectedManagerId(null);
    onClose();
  }, [onClose]);

  const handleSave = async () => {
    const transferData = users.reduce(
      (acc, user) => [
        ...acc,
        {
          id: user.id,
          report_to: selectedManagerId,
        },
      ],
      []
    );
    await http.patch(API_USERS_MULTIPLE, transferData);
    await onConfirm();
    handleClose();
  };

  return (
    <CustomModal
      title={translations.title}
      isOpened={isOpened}
      confirmButtonLabel={translations.save}
      closeButtonLabel={translations.cancel}
      isConfirmDisabled={!selectedManagerId}
      onClose={handleClose}
      onConfirm={handleSave}
      isMedium
    >
      <div>
        <Typography className={classes.description}>
          {translations.description}
        </Typography>
        <div className={classes.users}>
          {!isArrayEmpty(users) && (
            <CustomScrollBar
              customScrollBarYClass={classes.scrollY}
              passContentHeight
              verticalScroll
              removeScrollX
            >
              <UsersList users={users} isVerticalList />
            </CustomScrollBar>
          )}
        </div>
        <SelectField
          className={classes.managersWrapper}
          label={translations.label}
          placeholder={translations.placeholder}
          value={selectedManagerId}
          options={availableManagers}
          parser={{
            value: 'id',
            label: 'first_name',
          }}
          onChange={handleSelect}
          isUser
        />
      </div>
    </CustomModal>
  );
};

TransferEmployeesDialog.defaultProps = {
  currentUserId: -1,
  organizationUser: {},
};

TransferEmployeesDialog.propTypes = {
  translations: PropTypes.object.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentUserId: PropTypes.number,
  organizationUser: PropTypes.object,
  isOpened: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default TransferEmployeesDialog;
