import { useState, memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Collapse, alpha, makeStyles } from '@material-ui/core';
import DotsMenu from '../../dotsMenu';
import { getDotsMenuItems } from '../../../../utility/leveling';
import CustomButton from '../../customButton';
import TextBoxWithTooltip from '../../textBoxWithTooltip';
import ActionButton from '../../actionButton';
import { isArrayEmpty } from '../../../../utility/helpers';
import { ACTION_BUTTON_TYPES } from '../../actionButton/config';
import LevelingTracks from './levelingTracks';

const useStyles = makeStyles(
  ({ palette: { primary }, spacing, breakpoints }) => ({
    header: {
      backgroundColor: primary.bluish8,
      borderRadius: 8,
      boxSizing: 'border-box',
      border: `1px solid ${primary.bluish6}`,
      marginBottom: spacing(4),
      cursor: 'pointer',
      alignItems: 'center',
      height: 40,
      display: 'grid',
      gridTemplateColumns:
        'minmax(30px, 30px) minmax(140px, 1487px) minmax(60px, 60px)',
      [breakpoints.up('xLg')]: {
        gridTemplateColumns:
          'minmax(30px, 30px) minmax(140px, 2150px) minmax(60px, 60px)',
      },
      '& > div:first-of-type': {
        opacity: 0,
        transition: 'opacity .3s ease',
      },
      '&:hover': {
        '& > div:first-of-type': {
          opacity: ({ isVisible }) => {
            return isVisible ? 1 : 0;
          },
          transition: 'opacity .3s ease',
          cursor: ({ isVisible }) => {
            return isVisible ? 'pointer' : 'grabbing';
          },
        },
        cursor: ({ isVisible }) => {
          return isVisible ? 'pointer' : 'grabbing';
        },
        boxShadow: `0px 2px 8px 0px ${alpha(primary.black, 0.17)}`,
        transition: 'box-shadow .3s ease',
      },
      '&:active': {
        '& > div:first-of-type': {
          opacity: 1,
          transition: 'opacity .3s ease',
        },
        boxShadow: `0px 2px 8px 0px ${alpha(primary.black, 0.17)}`,
        transition: 'box-shadow .3s ease',
      },
    },
    visibleDragDropButton: {
      '& > div:first-of-type': {
        opacity: 0,
        transition: 'opacity .3s ease',
      },
    },
    headerReadOnly: {
      width: 1121,
      gridTemplateColumns: 'minmax(90px, 1100px) minmax(60px, 60px)',
      [breakpoints.up('xLg')]: {
        gridTemplateColumns: 'minmax(90px, 2100px) minmax(60px, 60px)',
      },
    },
    headerDisabled: {
      gridTemplateColumns: 'minmax(90px, 1441px) minmax(30px, 40px)',
      [breakpoints.up('xLg')]: {
        gridTemplateColumns: 'minmax(90px, 2156px) minmax(30px, 40px)',
      },
    },
    visibleCollapseButton: {
      '& > div:first-of-type': {
        opacity: 1,
        transition: 'opacity .3s ease',
      },
    },
    disableCollapse: {
      cursor: 'default',
      pointerEvents: 'none',
    },
    actionsWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginRight: spacing(2),
      justifyContent: 'space-between',
    },
    templateActionButton: {
      marginRight: 0,
    },
    actionButton: {
      width: 24,
      height: 24,
    },
    expandIcon: {
      transform: 'rotate(0deg)',
      transition: 'transform .3s cubic-bezier(0.77, 0.2, 0.05, 1)',
    },
    expandIconOpened: {
      transform: 'rotate(180deg)',
    },
    titleText: {
      fontSize: 14,
      lineHeight: '18px',
      marginLeft: spacing(8),
    },
    titleWithDragButton: {
      marginLeft: 2,
    },
    button: {
      borderRadius: 8,
      marginBottom: spacing(4),
      marginTop: spacing(1),
      width: '100%',
      justifyContent: 'center',
    },
    dragButton: {
      position: 'relative',
      width: 22,
      height: 22,
      marginLeft: spacing(2),
      flexShrink: 0,
      marginRight: spacing(1.5),
      zIndex: 11,
      '& button': {
        width: 22,
        height: 22,
      },
    },
    dragIcon: {
      width: 8,
      height: 14,
    },
    dragTooltipLabel: {
      fontFamily: 'ProximaNova-Regular',
    },
    tracksWrapper: {
      marginLeft: 40,
    },
    disabledActions: {
      cursor: 'default',
      justifyContent: 'end',
    },
  })
);

const LevelingFramework = ({
  translations,
  levelingFramework,
  dragRef,
  dragProps,
  isReadOnly,
  onDuplicateFramework,
  onDuplicateTrack,
  onEditFrameworkTitle,
  onEditTrackTitle,
  handleDeleteFrameworkDialog,
  handleDeleteTrackDialog,
  handleCreateLevel,
  handleCreateTrack,
  onReorderTrack,
  handleMoveTrack,
  onReorderLevel,
  isExpandedOnFirstOpen,
  toggleExpansion,
  isExpandedOnFirstOpenFramework,
  toggleExpansionFramework,
  isVisible,
  isAdmin,
}) => {
  const classes = useStyles({ isVisible });
  const { name, tracks } = levelingFramework;
  const hasTracks = !isArrayEmpty(tracks);
  const [isExpanded, setIsExpanded] = useState(
    isExpandedOnFirstOpenFramework || false
  );
  const isExpandedDropdown = isExpanded || isExpandedOnFirstOpenFramework;
  const toggleExpand = () => {
    if (isExpandedOnFirstOpenFramework) {
      toggleExpansionFramework(false);
      setIsExpanded(false);
    }
    if (!isExpandedOnFirstOpenFramework) {
      setIsExpanded(prevExpanded => !prevExpanded);
    }
  };

  return (
    <div ref={dragRef} {...(dragProps ? { ...dragProps.draggableProps } : {})}>
      <div
        className={classNames(classes.header, {
          [classes.headerReadOnly]: isReadOnly,
          [classes.headerDisabled]: !isAdmin,
          [classes.visibleCollapseButton]: isReadOnly || !isAdmin,
          [classes.disableCollapse]: !isAdmin && !hasTracks,
        })}
        onClick={toggleExpand}
      >
        {!isReadOnly && isAdmin && (
          <ActionButton
            className={classes.dragButton}
            iconClass={classes.dragIcon}
            tooltipLabelClass={classes.dragTooltipLabel}
            type={ACTION_BUTTON_TYPES.DRAG}
            tooltipText={translations.frameworkDragTooltip}
            {...(dragProps ? { ...dragProps.dragHandleProps } : {})}
          />
        )}
        <TextBoxWithTooltip
          className={classNames(classes.titleText, {
            [classes.titleWithDragButton]: !isReadOnly && isAdmin,
          })}
          variant="h5"
          text={name}
        />
        <div
          className={classNames(classes.actionsWrapper, {
            [classes.disabledActions]: isReadOnly || !hasTracks,
          })}
        >
          {isAdmin && !isReadOnly && (
            <DotsMenu
              menuItems={getDotsMenuItems(
                translations,
                levelingFramework,
                levelingFramework,
                handleDeleteFrameworkDialog,
                onEditFrameworkTitle,
                onDuplicateFramework
              )}
              isDisabled={isReadOnly}
            />
          )}
          {!hasTracks && !isAdmin ? null : (
            <ActionButton
              className={classNames(
                { [classes.templateActionButton]: isReadOnly },
                classes.actionButton
              )}
              type={ACTION_BUTTON_TYPES.ARROW}
              iconClass={classNames(classes.expandIcon, {
                [classes.expandIconOpened]: isExpandedDropdown,
              })}
            />
          )}
        </div>
      </div>
      <Collapse in={isExpandedDropdown} timeout="auto" unmountOnExit>
        <div className={classes.tracksWrapper}>
          {hasTracks && (
            <LevelingTracks
              translations={translations}
              isAdmin={isAdmin}
              levelingTracks={levelingFramework?.tracks}
              levelingFramework={levelingFramework}
              onEditTrackTitle={onEditTrackTitle}
              onDuplicateTrack={onDuplicateTrack}
              onReorderTrack={onReorderTrack}
              handleDeleteTrackDialog={handleDeleteTrackDialog}
              onReorderLevel={onReorderLevel}
              handleCreateLevel={handleCreateLevel}
              handleMoveTrack={handleMoveTrack}
              isReadOnly={isReadOnly}
              isExpandedOnFirstOpen={isExpandedOnFirstOpen}
              toggleExpansion={toggleExpansion}
            />
          )}
          {!isReadOnly && isAdmin && (
            <CustomButton
              className={classes.button}
              type="addWithTextDarkNewMedium"
              onClick={() => handleCreateTrack(levelingFramework)}
            >
              {translations.createTrackButton}
            </CustomButton>
          )}
        </div>
      </Collapse>
    </div>
  );
};

LevelingFramework.defaultProps = {
  onDuplicateFramework: () => {},
  onDuplicateTrack: () => {},
  onReorderLevel: () => {},
  onReorderTrack: () => {},
  handleCreateLevel: () => {},
  handleCreateTrack: () => {},
  handleMoveTrack: () => {},
  handleDeleteFrameworkDialog: () => {},
  handleDeleteTrackDialog: () => {},
  onEditFrameworkTitle: () => {},
  onEditTrackTitle: () => {},
  dragRef: () => {},
  dragProps: null,
  isExpandedOnFirstOpen: false,
  toggleExpansion: () => {},
  isExpandedOnFirstOpenFramework: false,
  toggleExpansionFramework: () => {},
};

LevelingFramework.propTypes = {
  translations: PropTypes.object.isRequired,
  levelingFramework: PropTypes.object.isRequired,
  isVisible: PropTypes.bool.isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  onDuplicateFramework: PropTypes.func,
  onDuplicateTrack: PropTypes.func,
  onReorderLevel: PropTypes.func,
  onReorderTrack: PropTypes.func,
  handleCreateLevel: PropTypes.func,
  handleDeleteFrameworkDialog: PropTypes.func,
  handleDeleteTrackDialog: PropTypes.func,
  handleCreateTrack: PropTypes.func,
  handleMoveTrack: PropTypes.func,
  onEditFrameworkTitle: PropTypes.func,
  onEditTrackTitle: PropTypes.func,
  dragProps: PropTypes.object,
  dragRef: PropTypes.func,
  isExpandedOnFirstOpen: PropTypes.bool,
  toggleExpansion: PropTypes.func,
  isExpandedOnFirstOpenFramework: PropTypes.bool,
  toggleExpansionFramework: PropTypes.func,
  isAdmin: PropTypes.bool.isRequired,
};

export default memo(LevelingFramework);
