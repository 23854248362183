import { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { ReactComponent as DropdownIcon } from '../../../assets/icons/arrow-down-dark.svg';
import { isArrayEmpty } from '../../../utility/helpers';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  root: {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    padding: spacing(1, 2),
    width: 'fit-content',
  },
  status: {
    color: 'inherit',
    display: 'flex',
    alignItems: 'center',
    fontSize: 10,
    lineHeight: '12px',
    textTransform: 'uppercase',
    userSelect: 'none',
  },
  clickable: {
    cursor: 'pointer',
  },
  dropdownIcon: {
    flexShrink: 0,
    marginLeft: spacing(1.5),
    cursor: 'pointer',
    '& path': {
      fill: ({ status }) =>
        status?.value === 1 ? primary.blue1 : primary.white,
    },
  },
  toDo: {
    background: primary.bluish8,
    color: primary.blue1,
  },
  done: {
    background: primary.bluish1,
    color: primary.white,
  },
  completed: {
    background: primary.bluish1,
    color: primary.white,
  },
  inProgress: {
    background: primary.orange,
    color: primary.white,
  },
  draft: {
    background: primary.bluish8,
    color: primary.bluish1,
  },
  scheduled: {
    background: primary.bluish8,
    color: primary.blue1,
  },
  activeWithoutAccess: {
    backgroundColor: primary.white,
    border: `1px solid ${primary.green1}`,
    color: primary.green1,
  },
  activeInvited: {
    backgroundColor: primary.white,
    border: `1px solid ${primary.green1}`,
    color: primary.green1,
  },
  activeWithAccess: {
    backgroundColor: primary.green1,
    border: `1px solid ${primary.green1}`,
    color: primary.white,
  },
  deactivated: {
    backgroundColor: primary.bluish8,
    color: primary.bluish1,
  },
  menuItem: {
    fontFamily: 'ProximaNova-Bold',
  },
}));

const StatusIndicator = ({
  className,
  status,
  statuses,
  isSelectable,
  onSelect,
}) => {
  const classes = useStyles({ status });

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = e => {
    if (!isSelectable) return;

    e.stopPropagation();
    setAnchorEl(e.currentTarget);
  };

  const handleClose = e => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const handleSelect = e => {
    const { value } = e.target;

    if (value !== status.value) {
      onSelect(value);
    }

    handleClose(e);
  };

  return (
    <div
      className={classNames(
        classes.root,
        {
          [classes[status?.key]]: status,
        },
        className
      )}
      onClick={handleOpen}
    >
      <Typography
        variant="overline"
        className={classNames(classes.status, {
          [classes.clickable]: isSelectable,
        })}
      >
        {status.name}
      </Typography>
      {isSelectable && <DropdownIcon className={classes.dropdownIcon} />}
      {isSelectable && !isArrayEmpty(statuses) && (
        <Menu
          anchorEl={anchorEl}
          open={!!anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          onClose={handleClose}
        >
          {statuses.map(s => (
            <MenuItem
              key={s.key}
              classes={{ root: classes.menuItem }}
              value={s.value}
              selected={s.value === status.value}
              onClick={handleSelect}
            >
              {s.name}
            </MenuItem>
          ))}
        </Menu>
      )}
    </div>
  );
};

StatusIndicator.defaultProps = {
  className: null,
  status: null,
  statuses: [],
  isSelectable: false,
  onSelect: () => {},
};

StatusIndicator.propTypes = {
  className: PropTypes.string,
  status: PropTypes.shape({}),
  statuses: PropTypes.arrayOf(PropTypes.shape({})),
  isSelectable: PropTypes.bool,
  onSelect: PropTypes.func,
};

export default StatusIndicator;
