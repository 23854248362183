import { useState } from 'react';
import PropTypes from 'prop-types';
import { Fade, makeStyles } from '@material-ui/core';
import NotificationButton from '../notificationButton';
import AsyncListTooltip from '../asyncListTooltip';
import UpdateItem from './updateItem';

const useStyles = makeStyles(({ palette: { primary }, spacing }) => ({
  markAsReadButton: {
    border: `1px solid ${primary.bluish1}`,
    borderRadius: 44,
    color: primary.bluish1,
    fontFamily: 'ProximaNova-Bold',
    lineHeight: '24px',
    justifySelf: 'center',
    padding: spacing(1, 2),
    maxHeight: 32,
  },
}));

const UpdatesInfo = ({
  className,
  translations,
  updates,
  asyncListProps,
  isUpdate,
  hasUpdates,
  onClearAll,
}) => {
  const classes = useStyles();
  const [isOpenedView, setIsOpenedView] = useState(true);

  const updatesCount = updates?.length || 0;

  const onToggleView = value => {
    setIsOpenedView(value);
  };

  return (
    <Fade in={hasUpdates} unmountOnExit>
      <div className={className}>
        <AsyncListTooltip
          title={isUpdate ? translations.title : ''}
          tooltipButtonLabel={translations.markAsRead}
          labelData={updates}
          labelComponent={UpdateItem}
          tooltipButtonProps={{
            className: classes.markAsReadButton,
            type: 'addWithTextRounded',
          }}
          onTooltipButtonClickHandler={onClearAll}
          onToggleView={onToggleView}
          hasTooltipButton
          isDisabled
          {...asyncListProps}
        >
          <NotificationButton
            tooltipText={isOpenedView ? translations.updatesButtonTooltip : ''}
            notificationCount={updatesCount}
          />
        </AsyncListTooltip>
      </div>
    </Fade>
  );
};

UpdatesInfo.defaultProps = {
  className: '',
  updates: [],
  asyncListProps: {},
  isUpdate: false,
};

UpdatesInfo.propTypes = {
  className: PropTypes.string,
  translations: PropTypes.object.isRequired,
  updates: PropTypes.arrayOf(PropTypes.string),
  asyncListProps: PropTypes.shape({}),
  isUpdate: PropTypes.bool,
  hasUpdates: PropTypes.bool.isRequired,
  onClearAll: PropTypes.func.isRequired,
};

export default UpdatesInfo;
