export const LEVELING_TEMPLATES = {
  AMAZON: {
    title: `Based on <strong>Amazon</strong>'s levelling`,
    description:
      'Ideal for large tech companies, where a successful career path ends with managerial positions. Seniority is defined through years of experience, as well as required knowledge and skills.',
    frameworks: [
      {
        id: 1,
        name: 'Amazon framework',
        order: 1,
        tracks: [
          {
            id: 1,
            name: 'Job Title and Description for Engineering',
            order: 1,
            levels: [
              {
                id: 1,
                name: 'Software Development Engineer I',
                description:
                  '<ul><li><strong>Required years of experience:</strong> 2 or' +
                  ' more</li><li><strong>Required knowledge and skills:</strong>' +
                  ' object-oriented concepts, system design and architecture, data ' +
                  'structures, UNIX/Linux operating systems, and technical/product' +
                  ' development processes</li></ul>',
                order: 1,
              },
              {
                id: 2,
                name: 'Software Development Engineer II',
                description:
                  '<ul><li><strong>Required years of experience:</strong> 4 or' +
                  ' more</li><li><strong>Required knowledge and skills:</strong>' +
                  ' proficient in programming languages like Java, C/C++, Python,' +
                  ' JavaScript, Objective C, technologies like Linux, Spring, Machine' +
                  ' Learning, Big Data, JQuery; mentoring L4, strong communication' +
                  ' skills to convey technical concepts to non-technical teams</li></ul>',
                order: 2,
              },
              {
                id: 3,
                name: 'Software Development Engineer III',
                description:
                  '<ul><li><strong>Required years of experience:</strong> 8 or' +
                  ' more</li><li><strong>Required knowledge and skills:</strong>' +
                  ' proficiency in C, C++, or Java, in-depth knowledge of the' +
                  ' fundamentals of object-oriented analysis and design (OOA/OOD),' +
                  ' system design and architecture, algorithm design, complexity' +
                  ' analysis, deep understanding of the full software development ' +
                  'life cycle; recruiting and mentoring L4 and L5, tech leadership,' +
                  ' driving business growth through best engineering practices</li></ul>',
                order: 3,
              },
              {
                id: 4,
                name: 'Principal Software Development Engineer',
                description:
                  '<ul><li><strong>Required years of experience:</strong> 10 or' +
                  ' more</li><li><strong>Required knowledge and skills:</strong>' +
                  ' proven track record in dealing with high-quality systems with a ' +
                  'focus on scalability, customer satisfaction, and automation; ' +
                  'strong communication skills, problem-solving abilities, and ' +
                  'organizational skills; in charge of large teams and large-scale ' +
                  'projects, responsible for making important technical and ' +
                  'architectural decisions</li></ul>',
                order: 4,
              },
              {
                id: 5,
                name: 'Senior Software Development Engineer',
                description:
                  '<ul><li><strong>Required years of experience:</strong> 10 or more in' +
                  ' complex, service-oriented software development</li><li><strong>' +
                  'Required knowledge and skills:</strong> well-versed with C/C++, ' +
                  'Java, Ruby, Scala, and Python, delivering technical projects in ' +
                  'an Agile environment, leading junior SDEs, effectively communicating' +
                  ' technical decisions, and driving engineering excellence; in charge ' +
                  'of highly complex systems as leader and mentor</li></ul>',
                order: 5,
              },
              {
                id: 6,
                name: 'Distinguished Software Development Engineer',
                description:
                  '<ul><li><strong>Required years of experience:</strong> 5 or more as an L8' +
                  '</li><li><strong>Required knowledge and skills:</strong>' +
                  ' proven track record of building, launching, and maintaining ' +
                  'successful technical projects; leading and mentoring massive ' +
                  'technical teams, providing performance evaluation, and implementing' +
                  ' best engineering practices that drive innovation; well-versed with ' +
                  'heterogeneous systems, hardware, and software required in cloud-scale' +
                  ' infrastructure</li></ul>',
                order: 6,
              },
            ],
          },
        ],
      },
    ],
  },
  CARTA: {
    title: `Based on <strong>Carta</strong>'s levelling`,
    description:
      'Ideal for mid-sized companies, across all industries. Seniority is defined through universal criteria that can be used across all departments. Startups can also use the template as a basis, filling it in with new employees as they grow.',
    frameworks: [
      {
        id: 1,
        name: 'Carta framework',
        order: 1,
        tracks: [
          {
            id: 1,
            name: 'Individual Contributor Track',
            order: 1,
            levels: [
              {
                id: 1,
                name: 'Intern',
                description:
                  '<ul><li><strong>Global title and role description:</strong>' +
                  ' University Student/Co-op Participant</li><li><strong>Job' +
                  ' complexity:</strong> Completes narrowly focused tasks or projects. ' +
                  'Complements academic studies with on-the-job experience and incorporates' +
                  ' learnings into coursework</li><li><strong>Autonomy and' +
                  ' scope:</strong> Receives specific direction and guidance on a defined' +
                  ' set of problems over a pre-determined window of time</li><li><strong>Leadership' +
                  '</strong></li></ul>',
                order: 1,
              },
              {
                id: 2,
                name: 'Entry',
                description:
                  '<ul><li><strong>Global title and role description:</strong>' +
                  ' Acquires job skills and learns company policies and procedures' +
                  ' to complete routine tasks</li><li><strong>Job complexity:</strong> ' +
                  'Works on assignments that are routine in nature, requiring limited' +
                  ' judgment</li><li><strong>Autonomy and scope:</strong> Normally ' +
                  'receives detailed instructions on all work. Requires supervision ' +
                  'over both routine and non-routine work</li><li><strong>Leadership' +
                  '</strong></li></ul>',
                order: 2,
              },
              {
                id: 3,
                name: 'Mid 1 (Developing)',
                description:
                  '<ul><li><strong>Global title and role description:</strong>' +
                  ' Learns to use professional concepts. Applies team procedures to ' +
                  'solve routine problems</li><li><strong>Job complexity:</strong> ' +
                  'Works on problems of limited scope. Follows standard practices and' +
                  ' procedures to solve problems. Builds stable working relationships' +
                  ' internally to further problem-solving</li><li><strong>Autonomy and' +
                  ' scope:</strong> Normally receives detailed instructions on all ' +
                  'work. Requires supervision over non-routine work</li><li><strong>' +
                  'Leadership</strong></li></ul>',
                order: 3,
              },
              {
                id: 4,
                name: 'Mid 2 (Proficient)',
                description:
                  '<ul><li><strong>Global title and role description:</strong>' +
                  ' Developing professional expertise, applying company policies and' +
                  ' procedures to resolve a variety of issues</li><li><strong>Job ' +
                  'complexity:</strong> Works on problems of moderate scope and calls' +
                  ' upon multiple known practices and procedures to solve problems. ' +
                  'Exercises judgment within defined procedures and practices to resolve' +
                  ' day-to-day problems. Builds productive internal/external working ' +
                  'relationships</li><li><strong>Autonomy and scope:</strong> Normally' +
                  ' receives general instructions on routine work and detailed instructions' +
                  ' on new projects or assignments</li><li><strong>Leadership</strong></li></ul>',
                order: 4,
              },
              {
                id: 5,
                name: 'Senior (Skilled)',
                description:
                  '<ul><li><strong>Global title and role description:</strong>' +
                  ' A seasoned, experienced professional with a full understanding of' +
                  ' the area of specialization; resolves a wide range of issues in ' +
                  'creative ways</li><li><strong>Job complexity:</strong> Works on ' +
                  'problems of diverse scope and has a developed sense of how to solve' +
                  ' problems when in unknown territory. Demonstrates good judgment in' +
                  ' selecting methods and techniques for obtaining solutions and ' +
                  'escalating issues. Networks with senior internal and external ' +
                  'personnel in their own area of expertise</li><li><strong>Autonomy' +
                  ' and scope:</strong> Normally receives minimal instruction on day' +
                  '-to-day work. Seeks general instructions on new assignments</li>' +
                  '<li><strong>Leadership:</strong> Has some influence in planning and' +
                  ' timelines. Gives actionable feedback that drives improvement. ' +
                  'Coaches and mentors less experienced team members</li></ul>',
                order: 5,
              },
              {
                id: 6,
                name: 'Lead (Advanced)',
                description:
                  '<ul><li><strong>Global title and role description:</strong>' +
                  ' Having wide-ranging experience, uses professional concepts and ' +
                  'company objectives to resolve complex issues in creative and ' +
                  'effective ways</li><li><strong>Job complexity:</strong> Works on ' +
                  'complex issues where analysis of situations or data requires an ' +
                  'in-depth evaluation of variable factors. Exercises judgment in ' +
                  'selecting methods, techniques, and evaluation criteria for obtaining' +
                  ' results. Networks with key experts outside their own area of expertise' +
                  '</li><li><strong>Autonomy and scope:</strong> Requires little oversight' +
                  ' and reviewed only for technical integrity. Determines methods and ' +
                  'procedures on new assignments and may coordinate activities of other' +
                  ' personnel</li><li><strong>Leadership:</strong> Helps others to learn' +
                  ' and grow. Creates an environment to support a successful team. Coaches' +
                  ' and mentors others within an immediate team</li></ul>',
                order: 6,
              },
              {
                id: 7,
                name: 'Staff (Expert)',
                description:
                  '<ul><li><strong>Global title and role description:</strong>' +
                  ' Having broad expertise or specialized knowledge, uses skills to' +
                  ' contribute to the development of company objectives and principles ' +
                  'and to achieve goals in creative and effective ways</li><li><strong>' +
                  'Job complexity:</strong> Works on significant and unique issues' +
                  ' where analysis of situations or data requires an evaluation of ' +
                  'intangibles. Exercises independent judgment in methods, techniques,' +
                  ' and evaluation criteria for obtaining results. Creates formal networks' +
                  ' involving coordination among groups</li><li><strong>Autonomy and' +
                  ' scope:</strong> Acts independently to determine methods and procedures' +
                  ' on new or special assignments. May supervise the activities of others' +
                  '</li><li><strong>Leadership:</strong> Influences others to achieve' +
                  ' team goals. Coaches and mentors others within immediate and peer' +
                  ' teams. Supports a culture that is positive, inclusive, and' +
                  ' engaging</li></ul>',
                order: 7,
              },
              {
                id: 8,
                name: 'Senior Staff (Advisor)',
                description:
                  '<ul><li><strong>Global title and role description:</strong>' +
                  ' As an expert in the field, uses professional concepts in developing' +
                  ' resolutions to critical issues and broad design matters</li><li><strong>' +
                  'Job complexity:</strong> Works on issues that directly impact current and' +
                  ' future business success. Creates formal networks with key decision makers' +
                  ' and serves as external spokesperson for the organization</li><li><strong>' +
                  'Autonomy and scope:</strong> Exercises wide latitude in determining ' +
                  'objectives and approaches to critical assignments</li><li><strong>' +
                  'Leadership:</strong> Leads by example in modeling the' +
                  " company's operating principles and identity traits and holds others" +
                  ' accountable for doing the same. Delegates effectively and empowers' +
                  ' others to execute tasks with accountability</li></ul>',
                order: 8,
              },
              {
                id: 9,
                name: 'Principal (Strategist)',
                description:
                  '<ul><li><strong>Global title and role description:</strong>' +
                  ' A distinguished technical professional who guides a specialized ' +
                  'and/or strategic functional/product roadmap; viewed as an expert ' +
                  'in their field</li><li><strong>Job complexity:</strong> Generally' +
                  ' oversees creative research or advanced development in areas such' +
                  ' as new technologies, product innovation, engineering materials, ' +
                  'or next-generation processes. (Some organizations only allow ' +
                  'individual contributors at this level in the engineering area,' +
                  ' but others allow it anywhere in the company</li><li><strong>Autonomy' +
                  ' and scope:</strong> Directs and coordinates activities necessary' +
                  ' to complete major projects, requiring a wide variety of technical' +
                  ' skills. Consults with and provides guidance to senior management ' +
                  'and a number of departments on advanced technical issues</li><li><strong>' +
                  "Leadership:</strong> A role model for the company's" +
                  ' operating principles' +
                  'and identity traits. Builds community across functional and product lines</li></ul>',
                order: 9,
              },
              {
                id: 10,
                name: 'Distinguished (Thought Leader)',
                description:
                  '<ul><li><strong>Global title and role description:</strong>' +
                  ' Top individual contributor technical position in the organization' +
                  ' who brings domain expertise and impact equivalent to that of a VP; a' +
                  ' known expert in their field</li><li><strong>Job complexity:</strong>' +
                  ' Oversees applied research or advanced engineering in the development' +
                  ' of new products, materials, or processes</li><li><strong>Autonomy and' +
                  ' scope:</strong> Directs and coordinates activities necessary to complete' +
                  ' major projects, requiring a wide variety of technical skills. Consults with' +
                  ' and provides guidance to senior management and a number of departments on' +
                  ' advanced technical issues</li><li><strong>Leadership:</strong>' +
                  " Champions the company's operating principles and identity traits" +
                  ' and holds leaders accountable to demonstrate them. Sets an inspiring' +
                  " vision and strategic direction that's in line with the" +
                  ' company mission</li></ul>',
                order: 10,
              },
            ],
          },
          {
            id: 2,
            name: 'Manager track',
            order: 2,
            levels: [
              {
                id: 1,
                name: 'Team Lead',
                description:
                  '<ul><li><strong>Global title and role description:</strong>' +
                  ' Provides supervision to individual contributors (ICs), primarily' +
                  ' in terms of work output and ability to meet team/role objectives.' +
                  ' Acts as advisor to unit or sub-units and may become actively involved,' +
                  ' as required, to meet schedules and resolve problems</li><li><strong>' +
                  'Job complexity:</strong> Works on issues within a defined team/' +
                  'procedural framework. Exercises judgment within defined procedures' +
                  ' to determine appropriate action</li><li><strong>Autonomy and' +
                  ' scope:</strong> Receives defined objectives and processes by which' +
                  ' to meet goals. Provides direction to employees according to established' +
                  ' policies and management guidance. Management reviews work to measure' +
                  ' the meeting of objectives</li><li><strong>Leadership</strong></li></ul>',
                order: 1,
              },
              {
                id: 2,
                name: 'Manager',
                description:
                  '<ul><li><strong>Global title and role description:</strong>' +
                  ' Manages, perhaps through other Team Leads, the coordination of the' +
                  ' activities of a section or department with responsibility for' +
                  ' results</li><li><strong>Job complexity:</strong> Works on issues' +
                  ' of diverse scope and is familiar with current business trends. ' +
                  'Follows processes and operational frameworks to solve problems. ' +
                  'Acts as advisor to reports to meet goals and resolve problems. ' +
                  'Develops and administers goals, schedules, and performance requirements;' +
                  ' may have budget responsibilities</li><li><strong>Autonomy and' +
                  ' scope:</strong> Receives objectives and determines how to' +
                  ' use resources to meet goals. Provides guidance to reports in' +
                  ' alignment with team frameworks. Recommends changes to frameworks' +
                  ' and establishes procedures that affect immediate organization(s)' +
                  '</li><li><strong>Leadership:</strong>Guides individual goal-setting' +
                  ' aligned to team objectives. Manages hiring and performance of direct' +
                  ' reports. Develops a high-functioning and successful team</li></ul>',
                order: 2,
              },
              {
                id: 3,
                name: 'Senior Manager',
                description:
                  '<ul><li><strong>Global title and role description:</strong>' +
                  ' Manages the work and teams of two or more departments. Oversees' +
                  ' costs, strategies, and staffing. May have managers reporting to ' +
                  'them depending on team size/need</li><li><strong>Job complexity:' +
                  '</strong> Works on issues that require in-depth knowledge of' +
                  ' organizational objectives. Establishes and assures adherence to' +
                  ' budgets, schedules, work plans, and performance expectations</li>' +
                  '<li><strong>Autonomy and scope:</strong> Establishes operational ' +
                  'objectives and work plans and assigns work to the appropriate reports.' +
                  ' Senior management reviews objectives to determine success. Involved' +
                  ' in developing, modifying, and executing work that affects immediate' +
                  ' operations and may also have a company-wide effect</li><li><strong>' +
                  'Leadership:</strong>Leads team goal-setting and defines deliverables' +
                  ' and timelines. Responsible for hiring and overall performance of' +
                  ' direct and skip-level reports</li></ul>',
                order: 3,
              },
              {
                id: 4,
                name: 'Director',
                description:
                  '<ul><li><strong>Global title and role description:</strong>' +
                  ' Leads a broad functional area through several department managers' +
                  ' within the company. Has overall control of planning, staffing, ' +
                  'budgeting, managing expense priorities, and recommending and' +
                  ' implementing changes to methods</li><li><strong>Job complexity:' +
                  '</strong> Works on complex issues that require an in-depth knowledge' +
                  ' of the company. Participates in company creation of methods,' +
                  ' techniques, and evaluation criteria for projects, programs, and ' +
                  'people. Ensures budgets and schedules meet company requirements</li>' +
                  '<li><strong>Autonomy and scope:</strong> Participates with other' +
                  ' senior managers to establish strategic plans and objectives. ' +
                  'Makes final decisions on administrative or operational matters and' +
                  ' ensures effective achievement of objectives</li><li><strong>' +
                  'Leadership:</strong> Coaches and mentors others, including' +
                  ' employees who do not directly report to them. Responsible for ' +
                  'hiring senior talent and reducing the occurrence of single sources' +
                  ' of knowledge within the team</li></ul>',
                order: 4,
              },
              {
                id: 5,
                name: 'Senior Director',
                description:
                  '<ul><li><strong>Global title and role description:</strong>' +
                  ' Leads one or more functional areas, product groups, or service' +
                  ' areas through senior managers who have overall responsibility for' +
                  ' the successful operation of those assigned areas</li><li><strong>' +
                  'Job complexity:</strong> Consistently works with abstract ideas or' +
                  ' situations across functional areas of the business. Through assessment' +
                  ' of intangible variables, identifies, and evaluates fundamental issues,' +
                  ' providing strategy and direction for major functional areas. Requires' +
                  ' in-depth knowledge of the functional area, business strategies,' +
                  ' and the company’s goals</li><li><strong>Autonomy and scope:</strong>' +
                  ' Develops company and/or organizational policies and oversees their' +
                  ' implementation. Detailed knowledge of the company allows for innovative' +
                  ' concepts and promoting new ideas. Provides direction to senior managers' +
                  ' in various areas, groups, and/or operations</li><li><strong>' +
                  'Leadership:</strong>Drives and supports talent and succession planning' +
                  ' within the department</li></ul>',
                order: 5,
              },
              {
                id: 6,
                name: 'VP',
                description:
                  '<ul><li><strong>Global title and role description:</strong>' +
                  ' Leads a complete functional area through multiple levels of management' +
                  ' within the company. Has overall control of planning, staffing,' +
                  ' budgeting, and approving changes to strategies and practices.' +
                  ' Manages a team of directors and/or high-level individual contributors' +
                  '</li><li><strong>Job complexity:</strong> Develops strategic plans' +
                  ' to ensure achievement of objectives across one or more functional' +
                  ' areas. Requires in-depth knowledge of the company, competitive' +
                  ' environment, technology and products, and economic or social implications' +
                  ' of company activities. Participates in the development of company' +
                  ' strategies, priorities, and evaluation criteria for projects,' +
                  ' programs, and people. Approves budgets and plans</li><li><strong>' +
                  'Autonomy and scope:</strong> Sets vision, direction, and resource' +
                  ' allocation for a significant organization or business unit. Responsible' +
                  ' for multiple functions, products, departments, and/or geographies.' +
                  ' Develops and implements strategic plans and objectives for the ' +
                  'organization in alignment with company strategy; oversees the direction' +
                  ' and approves administrative, operational, and budgetary decisions' +
                  ' to ensure the achievement of objectives</li><li><strong>' +
                  'Leadership:</strong>Makes strategic decisions for hiring, departure,' +
                  ' growth, and compensation in close partnership with the People' +
                  ' Team</li></ul>',
                order: 6,
              },
              {
                id: 7,
                name: 'Senior VP',
                description:
                  '<ul><li><strong>Global title and role description:</strong>' +
                  ' Lead more than one functional area or product group through vice' +
                  ' president level executive leaders who have overall operational' +
                  ' responsibility for their assigned functions, geographic regions,' +
                  ' and businesses</li><li><strong>Job complexity:</strong> Consistently' +
                  ' works with abstract ideas or situations across functional areas of' +
                  ' the business. Through assessment of intangible variables, identifies' +
                  ' and evaluates core issues, providing strategy and direction for' +
                  ' major functional areas. Requires in-depth knowledge of the function,' +
                  ' business strategies, and the company’s goals as well as external' +
                  ' factors affecting the governance of company activities</li><li>' +
                  '<strong>Autonomy and scope:</strong> Sets vision and direction through' +
                  ' resource allocation decisions for multiple significant organizations' +
                  ' or business units where each is typically led by an executive.' +
                  ' Develops corporate and/or organizational strategies and authorizes' +
                  ' their implementation. Provides vision and direction to senior managers' +
                  ' in various groups, and/or operations</li><li><strong>' +
                  'Leadership:</strong>Shapes a people-first culture that exemplifies' +
                  ' kindness, promotes helpfulness and assumes positive intent. Builds' +
                  ' a strong leadership pipeline with a future focus. Inspires teams' +
                  ' to work toward a shared vision and common goals</li></ul>',
                order: 7,
              },
              {
                id: 8,
                name: 'C-Suite',
                description:
                  '<ul><li><strong>Global title and role description</strong></li><li><strong>' +
                  'Job complexity</strong></li><li><strong>Autonomy and scope:</strong>' +
                  ' Develops company-wide vision and the strategy to achieve the highest' +
                  ' company priorities</li><li><strong>Leadership:</strong> Shapes a' +
                  ' people-first culture that exemplifies kindness, promotes helpfulness' +
                  ' and assumes positive intent. Holds senior leadership accountable' +
                  ' for living out the company culture defined by its identity traits' +
                  ' and operating principles. Inspires business units to work toward' +
                  ' a shared vision and common goals</li></ul>',
                order: 8,
              },
            ],
          },
        ],
      },
    ],
  },
  FACEBOOK: {
    title: `Based on <strong>Facebook</strong>'s levelling`,
    description:
      'Ideal for mid-sized and large tech companies. Seniority is defined through general requirements from Engineering professionals.',
    frameworks: [
      {
        id: 1,
        name: 'Facebook framework',
        order: 1,
        tracks: [
          {
            id: 1,
            name: 'Engineering track',
            order: 1,
            levels: [
              {
                id: 1,
                name: 'Software Engineer Level I',
                description:
                  '<strong>General Requirements:</strong> up to 2 years' +
                  ' of experience, BCs degree, expected to write code for production' +
                  ' and conduct tests under minimal supervision; well-versed with ' +
                  'software testing tools, source control, and code review technologies',
                order: 1,
              },
              {
                id: 2,
                name: 'Software Engineer Level II',
                description:
                  '<strong>General Requirements:</strong> 2-4 years of experience,' +
                  ' basic knowledge of software application design, responsible for' +
                  ' making minor design decisions independently and work towards' +
                  ' understanding and developing system applications without' +
                  ' supervision',
                order: 2,
              },
              {
                id: 3,
                name: 'Software Engineer Level III',
                description:
                  '<strong>General Requirements:</strong> more than 5 years of experience,' +
                  ' in-depth domain knowledge, problem-solving abilities and be' +
                  ' well-versed in system design and architecture to function as a' +
                  ' technical architect, budgetary responsibilities, web and mobile' +
                  ' development skills',
                order: 3,
              },
              {
                id: 4,
                name: 'Software Engineer Level IV',
                description:
                  '<strong>General Requirements:</strong> more than 10 years of experience,' +
                  ' expected to lead large teams of Software Engineers while working autonomously',
                order: 4,
              },
              {
                id: 5,
                name: 'Software Engineer Level V',
                description:
                  '<strong>General Requirements:</strong> expected to conduct market ' +
                  'research and stay updated with the latest trends to drive best' +
                  ' engineering practices and contribute to higher revenue',
                order: 5,
              },
              {
                id: 6,
                name: 'Software Engineer Level VI',
                description:
                  '<strong>General Requirements:</strong> acting as progressive visionarie' +
                  ' in the field of engineering and working towards large-scale' +
                  ' and competitive business growth',
                order: 6,
              },
            ],
          },
          {
            id: 2,
            name: 'Management track',
            order: 2,
            levels: [
              {
                id: 1,
                name: 'Entry-level Software Engineering Manager',
                description:
                  '<strong>General Additional Requirements:</strong> mentoring' +
                  ' junior-level Software Engineers,  training juniors towards' +
                  ' technical excellence, conducting timely performance evaluations,' +
                  ' ensuring resource allocation if necessary',
                order: 1,
              },
              {
                id: 2,
                name: 'Software Engineering Manager I',
                description:
                  '<strong>General Additional Requirements:</strong> facilitating' +
                  ' coordination between different teams as well as recruiting and' +
                  ' hiring new Software Engineers to accomplish goals, identifying ' +
                  'individual strengths, fostering team growth',
                order: 2,
              },
              {
                id: 3,
                name: 'Software Engineering Manager II',
                description:
                  '<strong>General Additional Requirements:</strong> leading cross-' +
                  'functional teams through technical and non-technical strategic' +
                  ' planning',
                order: 3,
              },
              {
                id: 4,
                name: 'Director',
                description:
                  '<strong>General Additional Requirements:</strong> improving company' +
                  ' culture, devising policies, launching large-scale projects,' +
                  ' incorporating evolving technological practices',
                order: 4,
              },
            ],
          },
        ],
      },
    ],
  },
};
